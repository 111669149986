import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import LoadingComponent from "../../../LoadingComponent";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { CONSULTANT_STATUS } from "../../../Utils/constants";

import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

import ChangeStatus from "./changeStatus";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";

import { getSalesTeam, resetLazySalesTeam } from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";

class SalesTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowClosureDialog: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            statusFilter: CONSULTANT_STATUS.MARKETING,
            selectedId: "",
            selectedStatus: CONSULTANT_STATUS.MARKETING,
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    getSalesTeam = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/consultant/get-sales-assigned?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, { status: this.props.selectedStatus }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.salesTeam?.length) {
            this.props.getSalesTeam({ status: this.props.selectedStatus });
        }
    }

    onGlobalSearch = (e) => {
        this.props.getSalesTeam({
            globalSearch: e.target.value,
            status: this.props.selectedStatus,
        });
    };

    onPage = (event) => {
        this.props.getSalesTeam({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onSort = (event) => {
        this.props.getSalesTeam({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getSalesTeam({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    closeConsultant = () => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/`;
        const requestBody = {
            _id: this.state.selectedId,
            status: CONSULTANT_STATUS.COMPLETED,
        };
        this.serverlessService
            .put(url, requestBody, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            consultant: res.res.data,
                            isLoading: false,
                        },
                        () => {
                            this.props.getSalesTeam();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={() => {
                        this.setState({
                            isShowClosureDialog: false,
                        });
                    }}
                />
                <Button
                    label="Complete"
                    autoFocus
                    onClick={this.closeConsultant()}
                />
            </div>
        );
    };

    showConsultantNotes = (rowData) => {
        this.setState({
            isShowConsultantNotes: true,
            editData: rowData,
        });
    };

    showConsultantMonitor = (rowData) => {
        this.setState({
            isShowConsultantMonitor: true,
            editData: rowData,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                {rowData.status != CONSULTANT_STATUS.COMPLETED &&
                    rowData.status != CONSULTANT_STATUS.STOPPED && (
                        <Authorize permId={PERMISSIONS.MARKETING_CHANGE_STATUS}>
                            <div className="col-2">
                                <span
                                    onClick={() =>
                                        this.setState({
                                            isShowAssignToContentWriter: true,
                                            selectedCustomer: rowData,
                                        })
                                    }
                                    data-pr-tooltip="Change Status"
                                    data-pr-position="top"
                                    className={`p-text jk${rowData._id.substr(
                                        4
                                    )}`}
                                >
                                    <i className="pi pi-cog" />
                                </span>
                                <Tooltip
                                    target={`.jk${rowData._id.substr(
                                        4
                                    )}`}
                                />
                            </div>
                        </Authorize>
                    )}
                <Authorize permId={PERMISSIONS.MARKETING_SUBMISSIONS}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/submission/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Submissions"
                            data-pr-position="top"
                            className={`p-text io${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-images" />
                        </span>
                        <Tooltip
                            target={`.io${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.MARKETING_INTERVIEWS}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/interview/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Interviews"
                            data-pr-position="top"
                            className={`p-text ol${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-users" />
                        </span>
                        <Tooltip
                            target={`.ol${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.MARKETING_SHOW_CONSULTANT}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text bg${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.bg${rowData._id.substr(4)}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.MARKETING_MONITOR}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text nh${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip
                            target={`.nh${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.MARKETING_TRACKER}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/tracker/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Consultant Tracker"
                            data-pr-position="top"
                            className={`p-text mk${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip
                            target={`.mk${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.MARKETING_NOTES}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text ol${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip
                            target={`.ol${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                {this.props.salesTeam.some(
                    (consultant) => consultant.marketingAssignedTo
                ) && (
                        <Authorize permId={PERMISSIONS.MARKETING_COPYHELPER}>
                            <div className="col-2">
                                <span
                                    onClick={() => {
                                        this.props.history.push(
                                            `/admin/copy-helper/${rowData._id}`
                                        );
                                    }}
                                    data-pr-tooltip="Copy Helper Info"
                                    data-pr-position="top"
                                    className={`p-text pl${rowData._id.substr(
                                        4
                                    )}`}
                                >
                                    <i className="pi pi-copy" />
                                </span>
                                <Tooltip
                                    target={`.pl${rowData._id.substr(4)}`}
                                />
                            </div>
                        </Authorize>
                    )}
            </div>
        );
    };

    showNotes = (rowData) => {
        this.setState({
            isShowNotes: true,
            editData: rowData,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };
    onClosureConfirmation = () => {
        this.setState({
            isShowClosureDialog: false,
        });
    };

    onFilterChange = (e) => {
        this.setState({ selectedStatus: e.value });
        this.props.getSalesTeam({ status: e.value });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Consultants</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.MARKETING_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.MARKETING_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.MARKETING_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );

        const statusOptions = [
            { label: "In Marketing", value: CONSULTANT_STATUS.MARKETING },
            { label: "Completed", value: CONSULTANT_STATUS.COMPLETED },
            { label: "Hold", value: CONSULTANT_STATUS.HOLD },
            { label: "Stopped", value: CONSULTANT_STATUS.STOPPED },
        ];
        const items = [{ label: "Admin", url: "/#/admin" }, { label: "Marketing" }];

        const home = {
            icon: "pi pi-home",
            url: "/#/admin",
        };
        return (
            <>
                <div className='p-5'>
                    <BreadCrumb model={items} home={home} />
                    <Toolbar
                        className="p-0 bg-primary-reverse border-white"
                        right={() => {
                            return (
                                <div className="p-col">
                                    <Dropdown
                                        value={this.props.selectedStatus}
                                        options={statusOptions}
                                        onChange={this.onFilterChange}
                                        placeholder="Select a status"
                                        className="w-11rem mt-3"
                                    />
                                </div>
                            );
                        }}
                        left={() => {
                            return <></>;
                        }}
                    ></Toolbar>

                    <Authorize permId={PERMISSIONS.MARKETING_LIST}>
                        <div className="mt-3 shadow-3 border-round overflow-hidden">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.salesTeam}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({
                                        selectedCustomer: e.value,
                                    })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="name"
                                    header="Full Name"
                                ></Column>
                                <Column
                                    field="mobile"
                                    header="Phone"
                                ></Column>
                                <Column
                                    field="email"
                                    header="Email"
                                ></Column>
                                <Column
                                    field="rolePrefered"
                                    header="Role"
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>

                            {this.state.isShowAssignToContentWriter && (
                                <ChangeStatus
                                    selectedCustomer={
                                        this.state.selectedCustomer
                                    }
                                    onCancel={() => {
                                        this.setState({
                                            isShowAssignToContentWriter: false,
                                            selectedCustomer: null,
                                        });
                                    }}
                                    onHide={() => {
                                        this.toast.show({
                                            severity: "success",
                                            summary:
                                                "Status changed",
                                            detail: "Consultant status changed successfully.",
                                            life: 3000,
                                        });
                                        this.props.getSalesTeam({
                                            status: this.state
                                                .statusFilter,
                                        });
                                        this.setState(
                                            {
                                                isShowAssignToContentWriter: false,
                                                selectedCustomer: null,
                                            });
                                    }}
                                />
                            )}
                        </div>
                    </Authorize>

                </div>

                {this.state.isShowNotes && (
                    <Dialog
                        header={"Notes"}
                        draggable={false}
                        visible={true}
                        style={{ width: "49vw" }}
                        onHide={() => {
                            this.setState({
                                isShowNotes: false,
                                editData: null,
                            });
                        }}
                    >
                        <p>{this.state.editData.notesToContentWriter}</p>
                    </Dialog>
                )}

                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}
                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}
                {this.state.isShowClosureDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowClosureDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent}
                        draggable={false}
                    >
                        <p>Once Completed Not Retrieved</p>
                    </Dialog>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    salesTeam: state?.salesTeams?.salesTeam,
    totalRecords: state?.salesTeams?.totalRecords,
    lazyParams: state?.salesTeams?.lazyParams,
    isLoading: state?.salesTeams?.isLoading,
    selectedStatus: state?.salesTeams?.selectedStatus,
});

export default BaseComponent(SalesTeam, mapStateToProps, {
    getSalesTeam,
    resetLazySalesTeam,
});
