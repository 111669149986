import React, { Component } from "react";
import { Button } from "primereact/button";
import ServerlessService from "../../../Service/ServerlessService";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import BaseComponent from "../../common/BaseComponent";
import moment from "moment";
import { BreadCrumbHome, logOut } from "../../../Utils";
import CkEditorComponent from "../../common/ckEditor";
import { BreadCrumb } from "primereact/breadcrumb";

class ViewConsultant extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultant: null,
        };

        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Consultants", url: "/#/admin/consultants" },
        { label: `${this.props.params.id ? "View" : 'Add'} Consultant`, url: `/#/admin/cosultant/${this.props.params.id ? 'view/' + this.props.params.id : 'add'}` }
    ];


    getConsultant = (consultantId) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/id/${consultantId}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultant: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount = () => {
        if (this.props?.params?.id) {
            this.getConsultant(this.props?.params.id);
        }
    };

    render() {
        const { consultant } = this.state;
        console.log(consultant, 'sp')
        return (
            <div className="p-5">
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <div className="shadow-3 border-round overflow-hidden p-5 mt-5">
                    {consultant && (
                        <div className="p-grid p-fluid">
                            <div >
                                <div >
                                    {/* <Button
                                        icon="pi pi-arrow-left"
                                        className="p-button-rounded p-button-text p-button-secondary m-3"
                                        onClick={() => this.props.history.goBack()}
                                        style={{
                                            marginRight: "0.5rem",
                                            padding: "0.5rem",
                                            backgroundColor: "#6366F1",
                                            color: "#ffffff",
                                            fontSize: "1.5rem",
                                        }}
                                    /> */}
                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Personal Details
                                    </div>
                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Name
                                            </div>
                                            <div className="text-900">
                                                {consultant.name}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Email
                                            </div>
                                            <div className="text-900">
                                                {consultant.email}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Phone
                                            </div>
                                            <div className="text-900">
                                                {consultant.mobile}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Secondary Email
                                            </div>
                                            <div className="text-900">
                                                {consultant.emailSecond}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Social Security Number
                                            </div>
                                            <div className="text-900">
                                                {consultant.last4digitSSN}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Land Line
                                            </div>
                                            <div className="text-900">
                                                {consultant.landLine}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Mother Tongue
                                            </div>
                                            <div className="text-900">
                                                {consultant.motherTongue}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Date Of Birth
                                            </div>
                                            <div className="text-900">
                                                {moment(
                                                    consultant.dateOfBirth
                                                ).format("MM/DD/YYYY")}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Date of Entered US
                                            </div>
                                            <div className="text-900">
                                                {moment(
                                                    consultant.usEnteredDate
                                                ).format("MM/DD/YYYY")}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                VISA Status
                                            </div>
                                            <div className="text-900">
                                                {consultant.visaStatus}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Driving License
                                            </div>
                                            <div className="text-900">
                                                {consultant.drivingLicenseNo}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                State ID
                                            </div>
                                            <div className="text-900">
                                                {consultant.stateId}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Skype ID
                                            </div>
                                            <div className="text-900">
                                                {consultant.skypeId}
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Position Details
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Role Prefered
                                            </div>
                                            <div className="text-900">
                                                {consultant.rolePrefered}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Technologies Prefered
                                            </div>
                                            <div className="text-900">
                                                {consultant.technologiesPrefered?.join(', ')}
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Commuication address
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Current Address
                                            </div>
                                            <div className="text-900">
                                                {consultant.currentAddress}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Home Country Address
                                            </div>
                                            <div className="text-900">
                                                {consultant.homeCountryAddress}
                                            </div>
                                        </div>

                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Current Location (City and State)
                                            </div>
                                            <div className="text-900">
                                                {consultant.currentLocation}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-6 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Willing to relocate
                                            </div>
                                            <div className="text-900">
                                                {consultant.willingToRelocate
                                                    ? "Yes"
                                                    : "No"}
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Emergency Contact Details
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Emergency Contact Person Name
                                            </div>
                                            <div className="text-900">
                                                {consultant.emergencyContactPerson}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Emergency Contact Person Email
                                            </div>
                                            <div className="text-900">
                                                {
                                                    consultant.emergencyContactPersonEmail
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Emergency Contact Person Phone
                                            </div>
                                            <div className="text-900">
                                                {
                                                    consultant.emergencyContactPersonPhone
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Educational Details
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Bachelors Degree
                                            </div>
                                            <div className="text-900">
                                                {consultant.bachellorsDegree}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Bachelors College Name
                                            </div>
                                            <div className="text-900">
                                                {consultant.bachellersCollegeName}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Bachelors Completed Date
                                            </div>
                                            <div className="text-900">
                                                {consultant.bachellersCompletedDate}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Masters Degree
                                            </div>
                                            <div className="text-900">
                                                {consultant.masterDegree}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Masters College Name
                                            </div>
                                            <div className="text-900">
                                                {consultant.mastersCollegeName}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Masters Completed Date
                                            </div>
                                            <div className="text-900">
                                                {consultant.mastersCompletedDate}
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Previous/Current experience
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Work Authorization Start Date
                                            </div>
                                            <div className="text-900">
                                                {moment(
                                                    consultant.workAuthorizationStartDate
                                                ).format("MM/DD/YYYY")}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Work Authorization End Date
                                            </div>
                                            <div className="text-900">
                                                {moment(
                                                    consultant.workAuthorizationEndDate
                                                ).format("MM/DD/YYYY")}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Any Work Experience
                                            </div>
                                            <div className="text-900">
                                                {consultant.hasAnyWorkExperience
                                                    ? "Yes"
                                                    : "No"}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Work Experience Title
                                            </div>
                                            <div className="text-900">
                                                {consultant.workExperienceTitle}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Work Experience Technologies
                                            </div>
                                            <div className="text-900">
                                                {
                                                    consultant.workExperienceTechnologies
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Total Years Of Experience
                                            </div>
                                            <div className="text-900">
                                                {consultant.totalYearsOfExperience}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Previous working company
                                            </div>
                                            <div className="text-900">
                                                {consultant.previousWorkingCompany}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Previous Work Experience Title
                                            </div>
                                            <div className="text-900">
                                                {
                                                    consultant.previousWorkExperienceTitle
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Previous Work Experience
                                                Technologies
                                            </div>
                                            <div className="text-900">
                                                {
                                                    consultant.previousWorkExperienceTechnologies
                                                }
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Total Years Of Experience In
                                                Previous Company
                                            </div>
                                            <div className="text-900">
                                                {
                                                    consultant.totalYearsOfExperienceInPreviousCompany
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Marketing Details
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Marketing Mail
                                            </div>
                                            <div className="text-900">
                                                {consultant.marketingMailId}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Marketing Mail Password
                                            </div>
                                            <div className="text-900">
                                                {consultant.marketingMailPassword}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Marketing Phone Google Voice
                                            </div>
                                            <div className="text-900">
                                                {consultant.marketingPhone}
                                            </div>
                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <div className="text-500 font-medium mb-2">
                                                Available For Web Camera Interview
                                            </div>
                                            <div className="text-900">
                                                {consultant.availableForWebCamInterview
                                                    ? "Yes"
                                                    : "No"}
                                            </div>
                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Attachments
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <label className="font-bold block">
                                                Driving License
                                            </label>
                                            {
                                                consultant.drivingLicenseURL?.path && <a
                                                    href={`${consultant.drivingLicenseURL?.path}`}
                                                    className="text-lg"

                                                    style={{ marginRight: "10px" }}
                                                    target="_blank"
                                                >
                                                    Click Here
                                                </a>
                                            }

                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <label className="font-bold block">
                                                State ID
                                            </label>
                                            {
                                                consultant.stateIdURL?.path && <a
                                                    href={`${consultant.stateIdURL?.path}`}
                                                    className="text-lg"
                                                    style={{ marginRight: "10px" }}
                                                    target="_blank"
                                                >
                                                    Click Here
                                                </a>
                                            }

                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <label className="font-bold block">
                                                I94
                                            </label>
                                            {
                                                consultant.i94URL?.path && <a
                                                    href={`${consultant.i94URL?.path}`}
                                                    className="text-lg"
                                                    style={{ marginRight: "10px" }}
                                                    target="_blank"
                                                >
                                                    Click Here
                                                </a>
                                            }

                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <label className="font-bold block">
                                                Work Authorization (CPT, OPT, H1,
                                                GC)
                                            </label>
                                            {consultant.workAuthorizationURL?.path && <a
                                                href={`${consultant.workAuthorizationURL?.path}`}
                                                className="text-lg"
                                                style={{ marginRight: "10px" }}
                                                target="_blank"
                                            >
                                                Click Here
                                            </a>}

                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <label className="font-bold block">
                                                Passport
                                            </label>
                                            {
                                                consultant.passportURL?.path && <a
                                                    href={`${consultant.passportURL?.path}`}
                                                    className="text-lg"
                                                    style={{ marginRight: "10px" }}
                                                    target="_blank"
                                                >
                                                    Click Here
                                                </a>
                                            }

                                        </div>
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <label className="font-bold block">
                                                Passphoto
                                            </label>
                                            {
                                                consultant.passphotoURL?.path && <a
                                                    href={`${consultant.passphotoURL?.path}`}
                                                    className="text-lg"
                                                    style={{ marginRight: "10px" }}
                                                    target="_blank"
                                                >
                                                    Click Here
                                                </a>
                                            }

                                        </div>
                                    </div>

                                    <br />

                                    <div className="font-medium text-3xl text-900 mb-3">
                                        Internal
                                    </div>

                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12">
                                            <label className="font-bold block">
                                                Vendor Script
                                            </label>
                                            <p className="text-lg">
                                                <CkEditorComponent
                                                    value={consultant.vendorScript}
                                                    disabled={true}
                                                    editor={null}
                                                />
                                                {/* {consultant.vendorScript} */}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="grid grid-nogutter border-top-1 surface-border pt-2">
                                        <div className="col-12 md:col-4 lg:col-4 p-3">
                                            <label className="font-bold block">
                                                Documents
                                            </label>
                                            <ul>
                                                {
                                                    !consultant?.files?.length && <>No documents</>
                                                }
                                                {consultant?.files?.map((file) => (
                                                    <li key={file.name}>
                                                        <p>
                                                            <a
                                                                target="_blank"
                                                                href={
                                                                    file?.url?.path
                                                                }
                                                            >
                                                                {file.name}
                                                            </a>
                                                        </p>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default BaseComponent(ViewConsultant);
