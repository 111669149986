import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";
import AssignTable from "./assignTable";
import UnassignTable from "./unAssignTable";
import AddTable from "./addTable";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { FeeDays, PaymentModes } from "../../../Utils/constants";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import {
    getFormFields,
    isFormValid,
    onTextChange,
    onDropDownChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import _TableFields from "./renewalTable.json";
import TableHistroy from "./tableHistroy";
let TableFields = _.cloneDeep(_TableFields);

class TablesList extends Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(TableFields, this.props.editData || {});
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            tables: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            rowDataOfTable: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            table: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
            table: this.formFields.data
        });
    };

    onTableAddOrUpdate = () => {
        this.setState(
            {
                visible: false,
                editData: null,
            },
            this.getTables
        );
    };

    deleteTable = () => {
        this.setState({
            isLoading: true,
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        const url = `/co-space/tables/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getTables();
                        }
                    );
                    this.toast.show({
                        message: res.message,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getTables = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            tables: [],
        });

        const url = `/co-space/tables?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        tables: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getTables();
    }

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getTables);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getTables);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getTables);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getTables);
    };

    showAssignTable = (rowData) => {
        this.setState({ isShowAssignTable: true, editData: rowData });
    };

    unAssignTable = (_id) => {
        this.setState({ isShowUnAssignTable: true, id: _id });
    };

    viewTableDetails = (_id) => {
        this.setState({
            isLoading: true,
            viewTable: [],
        });

        const url = `/co-space/tables/view/${_id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        viewTable: res.res.data,
                        isShowView: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    showTableHistory = (rowData) => {
        this.setState({
            isShowTableHistory: true,
            rowDataOfTable: rowData,
        });

    };

    showRenewalTable = (rowData) => {
        this.setState({
            isShowRenewal: true,
            rowDataOfTable: rowData,
        });
    };

    onPaymentUpdate = () => {
        const { table } = this.state;

        TableFields = TableFields.map((field) => {
            if (
                field.FieldName == "paymentReferenceNumber" ||
                field.FieldName == "paymentGateway"
            ) {
                field.Required = table.paymentMode == "online";
            }
            return field;
        });
    }

    onRenewalClick = () => {
        let table = trimObj(this.state.table);
        const formStatus = isFormValid(
            TableFields,
            this.formFields.formValidations,
            table
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            this.setState({
                isLoading: true,
                isVisible: false,
            });



            let payload = {
                ...this.state.table,
                assignTable: this.state.rowDataOfTable?._id,
                assignedTo: this.state.rowDataOfTable?.assignedTo?._id,
            };
            const url = `/co-space/tables/renewal-table`;
            this.serverlessService
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                                isShowRenewal: false,
                                rowDataOfTable: null,
                                renewalData: null,
                                table: this.formFields.data
                            },
                            () => {
                                this.getTables();
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        };
    };

    onDaysSelect = (e) => {
        let fee = this.state.rowDataOfTable?.feeType?.fees?.find(
            (a) => a.id === e.value
        )?.fee;
        this.setState((prevState) => {
            return {
                table: {
                    ...prevState.table,
                    days: e.value,
                    fee: fee.toString(),
                }
            };
        });
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={this.onHide}
                />
                <Button
                    label="Renewal"
                    autoFocus
                    onClick={this.onRenewalClick}
                />
            </div>
        );
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.TABLES_VIEW}>
                        <span
                            onClick={() => this.viewTableDetails(rowData._id)}
                            data-pr-tooltip="View Table"
                            data-pr-position="top"
                            className={`p-0 tableView${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.tableView${rowData._id}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.TABLES_EDIT}>
                        <span
                            onClick={() => {
                                const _rowData = { ...rowData };
                                //  _rowData.feeDetails = _rowData.feeDetails?._id;
                                _rowData.feeType = _rowData.feeType?._id;
                                this.setState({
                                    editData: _rowData,
                                    visible: true,
                                    isEdit: true,
                                });
                            }}
                            data-pr-tooltip="Edit Table"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.TABLES_ASSIGN}>
                        <span
                            onClick={() => this.showAssignTable(rowData)}
                            data-pr-tooltip={
                                rowData?.assignedTo
                                    ? "Re assign To"
                                    : "Assign To"
                            }
                            data-pr-position="top"
                            className={`p-text tableAssign${rowData._id}`}
                        >
                            <i className="pi pi-user-plus" />
                        </span>
                        <Tooltip target={`.tableAssign${rowData._id}`} />
                    </Authorize>
                </div>

                <div className="col-2">
                    <Authorize permId={PERMISSIONS.TABLES_DELETE}>
                        <span
                            onClick={() =>
                                this.showDeleteTableDialog(rowData._id)
                            }
                            data-pr-tooltip="Delete table"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.tableDelete${rowData._id}`} />
                    </Authorize>
                </div>

                {/* <div className="col-4"> */}
                {rowData?.assignedTo && (
                    <>
                        <div className="col-2">
                            <Authorize permId={PERMISSIONS.TABLES_UNASSIGN}>
                                <span
                                    onClick={() =>
                                        this.unAssignTable(rowData._id)
                                    }
                                    data-pr-tooltip="Unassign customer"
                                    data-pr-position="top"
                                    className={`p-text tableUnassign${rowData._id}`}
                                >
                                    <i className="pi pi-user-minus" />
                                </span>
                                <Tooltip
                                    target={`.tableUnassign${rowData._id}`}
                                />
                            </Authorize>
                        </div>

                        <div className="col-2">
                            <Authorize permId={PERMISSIONS.TABLES_RENEWAL}>
                                <span
                                    onClick={() =>
                                        this.showRenewalTable(rowData)
                                    }
                                    data-pr-tooltip="Renewal table"
                                    data-pr-position="top"
                                    className={`p-text tableRenewal${rowData._id}`}
                                >
                                    <i className="pi pi-calendar-plus" />
                                </span>
                                <Tooltip
                                    target={`.tableRenewal${rowData._id}`}
                                />
                            </Authorize>
                        </div>
                    </>
                )}
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.TABLES_ASSIGN}>
                        <span
                            onClick={() => this.showTableHistory(rowData)}
                            data-pr-tooltip="Table History"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-history" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };

    footerContentfotDelete = () => {
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onCloseConfirmation}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable()}
                autoFocus
                className=" p-button-primary "
            />
        </div>;
    };

    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    render() {
        const { table, formValidations } = this.state;
        return (
            <>
                <div className="p-4">
                    <span className="text-1000 font-medium text-2xl  mb-3">
                        Tables
                    </span>
                    <br />
                    <Authorize permId={PERMISSIONS.TABLES_ADD}>
                        <Button
                            className="p-button-primary "
                            label="Add Table"
                            severity="warning"
                            onClick={() => this.setState({ visible: true })}
                        />
                    </Authorize>

                    <br></br>
                    <br></br>
                    {this.state.visible && (
                        <AddTable
                            onHide={this.onHide}
                            editData={this.state.editData}
                            onTableAddOrUpdate={this.onTableAddOrUpdate}
                        ></AddTable>
                    )}
                    {/* */}
                    <Authorize permId={PERMISSIONS.TABLES_DATA}>
                        <div>
                            <DataTable
                                value={this.state.tables}
                                lazy
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.state.lazyParams.first}
                                last={this.state.totalRecords}
                                rows={this.state.lazyParams.rows}
                                totalRecords={this.state.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="name"
                                    header="Table Name"
                                ></Column>

                                <Column
                                    body={(rowData) => {
                                        return (
                                            <>
                                                {rowData?.assignedTo ? (
                                                    rowData?.assignedTo?.name
                                                ) : (
                                                    <div
                                                        style={{ color: "red" }}
                                                    >
                                                        None
                                                    </div>
                                                )}
                                            </>
                                        );
                                    }}
                                    header="Assign To"
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                            {this.state.isShowRenewal && (
                                <Dialog
                                    header="Renewal Table"
                                    visible={this.state.isShowRenewal}
                                    style={{ width: "65vw" }}
                                    onHide={() =>
                                        this.setState({ isShowRenewal: false })
                                    }
                                    footer={this.footerContent()}
                                    draggable={false}
                                >
                                    <div className="p-grid row pl-6 pr-6">
                                        <div className="col-4">
                                            <label className="font-bold block mb-2">Days</label>
                                            <Dropdown
                                                onChange={(e) => {
                                                    onDropDownChange(
                                                        e.value,
                                                        "days",
                                                        this,
                                                        TableFields,
                                                        table,
                                                        formValidations,
                                                        "table",
                                                        "formValidations"
                                                    );
                                                    this.setState((prevState) => {
                                                        return {
                                                            renewalData: {
                                                                ...prevState.renewalData,
                                                                days: e.value,
                                                                fee: this.onDaysSelect(e),
                                                            },
                                                        };
                                                    });
                                                }}

                                                value={table.days}
                                                options={FeeDays}
                                                optionValue="id"
                                                optionLabel="days"
                                                placeholder="Select a day"
                                                className="w-full md:w-14rem"
                                            />
                                            {formValidations &&
                                                !formValidations.fields["days"].isValid && (
                                                    <p className="p-error">
                                                        {
                                                            formValidations.fields["days"]
                                                                .errorMsg
                                                        }
                                                    </p>
                                                )}
                                        </div>

                                        <div className="col-4">
                                            <label className="font-bold block mb-2">Fee</label>

                                            <InputNumber
                                                value={table.fee}
                                                readOnly
                                            />
                                        </div>

                                        <div className="col-4">
                                            <label className="font-bold block mb-2">
                                                Payment Mode
                                            </label>
                                            <Dropdown
                                                onChange={(e) => {
                                                    onDropDownChange(
                                                        e.value,
                                                        "paymentMode",
                                                        this,
                                                        TableFields,
                                                        table,
                                                        formValidations,
                                                        "table",
                                                        "formValidations",
                                                        this.onPaymentUpdate,
                                                    );
                                                    this.setState((prevState) => {
                                                        return {
                                                            renewalData: {
                                                                ...prevState.renewalData,
                                                                paymentMode: e.value,
                                                            },
                                                        };
                                                    });
                                                }}
                                                value={table.paymentMode}
                                                options={PaymentModes}
                                                optionValue="id"
                                                optionLabel="type"
                                                placeholder="Select a payment type"
                                                className="w-full md:w-14rem"
                                            />
                                            {formValidations &&
                                                !formValidations.fields["paymentMode"].isValid && (
                                                    <p className="p-error">
                                                        {
                                                            formValidations.fields["paymentMode"]
                                                                .errorMsg
                                                        }
                                                    </p>
                                                )}
                                        </div>

                                        {this.state.table.paymentMode == "online" && (
                                            <>
                                                <div className="col-4">
                                                    <label className="font-bold block mb-2">
                                                        Payment Reference Number
                                                    </label>
                                                    <InputText
                                                        onChange={(e) => {
                                                            onTextChange(
                                                                e.target.value,
                                                                "paymentReferenceNumber",
                                                                this,
                                                                TableFields,
                                                                table,
                                                                formValidations,
                                                                "table",
                                                                "formValidations"
                                                            );
                                                            this.setState((prevState) => {
                                                                return {
                                                                    renewalData: {
                                                                        ...prevState.renewalData,
                                                                        paymentReferenceNumber:
                                                                            e.value,
                                                                    },
                                                                };
                                                            });
                                                        }}
                                                    ></InputText>
                                                    {formValidations &&
                                                        !formValidations.fields["paymentReferenceNumber"]
                                                            .isValid && (
                                                            <p className="p-error">
                                                                {
                                                                    formValidations.fields[
                                                                        "paymentReferenceNumber"
                                                                    ].errorMsg
                                                                }
                                                            </p>
                                                        )}
                                                </div>
                                                <div className="col-4">
                                                    <label className="font-bold block mb-2">
                                                        Payment Gateway
                                                    </label>
                                                    <InputText
                                                        onChange={(e) => {
                                                            onTextChange(
                                                                e.target.value,
                                                                "paymentGateway",
                                                                this,
                                                                TableFields,
                                                                table,
                                                                formValidations,
                                                                "table",
                                                                "formValidations"
                                                            );

                                                            this.setState((prevState) => {
                                                                return {
                                                                    renewalData: {
                                                                        ...prevState.renewalData,
                                                                        paymentGateway: e.target.value,
                                                                    },
                                                                };
                                                            });
                                                        }}
                                                    ></InputText>
                                                    {formValidations &&
                                                        !formValidations.fields["paymentGateway"]
                                                            .isValid && (
                                                            <p className="p-error">
                                                                {
                                                                    formValidations.fields[
                                                                        "paymentGateway"
                                                                    ].errorMsg
                                                                }
                                                            </p>
                                                        )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </Dialog>
                            )}

                            {this.state.isShowTableHistory && (<TableHistroy rowDataOfTable={this.state.rowDataOfTable} />

                            )}
                            {this.state.isShowView && (
                                <Dialog
                                    header="View Table Details"
                                    visible={this.state.isShowView}
                                    style={{ width: "65vw" }}
                                    draggable={false}
                                    onHide={() =>
                                        this.setState({ isShowView: false })

                                    }
                                >
                                    <div className="p-grid row p-3 pl-6 pr-6">
                                        <div className="col-4">
                                            <p>
                                                <b>Name : </b>{" "}
                                                {this.state.viewTable.name}
                                            </p>
                                        </div>

                                        <div className="col-4">
                                            <p>
                                                <b>Location : </b>{" "}
                                                {this.state.viewTable.location}
                                            </p>
                                        </div>

                                        <div className="col-4">
                                            <p>
                                                <b>Table Id : </b>{" "}
                                                {
                                                    this.state.viewTable
                                                        .referenceId
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <br />
                                    <br />
                                    <div className="flex w-full relative align-items-center justify-content-start my-3 px-4">
                                        <div className="border-top-1 border-300 top-50 left-0 absolute w-full"></div>
                                        <div className="px-2 z-1 surface-0 flex align-items-center"></div>
                                    </div>{" "}
                                    <br />
                                    <br />
                                    {this.state.viewTable.assignedTo ? (
                                        <>
                                            <span className="text-1000 font-medium text-2xl  mb-3 pl-5">
                                                Customer Details
                                            </span>
                                            <br />
                                            <div className="p-grid row pl-6 pr-6">
                                                <div className="col-4">
                                                    <p>
                                                        <b>Name : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .assignedTo
                                                                ?.name
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>Father Name : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .assignedTo
                                                                ?.fatherName
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>Mobile : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .assignedTo
                                                                ?.mobile
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>Aadhar : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .assignedTo
                                                                ?.adhar
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>E-mail : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .assignedTo
                                                                ?.email
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>
                                                            Current Address :{" "}
                                                        </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .assignedTo
                                                                ?.currentAddress
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>
                                                            Permanent Address :{" "}
                                                        </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .assignedTo
                                                                ?.permanentAddress
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <br />
                                            <br />
                                            <div className="flex w-full relative align-items-center justify-content-start my-3 px-4">
                                                <div className="border-top-1 border-300 top-50 left-0 absolute w-full"></div>
                                                <div className="px-2 z-1 surface-0 flex align-items-center"></div>
                                            </div>
                                            <br />
                                            <br />
                                            <span className="text-1000 font-medium text-2xl  mb-3 pl-5">
                                                Transaction Details
                                            </span>
                                            <div className="p-grid row pl-6 pr-6">
                                                <div className="col-4">
                                                    <p>
                                                        <b>Days : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .transaction
                                                                ?.days
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>Fee : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .transaction
                                                                ?.fee
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>Payment Mode : </b>{" "}
                                                        {
                                                            this.state.viewTable
                                                                .transaction
                                                                ?.paymentMode
                                                        }
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>Valid Till : </b>{" "}
                                                        {moment(
                                                            this.state.viewTable
                                                                ?.transaction
                                                                ?.validTill
                                                        ).format("MM/DD/YYYY")}
                                                    </p>
                                                </div>

                                                <div className="col-4">
                                                    <p>
                                                        <b>Created At : </b>{" "}
                                                        {moment(
                                                            this.state.viewTable
                                                                ?.transaction
                                                                ?.createdAt
                                                        ).format("MM/DD/YYYY")}
                                                    </p>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <p style={{ textAlign: "center" }}>
                                            Not Assigned yet
                                        </p>
                                    )}
                                </Dialog>
                            )}
                            <Toast
                                ref={(el) => (this.toast = el)}
                                position="bottom-right"
                            />
                            {(this.state.isLoading || this.props.isLoading) && (
                                <LoadingComponent />
                            )}
                        </div>
                    </Authorize>
                </div>

                {this.state.isShowAssignTable && (
                    <AssignTable
                        editData={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowAssignTable: false,
                                editData: null,
                            });
                        }}
                        onSuccessFullyAssigned={() => {
                            this.setState(
                                {
                                    isShowAssignTable: false,
                                    editData: null,
                                },
                                this.getTables
                            );
                        }}
                    />
                )}

                {this.state.isShowUnAssignTable && (
                    <UnassignTable
                        _id={this.state.id}
                        onHide={() => {
                            this.setState({
                                isShowUnAssignTable: false,
                                _id: null,
                            });
                        }}
                        onSuccessFullyUnAssigned={() => {
                            this.setState(
                                {
                                    isShowUnAssignTable: false,
                                    _id: null,
                                },
                                this.getTables
                            );
                        }}
                    />
                )}

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                        draggable={false}
                    >
                        <p>Deleted table can't be restore.</p>
                    </Dialog>
                )}
            </>
        );
    }
}

export default TablesList;
