import React, { Component } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import BaseComponent from "../../common/BaseComponent";

import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import { BreadCrumbHome, logOut, trimObj } from "../../../Utils";
import ConsultantFields from "./addConsultant.json";
import { InputTextarea } from "primereact/inputtextarea";
import CkEditorComponent from "../../common/ckEditor";
import { BreadCrumb } from "primereact/breadcrumb";
import { Toolbar } from "primereact/toolbar";

class AddContentWriter extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    items = [{ label: "Admin", url: "/#/admin" },
    { label: "Content Writer", url: '/#/admin/content-writer' },
    { label: "Vendor Script", url: `/#/admin/contentwriter/${this.props.params.id ? 'edit/' + this.props.params.id : 'add'}` }
    ];


    addOrUpdate = () => {
        let consultant = trimObj(this.state.consultant);

        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.state.consultant._id) {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/consultant`;

                this.serverlessService
                    .put(
                        url,
                        {
                            ...this.state.consultant,
                            // isProfileValid: true,
                        },
                        true
                    )
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    consultant: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.push(
                                        "/admin/content-writer"
                                    );
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/consultant/add`;
                this.serverlessService
                    .post(url, this.state.consultant, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    consultant: {
                                        referenceId: "",
                                        name: "",
                                        location: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onTableAddOrUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    getContentWriterById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            consultant: res.res.data,
                            isLoading: false,
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getContentWriterById(this.props?.params.id);
            }
        }
    }
    render() {
        const { consultant, formValidations } = this.state;

        return (
            <div className='p-5'>
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <div className="shadow-3 border-round overflow-hidden p-5 mt-5">
                    <div className="text-900 text-3xl font-medium mb-3 ml-2">{this.props.params.id ? 'Edit' : 'Add'} Vendor Script for {consultant.name}</div>
                    <div className="p-grid">
                        <div className="col-12">
                            <label className="font-bold block mb-2">
                                Vendor Script
                            </label>
                            <CkEditorComponent
                                value={consultant.vendorScript || ""}
                                onChange={(value) => {
                                    onTextChange(
                                        value,
                                        "vendorScript",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );

                                }}
                            />
                            {formValidations &&
                                !formValidations.fields["vendorScript"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["vendorScript"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>

                    <Toolbar
                        className="sp-toolbar sp-mt20"
                        right={() => {
                            return (<>
                                <Button
                                    label="Cancel"
                                    className="p-button-text hover:bg-primary mr-3"
                                    onClick={() => {
                                        this.props.history.push("/admin/content-writer");
                                    }}
                                />
                                <Button
                                    label={
                                        this.props?.params?.operation == "edit"
                                            ? "Update"
                                            : "Add"
                                    }
                                    onClick={this.addOrUpdate}
                                    autoFocus
                                    className="p-button-primary "
                                />
                            </>)
                        }}
                    ></Toolbar>
                </div>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {
                    (this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )
                }
            </div >
        );
    }
}

export default BaseComponent(AddContentWriter);
