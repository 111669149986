import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { logOut, trimObj } from "../../../Utils";
import { FeeDays } from "../../../Utils/constants";

import {
    getFormFields,
    isFormValid,
    onEmailChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
import MemberFields from "./member.json";
import { InputText } from "primereact/inputtext";

class AddMember extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            MemberFields,
            this.props.editMember || {

            }
        );
        this.state = {
            member: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAssign: false,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = () => {
        let member = trimObj(this.state.member);

        const formStatus = isFormValid(
            MemberFields,
            this.formFields.formValidations,
            member
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editMember) {
                this.setState({
                    isLoading: true,
                });

                const url = `/chitfunds/members`;
                this.serverlessService
                    .put(url, this.state.member, true)
                    .then((res) => {

                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    member: {
                                        name: "",
                                        fatherName: "",
                                        permanentAddress: "",
                                        adhar: "",
                                        mobileNumber: "",
                                        emailId: "",
                                        currentAddress: "",
                                        assigned: false,
                                        assignedTable: null,
                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.onMemberAddOrUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/chitfunds/members/add`;
                this.serverlessService
                    .post(url, this.state.member, true)
                    .then((res) => {

                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    member: {
                                        name: "",
                                        fatherName: "",
                                        permanentAddress: "",
                                        adhar: "",
                                        mobileNumber: "",
                                        emailId: "",
                                        currentAddress: "",
                                        assigned: false,
                                        assignedTable: null,
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onMemberAddOrUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };



    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onHide}
            />
            {/* <Button
                label="Assign"
                className=" p-button-primary "
                onClick={() => this.setState}
            /> */}
            <Button
                label={this.props.editMember ? "Update" : "Add"}
                onClick={this.addOrEdit}
                autoFocus
                className="ml-2 p-button-primary "
            />
        </div>
    );

    onDaysSelect = (e) => {

        let fee = this.state.member?.feeType?.fees?.find(
            (a) => a.id === e.value
        )?.fee;

        this.setState((prevState) => {
            return {
                assignData: {
                    ...prevState.assignData,
                    days: e.value,
                    fee: fee,
                },
            };
        });
    };

    componentDidMount() { }

    render() {
        const { member, formValidations } = this.state;

        return (
            <div>
                <Dialog
                    header={
                        this.props.editMember
                            ? "Edit Member"
                            : "Add Member"
                    }
                    draggable={false}
                    visible={true}
                    className="w-11"
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                >
                    <div className="p-grid row ">
                        <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                            <label className="font-bold block mb-2">
                                {" "}
                                Name <span className="sp-required">
                                    *
                                </span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "name",
                                        this,
                                        MemberFields,
                                        member,
                                        formValidations,
                                        "member",
                                        "formValidations"
                                    );
                                }}
                                value={member.name}
                            />

                            {formValidations &&
                                !formValidations.fields["name"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["name"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                            <label className="font-bold block mb-2">
                                Father Name <span className="sp-required">
                                    *
                                </span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "fatherName",
                                        this,
                                        MemberFields,
                                        member,
                                        formValidations,
                                        "member",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.member.fatherName}
                            />
                            {formValidations &&
                                !formValidations.fields["fatherName"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["fatherName"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                            <label className="font-bold block mb-2">
                                Aadhar <span className="sp-required">
                                    *
                                </span>
                            </label>

                            <InputText
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "adhar",
                                        this,
                                        MemberFields,
                                        member,
                                        formValidations,
                                        "member",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.member.adhar}
                            />
                            {formValidations &&
                                !formValidations.fields["adhar"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["adhar"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                            <label className="font-bold block mb-2">
                                Mobile Number <span className="sp-required">
                                    *
                                </span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "mobile",
                                        this,
                                        MemberFields,
                                        member,
                                        formValidations,
                                        "member",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.member.mobile}
                            />
                            {formValidations &&
                                !formValidations.fields["mobile"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["mobile"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                            <label className="font-bold block mb-2">
                                Email <span className="sp-required">
                                    *
                                </span>
                            </label>
                            <InputText
                                rows={1}
                                cols={30}
                                onChange={(e) => {
                                    onEmailChange(
                                        e.target.value,
                                        "email",
                                        this,
                                        MemberFields,
                                        member,
                                        formValidations,
                                        "member",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.member.email}
                            />
                            {formValidations &&
                                !formValidations.fields["email"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["email"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                            <label className="font-bold block mb-2">
                                Current Address <span className="sp-required">
                                    *
                                </span>
                            </label>
                            <InputTextarea
                                rows={3}
                                cols={30}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "currentAddress",
                                        this,
                                        MemberFields,
                                        member,
                                        formValidations,
                                        "member",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.member.currentAddress}
                            />
                            {formValidations &&
                                !formValidations.fields["currentAddress"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "currentAddress"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                            <label className="font-bold block mb-2">
                                Permanent Address <span className="sp-required">
                                    *
                                </span>
                            </label>
                            <InputTextarea
                                rows={3}
                                cols={30}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "permanentAddress",
                                        this,
                                        MemberFields,
                                        member,
                                        formValidations,
                                        "member",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.member.permanentAddress}
                            />
                            {formValidations &&
                                !formValidations.fields["permanentAddress"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "permanentAddress"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>
                </Dialog>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default AddMember;
