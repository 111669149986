import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
export default (
    state = {
        privileges: {},
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
            rows: 10,
        },
        globalSearch: "",
        totalRecords: 0,
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_ADMIN_PRIVILEGES:
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_ADMIN_PRIVILEGES:
            debugger
            return {
                ...state,
                isLoading: false,
                privileges: {
                    ...state.privileges,
                    [action.data.privilegeType]: action?.data?.data
                },
                totalRecords: action?.data?.totalRecords,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
            };
        case ACTIONS.ERROR_ADMIN_PRIVILEGES:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_ADMIN_PRIVILEGES:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                privileges: {}
            };
        default:
            return state;
    }
};
