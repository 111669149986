import React, { Component } from "react";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import AddCustomer from "./addMember";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import moment from "moment";
import { fallbackCopyTextToClipboard, logOut } from "../../../Utils";
import BaseComponent from "../../common/BaseComponent";
import { Tag } from "primereact/tag";
const items = [
    { label: "Admin", url: "/#/admin" },
    { label: "All Members", url: "/#/admin/chits-members" },
    { label: "Member Payment Balance Sheet" }];

const home = {
    icon: "pi pi-home",
    url: "/#/admin",
};

class PaymentsForNow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            member: null,
            paymentSummary: null,
            policies: [],
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    getPaymentsPending = () => {
        this.setState({
            isLoading: true
        });

        const url = `/chitfunds/policy/payments/${this.props.params.id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                //
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        ...res?.res?.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        noActivePolicies: (res.errMessage || res.res.message).includes('no active policies')
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Error",
                        detail: res.errMessage || res.res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut();
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getPaymentsPending();
    }

    getClipboardText = () => {

        const { member, policies, paymentSummary } = this.state;
        let copyText = `Name   :*${member.name}*\nTotal     :*${paymentSummary.totalMoneyToBePaid}/-*\nBalance     :*${paymentSummary.moneyToBePaid}/-*\nPaid     :*${paymentSummary.totalMoneyToBePaid - paymentSummary.moneyToBePaid}/-*\nPolicies :*${paymentSummary.totalPolicies}*`;
        let text = '';
        let number = 1;
        policies.map((policy, index) => {
            if (index == 0) {
                text += '\n\n';
            }
            text += ``
            policy.payment.monthHistoryToBePaid.map((payment) => {
                text += `${number}. Policy No: *${policy.policy.policyNumber}*        \n\tAmount: *${payment.moneyToBePaid}/-(${payment.paid ? 'paid' : 'unpaid'})*,      \n\tPremium: *${policy.payment.basicDetails.month}/${policy.policy.policy.totalMembers}*,      \n\tDate: *${moment(policy.policy.policy.startDate).add(parseInt(policy.payment.basicDetails.month - 1), "months").format("DD/MM/YYYY")}*\n`
                number++;
            })
        })
        copyText += text + `\n\nPlease pay *${paymentSummary.moneyToBePaid}/-*`;
        return copyText;
    }

    copyToClipboard = () => {
        let copyText = this.getClipboardText();

        if (navigator.clipboard) {
            navigator.clipboard.writeText(copyText).then(() => {
                this.toast.show({
                    severity: "success",
                    summary: "Copied",
                    detail: "Member policy details are copied.",
                    life: 3000,
                });
            });
        } else {
            let status = fallbackCopyTextToClipboard(copyText)
            if (status) {
                this.toast.show({
                    severity: "success",
                    summary: "Copied",
                    detail: "Member policy details are copied.",
                    life: 3000,
                });
            }
        }
    };

    shareOnWhatsapp = () => {
        let copyText = this.getClipboardText();
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(copyText)}`, '_self')
    };

    render() {
        const { member, policies, paymentSummary } = this.state;

        return (<div className="p-4">
            <BreadCrumb model={items} home={home} />
            <Authorize
                permId={PERMISSIONS.CHITS_MEMBERS_SHOW_POLICIES}
            >
                {paymentSummary && (<div className="surface-card p-4 shadow-2 border-round">
                    <div className="font-medium text-3xl text-900 mb-3 uppercase">{member.name}
                        <Button
                            type="button"
                            icon="pi pi-copy"
                            onClick={this.copyToClipboard}
                            className="p-button-rounded p-button-text"
                        ></Button>
                        <Button
                            type="button"
                            icon="pi pi-share-alt"
                            onClick={this.shareOnWhatsapp}
                            className="p-button-rounded p-button-text"
                        ></Button>

                    </div>
                    <div className="text-500 mb-5"></div>
                    <ul className="list-none p-0 m-0 border-top-1 border-300">
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-2 font-medium">Father Name</div>
                            <div className="text-900 w-full md:w-10 capitalize">{member.fatherName}</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-2 font-medium">Phone Number</div>
                            <div className="text-900 w-full md:w-10 capitalize">{member.mobile}</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap">
                            <div className="text-500 w-full md:w-2 font-medium">Email</div>
                            <div className="text-900 w-full md:w-10 line-height-3 capitalize">{member.email}</div>
                        </li>
                        <li className="flex align-items-center py-3 px-2 flex-wrap surface-ground">
                            <div className="text-500 w-full md:w-2 font-medium">Address</div>
                            <div className="text-900 w-full md:w-10 capitalize">{member.permanentAddress
                            }</div>
                        </li>
                    </ul >
                    <div className="pt-4">
                        <div className="grid">
                            <div className="col-12 md:col-6 lg:col-6 xl:col-3 p-3">
                                <div className="p-3 text-center bg-blue-500 border-round">
                                    <span className="inline-flex justify-content-center align-items-center bg-blue-600 border-circle mb-3" style={{ width: '49px', height: '49px' }}>
                                        <i className="pi pi-paypal text-xl text-white"></i>
                                    </span>
                                    <div className="text-3xl font-medium text-white mb-2">{paymentSummary.totalMoneyToBePaid}</div>
                                    <span className="text-blue-100 font-medium">Total</span>
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 xl:col-3 p-3">
                                <div className="p-3 text-center bg-yellow-500 border-round">
                                    <span className="inline-flex justify-content-center align-items-center bg-yellow-600 border-circle mb-3" style={{ width: '49px', height: '49px' }}>
                                        <i className="pi pi-thumbs-down text-xl text-white"></i>
                                    </span>
                                    <div className="text-3xl font-medium text-white mb-2">{paymentSummary.moneyToBePaid}</div>
                                    <span className="text-yellow-100 font-medium">Balance</span>
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 xl:col-3 p-3">
                                <div className="p-3 text-center bg-green-500 border-round">
                                    <span className="inline-flex justify-content-center align-items-center bg-green-600 border-circle mb-3" style={{ width: '49px', height: '49px' }}>
                                        <i className="pi pi-thumbs-up text-xl text-white"></i>
                                    </span>
                                    <div className="text-3xl font-medium text-white mb-2">{paymentSummary.totalMoneyToBePaid - paymentSummary.moneyToBePaid}</div>
                                    <span className="text-green-100 font-medium">Paid</span>
                                </div>
                            </div>
                            <div className="col-12 md:col-6 lg:col-6 xl:col-3 p-3">
                                <div className="p-3 text-center bg-orange-500 border-round">
                                    <span className="inline-flex justify-content-center align-items-center bg-orange-600 border-circle mb-3" style={{ width: '49px', height: '49px' }}>
                                        <i className="pi pi-shopping-cart text-xl text-white"></i>
                                    </span>
                                    <div className="text-3xl font-medium text-white mb-2">{paymentSummary.totalPolicies}</div>
                                    <span className="text-orange-100 font-medium">Policies</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        policies?.length ? <div className="p-2 pb-5 mt-3">
                            {/* <h4 className="p-2">Total Policies breakdown</h4> */}
                            <ul className="list-none p-0 m-0">
                                {
                                    policies?.map((policy) => {
                                        const paid = policy?.payment?.monthHistoryToBePaid[0].paid
                                        return <li className={`flex flex-column md:flex-row md:align-items-center md:justify-content-between p-3 border-1 mb-3 ${paid ? 'bg-green-50 border-green-500' : 'bg-yellow-50 border-yellow-500'} `} style={{ borderRadius: '10px' }}>
                                            <div>
                                                <div>
                                                    <span className="inline-flex justify-content-center align-items-center w-2rem h-2rem border-circle border-1 border-yellow-200">
                                                        <i className={`pi pi-file text-${paid ? 'green' : 'yellow'}-700`}></i>
                                                    </span>
                                                    <span className={`text-${paid ? 'green' : 'yellow'}-700 font-bold ml-2`}>Policy No. {policy?.policy?.policyNumber}  || Month {policy?.payment?.basicDetails?.month} / {policy?.policy?.policy?.totalMonths}</span>
                                                </div>
                                                <p className={`text-${paid ? 'green' : 'yellow'}-700 mt-2 mb-0`}>Due <span className="font-medium">{moment(
                                                    policy?.payment
                                                        ?.basicDetails.date
                                                ).format("MM/DD/YYYY") ||
                                                    "--NA--"}</span></p>
                                            </div>
                                            <div className="flex align-items-center justify-content-between md:justify-content-end mt-3 md:mt-0">
                                                <span className={`bg-${paid ? 'green' : 'yellow'}-400 text-${paid ? 'green' : 'yellow'}-900 font-bold text-sm py-1 px-2`} style={{ borderRadius: '10px' }}>{
                                                    policy?.payment?.monthHistoryToBePaid[0]
                                                        .paid ? 'PAID' : 'PENDING'
                                                }</span>
                                                <div className="text-right ml-3">
                                                    <span className={`text-${paid ? 'green' : 'yellow'}-700 font-bold`}>{
                                                        policy?.payment
                                                            ?.monthHistoryToBePaid[0]
                                                            ?.moneyToBePaid
                                                    }</span>
                                                    <p className={`mt-1 mb-0 text-${paid ? 'green' : 'yellow'}-700`}>INR</p>
                                                </div>
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </div> : <></>
                    }
                </div >)}
                {
                    this.state.noActivePolicies && <Card className="p-3 m-3">
                        <p>No active policies</p>
                    </Card>
                }
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {
                    (this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )
                }
            </Authorize>
        </div >
        );
    }
}

export default BaseComponent(PaymentsForNow);
