import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import UserFields from "./user.json";
import ServerlessService from "../../../Service/ServerlessService";
import {
    getFormFields,
    isFormValid,
    onNumberChange,
    onTextChange,
    trimAll,
} from "../../../Utils/formHelper";
import { Button } from "primereact/button";
import { logOut, trimObj } from "../../../Utils";

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(UserFields, this.props?.editUser || { appType: process.env.REACT_APP_TARGET });
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    footerContent = () => {
        return (
            <>
                <Button label="Cancel" onClick={this.props.onHide}></Button>
                <Button
                    label={this.props.editUser ? "Update" : "Save"}
                    onClick={() => this.addOrUpdate()}
                ></Button>
            </>
        );
    };

    addOrUpdate = () => {
        let user = trimObj(this.state.user);

        const formStatus = isFormValid(
            UserFields,
            this.formFields.formValidations,
            user
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editUser) {
                this.setState({
                    isLoading: true,
                });

                const url = `/user/update`;

                this.serverlessService
                    .put(url, this.state.user, true)
                    .then((res) => {

                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    user: {
                                        name: "",
                                        email: "",
                                        phoneNumber: "",
                                        password: "",
                                        passwordConfirm: "",
                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.onUserAddAndUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/user/save`;
                this.serverlessService
                    .post(url, this.state.user, true)
                    .then((res) => {

                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    user: {
                                        name: "",
                                        email: "",
                                        phoneNumber: "",
                                        password: "",
                                        passwordConfirm: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onUserAddAndUpdate();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    inActiveUser = () => {
        const url = `/user/inActive`;

        let user = this.state.user;
        this.serverlessService
            .post(url, user, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            user: {
                                name: "",
                                email: "",
                                phoneNumber: "",
                                password: "",
                                passwordConfirm: "",
                            },

                            isLoading: false,
                        },
                        () => {
                            this.props.onUserAddAndUpdate();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                // console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    render() {
        const { user, formValidations } = this.state;

        return (
            <div>
                <Dialog
                    header={this.props.editUser ? "Edit User" : "Add User"}
                    visible={true}
                    style={{ width: "49vw" }}
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                >
                    <label className="font-bold block mb-2">Name</label>
                    <InputText
                        onChange={(e) => {
                            onTextChange(
                                e.target.value,
                                "name",
                                this,
                                UserFields,
                                user,
                                formValidations,
                                "user",
                                "formValidations"
                            );
                        }}
                        value={user.name}
                    />
                    {formValidations &&
                        !formValidations.fields["name"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["name"].errorMsg}
                            </p>
                        )}
                    <br />
                    <br />
                    <label className="font-bold block mb-2">Email</label>
                    <InputText
                        onChange={(e) => {
                            onTextChange(
                                e.target.value,
                                "email",
                                this,
                                UserFields,
                                user,
                                formValidations,
                                "user",
                                "formValidations"
                            );
                        }}
                        value={user.email}
                    />
                    {formValidations &&
                        !formValidations.fields["email"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["email"].errorMsg}
                            </p>
                        )}
                    <br />
                    <br />
                    <label className="font-bold block mb-2">Phone</label>
                    <InputText
                        onChange={(e) => {
                            onTextChange(
                                e.target.value,
                                "phoneNumber",
                                this,
                                UserFields,
                                user,
                                formValidations,
                                "user",
                                "formValidations"
                            );
                        }}
                        value={user.phoneNumber}
                    />

                    {formValidations &&
                        !formValidations.fields["phoneNumber"].isValid && (
                            <p className="p-error">
                                {formValidations.fields["phoneNumber"].errorMsg}
                            </p>
                        )}

                    <br></br>
                    <br></br>
                    {!this.props.editUser && (
                        <>
                            <label className="font-bold block mb-2">
                                Password
                            </label>
                            <InputText
                                type="password"
                                autoComplete="new-password"
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "password",
                                        this,
                                        UserFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                                value={user.password}
                            />

                            {formValidations &&
                                !formValidations.fields["password"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["password"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                            <br />
                            <label className="font-bold block mb-2">
                                Confirm password
                            </label>
                            <InputText
                                type="password"
                                autoComplete="new-password"
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "passwordConfirm",
                                        this,
                                        UserFields,
                                        user,
                                        formValidations,
                                        "user",
                                        "formValidations"
                                    );
                                }}
                                value={user.passwordConfirm}
                            />

                            {formValidations &&
                                !formValidations.fields["passwordConfirm"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "passwordConfirm"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </>
                    )}
                    {this.props.editUser && (
                        <>
                            <Button
                                label={
                                    this.props.editUser?.isActive
                                        ? "InActive"
                                        : "Active"
                                }
                                onClick={() => this.inActiveUser()}
                            ></Button>
                        </>
                    )}
                </Dialog>
            </div>
        );
    }
}

export default AddUser;
