import { BasicLazyParams } from "../../Utils/constants";
import { ACTIONS } from "../actionTypes";
import { v4 as uuidv4 } from "uuid";
export default (
    state = {
        comments: [],
        isLoading: false,
        error: false,
        errorMessage: "",
        lazyParams: {
            ...BasicLazyParams,
            sortField: "-createdAt",
            sortOrder: 1,
        },
        globalSearch: "",
        totalRecords: 0,
        isAddLoading: false,
        currentConsultantId: null
    },
    action
) => {
    switch (action.type) {
        case ACTIONS.REQUEST_CONSULTANT_MONITOR:
            // 
            return {
                ...state,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
                comments: action?.data?._id !== state.currentConsultantId ? [] : state.comments,
                currentConsultantId: action?.data?._id,
                globalSearch: action?.data?.globalSearch || state?.globalSearch,
                isLoading: true,
                error: false,
            };
        case ACTIONS.RECEIVED_CONSULTANT_MONITOR:
            return {
                ...state,
                isLoading: false,
                comments: action?.data?.data,
                totalRecords: action?.data?.totalRecords,
                lazyParams: {
                    ...state.lazyParams,
                    ...action?.data?.lazyParams,
                },
            };
        case ACTIONS.ERROR_CONSULTANT_MONITOR:
            return {
                ...state,
                isLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };
        case ACTIONS.CLEAR_CONSULTANT_MONITOR:
            return {
                isLoading: false,
                error: false,
                errorMessage: "",
                comments: []
            };
        case ACTIONS.REQUEST_ADD_COMMENT:
            return {
                ...state,
                isAddLoading: true,
                error: false,


            };
        case ACTIONS.RECEIVED_ADD_COMMENT:
            return {
                ...state,
                isAddLoading: false,
                comments: [
                    action?.data,
                    ...state.comments
                ],
                uuid: uuidv4(),
                totalRecords: state?.totalRecords + 1,
            };
        case ACTIONS.ERROR_ADD_COMMENT:
            return {
                ...state,
                isAddLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };

            return {
                ...state,
                isAddLoading: false,
                comments: state.comments.map(a => {
                    if (a._id === action?._id) {
                        return action?.data;
                    }
                    return a;
                }),

                uuid: uuidv4(),
                totalRecords: state?.totalRecords + 1,
            };
        case ACTIONS.ERROR_ADD_COMMENT:
            return {
                ...state,
                isAddLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };

            return {
                ...state,
                isAddLoading: false,
                comments: state.comments.filter(a => a._id !== action._id),

                uuid: uuidv4(),
                totalRecords: state?.totalRecords - 1,
            };
        case ACTIONS.ERROR_ADD_COMMENT:
            return {
                ...state,
                isAddLoading: false,
                error: true,
                errorMessage: action?.err?.message,
            };


        default:
            return state;
    }
};
