import React, { useEffect, useState, useRef } from "react";
import { Menubar } from "primereact/menubar";
import { Dropdown } from "primereact/dropdown";

import { StyleClass } from "primereact/styleclass";
import { Ripple } from "primereact/ripple";

import { withRouter, Link } from "react-router-dom";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { TieredMenu } from "primereact/tieredmenu";
import { SERVERLESS_URL } from "../../Service/config";
import { APP_TARGETS } from "../../Utils/constants";

import axios from "axios";
import { saveUserSession } from "../../store/actions";
import { InputText } from "primereact/inputtext";

const NavFooter = (props) => {
    const [isFeatureListVisible, setIsFeatureListVisible] = useState(false);
    const [logoutTimeout, setLogoutTimeout] = useState(null);
    const rootBtnRef = useRef(null);
    const btnRef4 = useRef(null);
    const btnRef6 = useRef(null);
    const btnRef3 = useRef(null);
    var menu = useRef(null);
    function onLogout() {
        localStorage.clear();

        props.history.push("/");
        window.location.reload();
    }

    const removeNode = () => {
        const iframes = document.getElementsByTagName('iframe'); // Get all iframe elements

        for (let index = 0; index < iframes.length; index++) {
            if (iframes[index]) {
                iframes[index].remove();;
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {
            removeNode()
        }, 3000);
    }, [])

    //  const onLogout = () => {
    //     axios.post(`${SERVERLESS_URL}/admin/logout`, null, {
    //         headers: {
    // 'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
    //             Authorization: `Bearer ${localStorage.getItem("Bearer")}`,
    //         },
    //     })
    //     .then((response) => {
    //         // Clear stored user data
    //         localStorage.clear();
    //         // Redirect to the login page
    //         props.history.push("/login");
    //         // Reload the window
    //        
    //     })
    //     .catch((error) => {
    //         console.error('Logout failed:', error);
    //         // Still clear stored user data and redirect even if logout fails
    //         localStorage.clear();
    //         props.history.push("/login");
    //       
    //     });
    // };

    // Function to toggle feature list visibility
    const toggleFeatureList = () => {
        setIsFeatureListVisible(!isFeatureListVisible);
    };

    // Function to initiate session check
    const initiateGetSession = () => {
        let userType = localStorage.getItem("userType");

        if (userType) {
            const url = `${SERVERLESS_URL}/admin/session`;

            let config = {
                headers: {
                    'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
                    Authorization: `Bearer ${localStorage.getItem("Bearer")
                        ? localStorage.getItem("Bearer")
                        : ""
                        }`,
                },
            };
            axios
                .get(url, config)
                .then((res) => {
                    if (res && res.data) {
                        props.saveUserSession(res.data);
                    } else {
                        // Handle case when session data is not available
                    }
                })
                .catch((err) => {
                    // Handle error
                });
        } else {
            // Handle case when userType is not available
        }
    };

    // useEffect to initiate session check on component mount
    useEffect(() => {
        if (!props.authenticated) {
            initiateGetSession();
        }
    }, []);

    // Event handler to reset logout timeout on user activity
    // const handleActivity = () => {
    //     clearTimeout(logoutTimeout);
    //     setLogoutTimeout(setTimeout(onLogout, 5 * 60  * 1000)); // 5 minutes
    // };

    // // useEffect to add event listeners for user activity
    // useEffect(() => {
    //     document.addEventListener('mousemove', handleActivity);
    //     document.addEventListener('keydown', handleActivity);

    //     // Cleanup function to remove event listeners
    //     return () => {
    //         document.removeEventListener('mousemove', handleActivity);
    //         document.removeEventListener('keydown', handleActivity);
    //     };
    // }, []);

    // Menu items
    const items = [
        {
            label: "Log Out",
            icon: "pi pi-power-off",
            command: () => {
                onLogout();
            },
        },
    ];

    console.log(props, "rrr");
    const menuItems = [
        {
            label: "Features ▼",
        },
        {
            label: "Pricing",
        },
        {
            label: "About",
        },
        {
            label: "Contact",
        },
    ];

    const getNavbarModules = () => {
        let ModuleMenuItems = [{
            label: 'Home',
            icon: 'pi pi-home',
            // className: `${(window.location.pathname == '/dashboard') ? 'sp-menu-active' : 'ddd'}`,
            command: () => {
                this.props.history.push('/admin')
            },
        },


        {
            label: 'Services',
            // className: `${(window.location.pathname == '/organization' || window.location.pathname == '/reseller' || window.location.pathname == '/cleints') ? 'ma-menu-active' : ''}`,
            // command: () => { this.props.history.push('/organization') },
            //command: () => { console.log('abcdef'); },
            //url: '/home'
            items: [
                {
                    label: 'Screening Calls',
                    // className: `${window.location.pathname == '/organization' ? 'sp-sub-menu-active' : 'ddd'}`,
                    command: () => {
                        this.props.history.push('/screeningcalls')
                    },
                    isPermRequired: true,
                    uId: 'a7ee7332-791a-4fd5-b457-1226b40186b3',
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Question Search',
                    // className: `${window.location.pathname == '/organization' ? 'ma-sub-menu-active' : ''}`,
                    command: () => {
                        this.props.history.push('/search-bank')
                    },
                    isPermRequired: true,
                    uId: 'fa41ed34-3f8b-4dd2-ab70-039f9aee713b',
                    // icon: 'pi pi-fw pi-align-left'
                },
                {
                    label: 'Learning',
                    // className: `${window.location.pathname == '/boards' ? 'ma-sub-menu-active' : ''}`,
                    command: () => {
                        this.props.history.push('/consultant-learning-plan')
                    },
                    isPermRequired: true,
                    uId: 'eec810e8-3744-4302-84a0-16fdc82ef514',
                    // icon: 'pi pi-fw pi-align-left'
                }
            ]
        },
        {
            label: 'FAQ',
            // className: `${window.location.pathname == '/boards' ? 'ma-sub-menu-active' : ''}`,
            command: () => {
                this.props.history.push('/faq')
            },
            icon: 'pi pi-fw pi-align-left'
        }

        ];

        if (props.user?.isAdmin) {
            ModuleMenuItems = [{
                label: 'Admin',
                // className: `${window.location.pathname == '/boards' ? 'ma-sub-menu-active' : ''}`,
                command: () => {
                    this.props.history.push('/admin')
                },

                icon: 'pi pi-home'
            }, ...ModuleMenuItems]
        }

        return ModuleMenuItems;


    }
    return (
        <div>
            <div>
                <div
                    className="bg-gray-900 py-3 px-6 shadow-2 flex align-items-center justify-content-between relative lg:static border-bottom-1 border-gray-800"
                    style={{
                        minHeight: "60px",
                        position: "relative",
                        top: 0,
                        width: "100%",
                        zIndex: 1000,
                    }}
                >
                    {props.logoVisible && props.logoSquare && (
                        <img
                            src={props.logoSquare}
                            onClick={() => {
                                props.history.push("/");
                            }}
                            alt="no"
                            className={`off-logo ${props.logoSize == "large"
                                ? "off-logo-large"
                                : ""
                                }`}
                        />
                    )}
                    <h5
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'break-word'
                        }}
                        className={`app-title ${props.logoSize == "large"
                            ? "app-title-large"
                            : ""
                            }`}
                        onClick={() => {
                            props.history.push("/");
                        }}
                    >
                        {props.brandNameVisible && props.orgName}{" "}
                    </h5>
                    <StyleClass
                        nodeRef={btnRef4}
                        selector="@next"
                        enterClassName="hidden"
                        leaveToClassName="hidden"
                        hideOnOutsideClick
                    >
                        <a
                            ref={btnRef4}
                            className="p-ripple cursor-pointer block lg:hidden text-gray-400"
                        >

                            <i className="pi pi-bars text-4xl"></i>
                            <Ripple />
                        </a>
                    </StyleClass>
                    <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                        <ul className="list-none ml-3 p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                            {/* <Menubar model={getNavbarModules()}
                                className="m-menu-bar "
                            /> */}
                            <>
                                {props.user?.isAdmin && <li>
                                    <Link
                                        to="/admin"
                                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                    >
                                        <i className="pi pi-home mr-2"></i>{" "}
                                        Admin
                                    </Link>
                                </li>
                                }

                                {
                                    props.authenticated ? <>
                                        {process.env.REACT_APP_TARGET == APP_TARGETS.CONSULTANT && (<li>
                                            <StyleClass nodeRef={btnRef3} selector="@next" enterClassName="hidden" enterActiveClassName="scalein" leaveToClassName="hidden" leaveActiveClassName="fadeout" hideOnOutsideClick>
                                                <a ref={btnRef3} className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full">
                                                    <i className="pi pi-users mr-2"></i>
                                                    <span>Services</span>
                                                    <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
                                                    <Ripple />
                                                </a>
                                            </StyleClass>
                                            <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute bg-gray-900 hidden origin-top w-full lg:w-15rem cursor-pointer lg:border-1 border-gray-800">


                                                {
                                                    !props.isAdmin && <li>
                                                        <Link
                                                            to="/screeningcalls"
                                                            className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                                        >
                                                            <i className="pi pi-whatsapp mr-2"></i>{" "}
                                                            Screening Calls
                                                        </Link>
                                                    </li>
                                                }
                                                {
                                                    !props.isAdmin &&
                                                    <li>
                                                        <Link
                                                            to="/search-bank"
                                                            className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                                        >
                                                            <i className="pi pi-whatsapp mr-2"></i>{" "}
                                                            Questions
                                                        </Link>
                                                    </li>
                                                }
                                                <li>
                                                    <Link
                                                        to="/consultant-learning-plan"
                                                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                                    >
                                                        <i className="pi pi-book mr-2"></i>{" "}
                                                        Learning
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/faq"
                                                        className="p-ripple flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-gray-400 hover:text-white hover:bg-gray-800 font-medium border-round cursor-pointer transition-colors transition-duration-150 w-full"
                                                    >
                                                        <i className="pi pi-question-circle mr-2"></i>{" "}
                                                        FAQ
                                                    </Link>
                                                </li>


                                            </ul>
                                        </li>)}
                                    </> : <>
                                        <li className="md:hidden">
                                            <Button
                                                label="Login / SignIn"
                                                onClick={() => {
                                                    props.history.push(
                                                        "/login"
                                                    );
                                                }}
                                                className="p-button-outlined font-bold mt-3"
                                            />
                                        </li>
                                    </>
                                }

                            </>
                        </ul>
                        <ul className="list-none ml-3 p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                            <li className="flex-order-2 lg:flex-order-0">
                                <div className="flex px-6 p-3 lg:px-3 lg:py-2">
                                    {props.user?.name ? (
                                        <div
                                            onClick={(event) =>
                                                menu.toggle(event)
                                            }
                                            style={{
                                                position: "absolute",
                                                right: "20px",

                                                transform: "translateY(-50%)",
                                                color: "white",
                                            }}
                                        >
                                            {props.user?.name}

                                            <img
                                                src="assets/images/blocks/avatars/circle/avatar-m-1.png"
                                                alt="avatar-f-1"
                                                className="mr-3 ml-3 lg:mr-0"
                                                style={{
                                                    width: "32px",
                                                    height: "32px",
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full bg-gray-900 left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row"></ul>
                                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                                    <li className="border-top-1 border-gray-800 lg:border-top-none">
                                                        <div className="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
                                                            <Button
                                                                label="Login / SignIn"
                                                                onClick={() => {
                                                                    props.history.push(
                                                                        "/login"
                                                                    );
                                                                }}
                                                                className="p-button-outlined font-bold"
                                                            />
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                </div>

                            </li>

                        </ul>
                    </div>

                    {/* <ul className="list-none p-0 m-0 flex lg:align-items-center text-900 select-none flex-column lg:flex-row cursor-pointer">

                        {props.user?.userType == "client" && (

                            <>
                                {process.env.REACT_APP_TARGET == APP_TARGETS.MATRIMONY && <>

                                    <li>
                                        <Link
                                            to="/profiles"
                                            className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                        >
                                            Profiles
                                            <Ripple />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/my-account"
                                            className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                        >
                                            My Account
                                            <Ripple />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to="/visited-profiles"
                                            className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                        >
                                            Visited Profiles
                                            <Ripple />
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to="/user-packages"
                                            className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                        >
                                            Packages
                                            <Ripple />
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to="/user-orders"
                                            className="p-ripple flex px-0 lg:px-5 py-3 hover:text-blue-600 font-medium transition-colors transition-duration-150"
                                        >
                                            Orders
                                            <Ripple />
                                        </Link>
                                    </li>
                                </>}
                            </>
                        )}
                    </ul> */}
                </div>
            </div>

            <div style={{ minHeight: "calc(100vh - 0px)" }}>
                {props.children}
            </div>

            <div className="bg-primary-900 px-4 py-6 md:px-6 lg:px-8 text-center">
                {/* <img src="assets/images/blocks/logos/bastion-purple.svg" alt="Image" height="50" /> */}
                <div className="font-medium text-white mt-4 mb-3 capitalize">&copy; {new Date().getFullYear()} {props.orgName}</div>
                <p className="text-white line-height-3 mt-0 mb-4 capitalize">{props.address}, {props.city}, {props.state}, {props.pincode}, {props.country}</p>
                <p className="text-white line-height-3 mt-0 mb-4 capitalize">Contact at {props.contactNumber1}, {props.contactNumber2}</p>

            </div>
            {/* 
            <div className="bg-primary-900 px-4 py-8 md:px-6 lg:px-8">
                <div className="grid">
                    <div className="col-12 md:col-3 flex flex-wrap justify-content-start">

                    </div>
                    <div className="col-12 md:col-3">
                        <div className="text-white font-bold line-height-3 mb-3">
                            Company
                        </div>
                        <div className="text-white mb-3">
                            <i className="pi pi-building border-round p-1 mr-2"></i>
                            {props.orgName}
                        </div>
                        <div className="text-white mb-3">
                            <i className="pi pi-building border-round p-1 mr-2"></i>

                        </div>
                        <div className="text-white mb-3">
                            <i className="pi pi-building border-round p-1 mr-2"></i>
                        </div>
                    </div>
                    <div className="col-12 md:col-3 text-gray-200">
                        <div className="text-white font-bold line-height-3 mb-3">
                            Contact Details
                        </div>
                        <div className="text-white mb-3">
                            <i className="pi pi-phone border-round p-1 mr-2"></i>
                            {props.contactNumber1}
                        </div>
                        <div className="text-white mb-3">
                            <i className="pi pi-phone border-round p-1 mr-2"></i>
                            {props.contactNumber2}
                        </div>
                    </div>
                </div>
            </div> */}
            <TieredMenu
                model={items}
                popup
                ref={(el) => (menu = el)}
                style={{ marginTop: "22px" }}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAdmin: state.user?.user?.isAdmin,
    user: state.user?.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgName: state?.orgData?.orgName,
    address: state?.orgData?.address,
    city: state?.orgData?.city,
    country: state?.orgData?.country,
    state: state?.orgData?.state,
    pincode: state?.orgData?.pincode,
    contactNumber1: state?.orgData?.contactNumber1,
    contactNumber2: state?.orgData?.contactNumber2,
    logoRectangle: state?.orgData?.logoRectangle,
    isLoading: state?.orgData?.isLoading,
    isSuperOrg: state?.orgData?.isSuper,
    brandNameVisible: state?.orgData?.brandNameVisible,
    logoVisible: state?.orgData?.logoVisible,
    theme: state?.orgData?.theme,
    logoSquare: state?.orgData?.logoSquare,
    logoSize: state?.orgData?.logoSize,
});

export default connect(mapStateToProps, {
    saveUserSession,
})(withRouter(NavFooter));
