import {
    combineReducers
} from 'redux';
import user from "./userDataReducer";
import permissions from './permissionsReducer';
import orgData from './orgReducer';
import consultantTags from './consultantTagReducer';
import consultants from './consultantReducer';
import learningResources from './learningResources';
import learningPlans from './learningPlan';
import contentWriters from './contentWriterReducer';
import documentEditors from './documentEditingReducer';
import documentPreperators from './documentPreperatorReducer';
import documentVerifiers from './documentVerifierReducer';
import preMarketReviews from './preMarketReviewReducer';
import questionBanks from './questionBankReducer';
import salesTeams from './salesTeamReducer';
//import documentEditingRequests from './documentEditingRequestReducer';
import interviews from './interviewsReducer';
import submissions from './submissionsReducer';
import salesTeamHead from './salesTeamHeadReducer';
import technicalPreperators from './technicalPreperatorReducer';
import consultantLearningPlans from './consultantLearningPlansReducer';
import faq from './faqReducer';
import contact from './contactReducer';
import copyhelper from './copyhelperReducer';
import dropdownUser from './dropdownUsersReducer';
import consultantFAQs from './consultantFAQs';
import userQuestions from './userQuestionsReducer';
import approvedQuestions from './approvedQuestionsReducer';
import marketingHistoryReducer from './marketingHistoryReducer';
import recruiterList from './recruiterListReducer';
import consultantMonitor from './consultantMonitor';
import consultantNotes from './consultantNotes';
import allConsultants from './allConsultantReducer';
import interviewDropdown from './interviewDropdownReducer';
import screeningCalls from './screeningCallsReducer';
import adminScreeningCalls from './adminScreeningCallsReducer';
import adminPrivileges from './adminPrivileges';

const rootReducer = combineReducers({
    user,
    permissions,
    orgData,
    consultantsTagging: consultantTags,
    consultants,
    learningResources,
    learningPlans,
    contentWriters,
    documentEditors,
    documentPreperators,
    documentVerifiers,
    preMarketReviews,
    questionBanks,
    salesTeams,
    // documentEditingRequests,
    interviews,
    submissions,
    salesTeamHead,
    technicalPreperators,
    consultantLearningPlans,
    faq,
    contact,
    consultantFAQs,
    copyhelper,
    dropdownUser,
    userQuestions,
    approvedQuestions,
    marketingHistoryReducer,
    recruiterList,
    comments: consultantMonitor,
    notes: consultantNotes,
    allConsultants,
    interviewDropdown,
    screeningCalls,
    adminScreeningCalls,
    adminPrivileges
});


export default rootReducer;