import React, { Component } from "react";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import CreateRole from "./createRole";
import "./styles.scss";
import Authorize, {
    PERMISSIONS,
} from "./../../../../session/authorize";
import LoadingComponent from "./../../../../LoadingComponent";
import { BreadCrumbHome, logOut, warningDialogInit } from "./../../../../Utils";
import Service from "./../../../../Service";
import ServerlessService from "./../../../../Service/ServerlessService";
import { BreadCrumb } from "primereact/breadcrumb";

const BasicLazyParams = {
    first: 0,
    rows: 10,
    page: 0,
    search: "",
    sortField: null,
    sortOrder: null,
    filters: {},
};

class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            globalFilter: "",
            globalSearch: "",
            columns: [{ columnName: "Role Name" }],
            roles: [],
            editRoleData: null,
            showSearch: false,
            isShowCreate: false,
            isAdd: false,
            dialogData: {},
            warningDialog: warningDialogInit,
            totalRecords: 0,
            globalSearch: "",
            lazyParams: BasicLazyParams,
            isLoading: true
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();

    }

    items = [
        { label: 'Admin', url: "/#/admin" },
        { label: 'Admin Roles', url: "/#/admin/admin-roles" },
    ]

    getRoles = () => {
        this.setState({
            isLoading: true,
        });

        let lazyParams = this.state.lazyParams;
        let url = `/authorize/getRoles?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${this.state.globalSearch}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.res && res.res.data) {
                    this.setState({
                        roles: res.res.data.map((r, index) => {
                            r.slNo = index + 1;
                            return r;
                        }),
                        totalRecords: res.res.totalRecords,
                        isLoading: false,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getRoles();
    }

    changeStatus = (e, rowData) => {
        let data = { ...rowData };
        if (e.value == true) {
            data.status = "Active";
        } else {
            data.status = "InActive";
        }

        this.setState({
            warningDialog: {
                visible: true,
                headerMsg: "Are you Sure?",
                message: `You are changing this Role status ${e.value ? "InActive" : "Active"
                    } to ${e.value ? "Active" : "InActive"}`,
            },
            editRoleData: data,
        });
    };

    onConfirmChange = () => {
        this.setState({
            warningDialog: warningDialogInit,
            isLoading: true,
        });
        const url = `/authorize/updateRole`;
        this.serverlessService
            .put(url, this.state.editRoleData, true)
            .then((res) => {
                if (res && res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.onRoleCreate(false);
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
                this.setState({
                    isLoading: false,
                });
            });
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };

    actionsTemplate = (rowData) => {
        return (
            <>
                <Authorize permId={PERMISSIONS.ROLE_EDIT}>
                    <Button
                        onClick={() => this.showEditRoleDialog(rowData)}
                        tooltip="Edit Role"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-pencil"
                        className="p-button-rounded mr-3" />
                </Authorize>
            </>
        );
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    page: event.page,
                    rows: event.rows,
                    first: event.first,
                },
            };
        }, this.getRoles);
    };

    showEditRoleDialog = (editRoleData) => {
        this.setState({
            editRoleData,
            isShowCreate: true,
            isAdd: false,
        });
    };

    showRoleCreateDialog = () => {
        this.setState({
            isShowCreate: true,
            editRoleData: null,
            isAdd: true,
        });
    };

    hideRoleCreateDialog = () => {
        this.setState({
            isShowCreate: false,
            editRoleData: null,
            isAdd: false,
        });
    };

    onRoleCreate = (isCreate, data) => {
        this.setState(
            {
                isShowCreate: false,
                editRoleData: null,
                lazyParams: BasicLazyParams,
            },
            this.getRoles
        );

        this.toast.show({
            severity: "success",
            summary: isCreate ? "Role Created" : "Role Updated",
            detail: `Role Successfully ${isCreate ? "Created" : "Updated"}`,
            life: 3000,
        });

    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getRoles);
    };

    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Admin Roles</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={PERMISSIONS.ROLE_GENERAL_SEARCH}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.state.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={PERMISSIONS.ROLE_GENERAL_SEARCH}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.ROLE_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        return (
            <div className='p-5'>
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <Authorize permId={PERMISSIONS.ROLE_ADD}>
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className=" mt-3 ml-4"
                        tooltip="Add Role"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        outlined
                        onClick={this.showRoleCreateDialog}
                    />
                </Authorize>
                <Authorize permId={PERMISSIONS.ROLE_LIST}>
                    <div className="mt-3 shadow-3 border-round overflow-hidden">
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.state.roles}
                            dataKey="_id"
                            paginator
                            lazy
                            onPage={this.onPage}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            paginatorLeft
                            header={header}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            responsiveLayout="scroll"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            globalFilter={this.state.globalFilter}
                        >
                            <Column field="roleName" header="Role" />
                            <Column
                                body={(rowData) => {
                                    return (
                                        <div className="">
                                            <Authorize
                                                permId={
                                                    PERMISSIONS.ROLE_EDIT
                                                }
                                            >
                                                <InputSwitch
                                                    onChange={(e) => {
                                                        this.changeStatus(
                                                            e,
                                                            rowData
                                                        );
                                                    }}
                                                    checked={
                                                        rowData.status ==
                                                        "Active"
                                                    }
                                                />
                                            </Authorize>
                                        </div>
                                    );
                                }}
                                field="status"
                                header="Status"
                            />
                            <Column
                                body={this.actionsTemplate}
                                header="Actions"
                            ></Column>
                        </DataTable>
                    </div>
                </Authorize>
                <Dialog
                    header={<>{this.state.warningDialog.headerMsg}</>}
                    draggable={false}
                    closeOnEscape={true}
                    blockScroll={true}
                    dismissableMask={false}
                    closable={false}
                    className="xl:w-3 md:w-3 lg:w-5 sm:w-9"
                    visible={this.state.warningDialog.visible}
                    footer={() => {
                        return (
                            <div className="m-3">
                                <Button
                                    label="No"
                                    className="p-button-outlined mr-3"
                                    onClick={() => {
                                        this.setState({
                                            warningDialog:
                                                warningDialogInit,
                                            editRoleData: null,
                                        });
                                    }}
                                />
                                <Button
                                    label="Yes"
                                    onClick={this.onConfirmChange}
                                />
                            </div>
                        );
                    }}
                    onHide={() =>
                        this.setState({
                            warningDialog: warningDialogInit,
                            editRoleData: null,
                        })
                    }
                >
                    <div>
                        <span className="warning-icon"></span>
                    </div>
                    {this.state.warningDialog.message}
                </Dialog>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.state.isShowCreate && (
                    <CreateRole
                        onRoleCreate={this.onRoleCreate}
                        isAdd={this.state.isAdd}
                        hideRoleCreateDialog={this.hideRoleCreateDialog}
                        editRoleData={this.state.editRoleData}
                    />
                )}
                {(this.state.isLoading || this.props.isLoading) && <LoadingComponent />}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
