import React, { useRef } from "react";
import { Button } from "primereact/button";
import moment from "moment";

const App = ({ policyDetails, selectedCustomer }) => {
  const selectedMember = policyDetails?.payment?.monthHistoryToBePaid.find(a => a.uuId == selectedCustomer);
  const printRef = useRef();

  const handlePrint = () => {
    const content = printRef.current;
    const printWindow = window.open("", "_blank", "width=800,height=600");
    printWindow.document.open();
    printWindow.document.write(`
         <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              @page {
                size: A4;
                margin: 20mm; /* Adjust the margin as per your needs */
              }
              body {
                margin: 0;
                font-family: Arial, sans-serif;
              }
              .print-container {
                width: 100%;
                height: auto;
                margin: 0 auto;
                padding: 10mm;
                box-sizing: border-box;
                background-color: #fff;
              }
            }
          </style>
        </head>
        <body>
          <div class="print-container">
            ${content.innerHTML}
          </div>
        </body>
      </html>
    `);
    // printWindow.document.close();
    // printWindow.close();
    printWindow.print();
    // 
  };

  return (
    <div>
      <div ref={printRef}>
        <div className="print-page-hidden">
          <div style={{ marginTop: '20px' }}>
            <div> Date / తేదీ: {moment().format('LL')}</div>
            <div style={{ marginTop: '20px' }}>
              I, {selectedMember?.name} s/o {selectedMember?.fatherName} hereby confirm that I have received the full amount of Rs. {policyDetails.payment.basicDetails.liftAmount}/- for this month through Chit Number {policyDetails.chitsPolicy.policyNumber}.
            </div>
            <div style={{ marginTop: '20px' }}>
              I agree to make the remaining {policyDetails?.chitsPolicy?.policy?.totalMonths - policyDetails?.payment?.basicDetails?.month} payments in a timely manner as per the schedule. I have understood the terms and conditions of the chit fund and agree to abide by them.
            </div>
          </div>
          <div style={{ marginTop: '40px' }}>
            <div style={{ marginTop: '20px' }}>
              నేను, {selectedMember?.name} s/o {selectedMember?.fatherName}, ఈ నెలకు సంబంధించిన మొత్తం రూ. {policyDetails.payment.basicDetails.liftAmount}/- ను చిట్టి నంబర్ {policyDetails.chitsPolicy.policyNumber} ద్వారా పూర్తిగా స్వీకరించినట్లు ధృవీకరిస్తున్నాను.
            </div>
            <div style={{ marginTop: '20px' }}>
              మిగిలిన {policyDetails?.chitsPolicy?.policy?.totalMonths - policyDetails?.payment?.basicDetails?.month} చెల్లింపులను షెడ్యూల్ ప్రకారం క్రమానుగతంగా చెల్లించేందుకు నేను అంగీకరిస్తున్నాను. చిట్టి నిబంధనలు మరియు షరతులను నేను అర్థం చేసుకున్నాను మరియు వాటిని పాటించేందుకు సిద్ధంగా ఉన్నాను.
            </div>
            <div style={{ marginTop: '20px' }}>
              Signature / సంతకం
            </div>
            <div style={{ marginTop: '20px' }}>
              _______________________________
            </div>
          </div>

        </div>
      </div>
      <Button onClick={handlePrint} label="Print Agreement" icon="pi pi-copy" className="p-ripple p-button-outlined"></Button>
    </div>
  );
};

export default App;
