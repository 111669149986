import React, { Component } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import { Chips } from "primereact/chips";
import {
    getFormFields,
    isFormValid,
    onTextChange,
    onNumberChange,
    onEmailChange,
    onChoiceChange,
    onChipsChange,
} from "../../../Utils/formHelper";
import { BreadCrumbHome, logOut, trimObj } from "../../../Utils";
import ConsultantFields from "./addConsultant.json";
import BaseComponent from "../../common/BaseComponent";

import { addConsultants } from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { Toolbar } from "primereact/toolbar";
class AddConsultant extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );

        this.state = {
            consultant: this.formFields.data,

            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Consultants", url: "/#/admin/consultants" },
        { label: `${this.props.params.id ? "Edit" : 'Add'} Consultant`, url: `/#/admin/cosultant/${this.props.params.id ? 'edit/' + this.props.params.id : 'add'}` }
    ];

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/consultants");
        }
    }

    addOrUpdate = async () => {
        let consultant = trimObj(this.state.consultant);

        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            await this.props.addConsultants(consultant);
        }
    };

    getConsultantById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            consultant: res.res.data,
                            isLoading: false,
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getConsultantById(this.props?.params.id);
            }
        }
    }
    render() {
        const items = [
            { label: "Admin", url: "/#/admin" },
            { label: "Consultants" },
            {
                label:
                    this.props?.params?.operation == "edit"
                        ? "Edit Consultant"
                        : "Add Consultant",
            },
        ];

        const home = {
            icon: "pi pi-home",
            url: "/#/admin",
        };
        const { consultant, formValidations } = this.state;
        return (
            <div className="p-5">
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <div className="shadow-3 border-round overflow-hidden p-5 mt-5">
                    <div className="text-900 text-3xl font-medium mb-3 ml-2">{this.props.params.id ? 'Edit' : 'Add'} Consultant</div>
                    <div className="p-grid row m-1">
                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Name<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "name",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.name}
                            />
                            {formValidations &&
                                !formValidations.fields["name"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["name"].errorMsg}
                                    </p>
                                )}
                        </div>

                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Phone<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onNumberChange(
                                        e.target.value,
                                        "mobile",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.mobile}
                            />
                            {formValidations &&
                                !formValidations.fields["mobile"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["mobile"].errorMsg}
                                    </p>
                                )}
                        </div>

                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Email<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onEmailChange(
                                        e.target.value,
                                        "email",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.email}
                            />

                            {formValidations &&
                                !formValidations.fields["email"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["email"].errorMsg}
                                    </p>
                                )}
                        </div>

                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Role Preferred<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "rolePrefered",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.rolePrefered}
                            />

                            {formValidations &&
                                !formValidations.fields["rolePrefered"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["rolePrefered"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Total Years Of Experience
                                <span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "yearsOfExpOnIntialCall",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.yearsOfExpOnIntialCall}
                            />
                            {formValidations &&
                                !formValidations.fields["yearsOfExpOnIntialCall"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "yearsOfExpOnIntialCall"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                    </div>
                    <div className="p-grid row m-1">
                        <div className="col-12 md:col-8 lg:col-8">
                            <label className="font-bold block mb-0">
                                Technologies Preferred
                                <span className="sp-required">*</span>
                            </label>
                            {Array.isArray(consultant.technologiesPrefered) && (
                                <Chips
                                    className="block"
                                    max={100}
                                    allowDuplicate={false}
                                    onChange={(e) => {
                                        onChipsChange(
                                            e.target.value,
                                            "technologiesPrefered",
                                            this,
                                            ConsultantFields,
                                            consultant,
                                            formValidations,
                                            "consultant",
                                            "formValidations"
                                        );
                                    }}
                                    value={consultant.technologiesPrefered}
                                />
                            )}

                            {formValidations &&
                                !formValidations.fields["technologiesPrefered"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "technologiesPrefered"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>
                    <Toolbar
                        className="sp-toolbar sp-mt20"
                        right={() => {
                            return (<>
                                <Button
                                    label="Cancel"
                                    className="p-button-text hover:bg-primary mr-3"
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                />
                                <Button
                                    label={
                                        this.props?.params?.operation == "edit"
                                            ? "Update"
                                            : "Add"
                                    }
                                    onClick={this.addOrUpdate}
                                    autoFocus
                                    className="p-button-primary "
                                />
                            </>)
                        }}
                    ></Toolbar>
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    consultants: state?.consultants?.consultants,
    totalRecords: state?.consultants?.totalRecords,
    lazyParams: state?.consultants?.lazyParams,
    isLoading: state?.consultants?.isLoading,
    consultantAddedOrUpdated: state?.consultants?.consultantAddedOrUpdated,
});

export default BaseComponent(AddConsultant, mapStateToProps, {
    addConsultants,
});
