import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import BaseComponent from "../../common/BaseComponent";
import {
    getConsultantLeaningPlans,
    resetLazyConsultantLeaningPlans,
} from "../../../store/actions";
import NavFooter from "../../navFooter";
import { DataView } from "primereact/dataview";
import "./styles.scss";
import { getFileType, ResourceTypes } from "../../../Utils";
import Authentication from "./../../../session/index";
import { Button } from "primereact/button";
import ResourceLearningWidget from "./resourceLearningWidget";
import { Card } from "primereact/card";
import axios from 'axios';

class ConsultantsLearningPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            resources: [],
            menuItems: [],
            selectedDay: null,
            selectedPlanIndex: null,
            selectedPlanDetails: null,
            selectedResource: null,
        };
        this.serverlessService = new ServerlessService();
        this.menuRef = React.createRef();
    }

    componentDidMount() {
        if (!this.props.consultants?.length) {
            this.props.getConsultantLeaningPlans();
        }
    }
    toggleMenu = () => {
        const { menuItems } = this.state;
        const submenuItems = menuItems.planDetails?.resources || [];
        const resources = submenuItems.map((submenu) => ({
            label: submenu.data.title,
            command: () => { },
        }));

        this.setState({ resources });
        this.menuRef.current.toggle(event);
    };
    handleToggle = (index) => {
        this.setState({ selectedDay: index, isShowConfirmationDialog: true });
    };

    showPlanDetails = (index) => {
        this.setState({ selectedPlanIndex: index });
    };
    goBack = () => {
        this.setState({ selectedPlanDetails: null });
    };

    goToPlansPage = () => {
        this.setState({ selectedResource: null });
    };

    hideLearningWidget = () => {
        this.setState({
            isShowLearningWidget: false,
            selectedResourceForLearning: null,
        });
    };

    onResourceClick = async (_resource) => {
        const { resourceType, resource, title } = _resource;
        switch (resourceType) {
            case ResourceTypes.Youtube:
                this.setState({
                    isShowLearningWidget: true,
                    selectedResourceForLearning: _resource,
                });
                return;
            case ResourceTypes.ExternalLink:
                window.open(resource);
                return;
            case ResourceTypes.Textbox:
                // 
                this.setState({
                    isShowLearningWidget: true,
                    selectedResourceForLearning: _resource,
                });
                return;
            case ResourceTypes.Upload:
                const filetype = getFileType(_resource?.resource?.type)

                if (filetype == 'image' || filetype == 'video') {
                    if (_resource?.resource?.resourceKey) {

                        this.setState({
                            isLoading: true
                        })
                        let url =
                            "https://btycikcg7g.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url";

                        let { data } = await axios.post(url, {
                            "type": "read",
                            "key": _resource?.resource.resourceKey
                        });
                        _resource.resource.resourceUpload = data.data
                        _resource.resource.filetype = filetype
                        this.setState({
                            isShowLearningWidget: true,
                            isLoading: false,
                            selectedResourceForLearning: _resource,
                        });
                    }

                } else {

                    this.setState({
                        isLoading: true
                    })

                    let url =
                        "https://btycikcg7g.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url";

                    let { data } = await axios.post(url, {
                        "type": "read",
                        "key": _resource?.resource.resourceKey
                    });
                    this.setState({
                        isLoading: false
                    })

                    window.open(data.data, '_blank');


                }

                this.setState({
                    isShowLearningWidget: true,
                    selectedResourceForLearning: _resource,
                });

            default:
                console.log('')
        }
    };

    resourceType(status) {
        switch (status) {
            case ResourceTypes.Youtube:
                return {
                    message: "Youtube",
                };
            case ResourceTypes.ExternalLink:
                return {
                    message: "External Link",
                };
            case ResourceTypes.Textbox:
                return {
                    message: "Text Box",
                };
            case ResourceTypes.Upload:
                return {
                    message: "Upload",
                };
            default:
                return { message: "Unknown", details: "Status is unknown." };
        }
    }
    renderResource = (resource) => {
        console.log(resource);
        return (
            <div>
                <div className="shadow-2 border-round surface-card mb-3 h-full  justify-content-between ">
                    <div className="p-4">
                        <div className="flex align-items-center">
                            <span className="text-900 font-medium text-2xl">
                                {resource.title} {" - "}
                                {resource.resourceType && (
                                    <span className="badge bg-primary ms-2">
                                        {
                                            this.resourceType(
                                                resource.resourceType
                                            ).message
                                        }
                                    </span>
                                )}
                            </span>
                        </div>
                    </div>
                    <div className="px-4 py-3 surface-100 text-right">
                        <Button
                            icon="pi pi-arrow-right"
                            iconPos="right"
                            label="View resource"
                            onClick={async () => {
                                await this.onResourceClick(resource);
                            }}
                            className="p-button-rounded p-button-success"
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderResouce = (resource) => {
        console.log(resource)
        return (
            <div>
                <div className="shadow-2 border-round surface-card mb-3 h-full  justify-content-between ">
                    <div className="p-4">
                        <div className="flex align-items-center">
                            <span className="text-900 font-medium text-2xl">
                                {resource?.title}
                            </span>
                        </div>
                        <p className="mt-0 mb-3 text-700 line-height-3">
                            {resource?.description}
                        </p>
                    </div>
                    <div className="px-4 py-3 surface-100 text-right">
                        <Button
                            icon="pi pi-arrow-right"
                            iconPos="right"
                            label="Go to resources"
                            onClick={() => {
                                this.setState({
                                    selectedResource: resource,
                                });
                            }}
                            className="p-button-rounded p-button-success"
                        />
                    </div>
                </div>
            </div>
        );
    };

    commentTemplate = (data) => {
        console.log(data)
        this.setState({ selectedDay: data.day });
        return (
            <div className="surface-card p-4 shadow-2 border-round my-4">
                <div className="text-500 mb-5">Day {data.day}</div>
                {data?.resources?.map(this.renderResouce)}
            </div>
        );
    };

    render() {
        const { selectedResource, selectedPlanDetails, selectedDay } =
            this.state;

        return (
            <>
                <NavFooter>
                    <div className="bg-white">
                        <div className="mx-8 py-4">
                            {!selectedPlanDetails && (
                                <div className="">
                                    <div>
                                        <div className="font-medium text-3xl text-900 mb-3">
                                            Courses
                                        </div>

                                        {this.props.consultantLearningPlans
                                            .length ? (
                                            this.props.consultantLearningPlans.map(
                                                (plan, index) => (
                                                    <div className="surface-card p-4 m-4 shadow-2 border-round">
                                                        <ul className="list-none p-0 m-0">
                                                            <li className="flex align-items-center py-3 px-2 surface-border flex-wrap">
                                                                <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">
                                                                    {
                                                                        plan.planName
                                                                    }
                                                                </div>

                                                                <div className="w-6 md:w-2 flex justify-content-end">
                                                                    <Button
                                                                        label="Go To Course"
                                                                        className="p-button-outlined p-button-rounded"
                                                                        onClick={() =>
                                                                            this.setState(
                                                                                {
                                                                                    selectedPlanDetails:
                                                                                        plan,
                                                                                }
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            )
                                        ) : (
                                            <p>No Courses assigned to you for study. Please contact admin to assign.</p>
                                        )}
                                    </div>
                                </div>
                            )}
                            {selectedPlanDetails && !selectedResource && (
                                <div className="plan-details-container">
                                    <Button
                                        onClick={() => this.goBack()}
                                        icon="pi pi-arrow-left"
                                        className="p-button-rounded p-button-outlined"
                                        aria-label="Back"
                                    />

                                    <h4 className="my-4">
                                        {" "}
                                        {
                                            this.state.selectedPlanDetails
                                                ?.planName
                                        }
                                    </h4>
                                    <DataView
                                        value={
                                            this.state.selectedPlanDetails
                                                ?.planDetails
                                        }
                                        layout={"list"}
                                        itemTemplate={this.commentTemplate}
                                        paginator
                                        gutter={false}
                                        rows={5}
                                        totalRecords={
                                            this.state.selectedPlanDetails
                                                ?.planDetails?.length
                                        }
                                        className="learning-plan"
                                    />
                                </div>
                            )}

                            {selectedResource && (
                                <>
                                    <div className="plan-details-container">
                                        <Button
                                            onClick={() => this.goToPlansPage()}
                                            icon="pi pi-arrow-left"
                                            className="p-button-rounded p-button-outlined my-4"
                                            aria-label="Back"
                                        />
                                        <Card>
                                            <DataView
                                                header={`${selectedResource?.title} Day - ${selectedDay}`}
                                                value={
                                                    selectedResource?.resources
                                                }
                                                layout={"list"}
                                                itemTemplate={
                                                    this.renderResource
                                                }
                                                paginator
                                                gutter={false}
                                                rows={5}
                                                totalRecords={
                                                    selectedResource?.resources
                                                        ?.length
                                                }
                                                className="learning-plan"
                                            />
                                        </Card>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </NavFooter>
                {this.state.isShowLearningWidget && (
                    <ResourceLearningWidget
                        hideLearningWidget={this.hideLearningWidget}
                        resource={this.state.selectedResourceForLearning}
                    />
                )}

                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    consultantLearningPlans:
        state?.consultantLearningPlans?.consultantLearningPlans,
    totalRecords: state?.consultantLearningPlans?.totalRecords,
    lazyParams: state?.consultantLearningPlans?.lazyParams,
    isLoading: state?.consultantLearningPlans?.isLoading,
});

export default BaseComponent(
    Authentication(ConsultantsLearningPlan),
    mapStateToProps,
    {
        getConsultantLeaningPlans,
        resetLazyConsultantLeaningPlans,
    }
);
