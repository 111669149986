import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { v4 as uuidv4 } from "uuid";

import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import { BreadCrumbHome, logOut, trimObj } from "../../../Utils";
import ConsultantFields from "./addConsultant.json";
import BaseComponent from "../../common/BaseComponent";
import AddResourceWidget from "./addResourceWidget";
import "./styles.scss";
import { addResource } from "../../../store/actions";
import { InputTextarea } from "primereact/inputtextarea";
import { BreadCrumb } from "primereact/breadcrumb";
import { Toolbar } from "primereact/toolbar";

class AddResource extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: {
                ...this.formFields.data,
                resources: [],
            },
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Materials" },
        { label: "Learning Resources", url: '#/admin/learning-resources' },
        { label: `${this.props.params.id ? "Edit" : 'Add'} Resource`, url: `/#/admin/learning-r/${this.props.params.id ? 'edit/' + this.props.params.id : 'add'}` }
    ];



    isAdditionalValid = () => {
        let consultant = JSON.parse(JSON.stringify(this.state.consultant));
        let { formValidations } = this.state;
        let { resources } = consultant;

        let isValid = true;

        if (!(resources && resources.length)) {
            formValidations.fields["resources"] = {
                ...formValidations.fields["resources"],
                isValid: false,
                errorMsg: `Please assign resource`,
            };
            isValid = false;
        } else if (!resources.every((i) => i.assigned)) {
            isValid = false;
            formValidations.fields["resources"] = {
                ...formValidations.fields["resources"],
                isValid: false,
                errorMsg: `Please assign resource`,
            };
        } else {
            formValidations.fields["resources"] = {
                ...formValidations.fields["resources"],
                isValid: true,
                errorMsg: ``,
            };
        }

        this.setState((prevState) => {
            return {
                formValidations,
            };
        });

        return isValid;
    };

    addOrUpdate = async () => {
        // 
        let consultant = trimObj(this.state.consultant);
        console.log(consultant)
        let isValid = true;
        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );
        console.log(formStatus)

        let isCompleteFormValid = true;

        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }
        if (isValid && isCompleteFormValid) {
            consultant.resources = consultant.resources.map((ia) => ia.data);
            await this.props.addResource(consultant);
        }
    };

    onAssignClick = (curriculum) => {
        let resources = this.state.consultant.resources.map((item) => {
            if (item.id === curriculum.id) {
                return curriculum;
            } else {
                return item;
            }
        });

        this.setState(
            (prevState) => {
                return {
                    consultant: {
                        ...prevState.consultant,
                        resources: resources,
                    },
                };
            },
            () => {
                if (curriculum.assigned) {
                    this.isAdditionalValid();
                }
            }
        );
    };

    addMore = () => {
        // 
        if (this.state.consultant.resources.every((s) => s.assigned === true)) {
            this.setState((prevState) => {
                return {
                    consultant: {
                        ...prevState.consultant,
                        resources: [
                            ...prevState.consultant.resources,
                            { id: uuidv4(), assigned: false },
                        ],
                    },
                };
            });
        } else {
            this.toast.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please save resource to add more",
                life: 3000,
            });
        }
    };

    removeTempSubject = (id) => {
        if (this.state.consultant.resources.length) {
            let resources = this.state.consultant.resources.filter(
                (item) => item.id != id
            );
            this.setState((prevState) => {
                return {
                    consultant: {
                        ...prevState.consultant,
                        resources,
                    },
                };
            });
        }
    };

    getResorcesById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/resources/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.data) {
                    let consultant = res.res.data;
                    consultant.resources = consultant.resources.map((a) => {
                        return {
                            id: uuidv4(),
                            assigned: true,
                            data: a,
                        };
                    });

                    this.setState((prevState) => {
                        return {
                            consultant,
                            isLoading: false,
                        };
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getResorcesById(this.props?.params.id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/learning-resources");
        }
    }
    render() {
        const { consultant, formValidations } = this.state;
        return (
            <div className="p-5">
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <div className="shadow-3 border-round overflow-hidden p-5 mt-5">
                    <div className="text-900 text-3xl font-medium mb-3 ml-2">{this.props.params.id ? 'Edit' : 'Add'} Resource</div>
                    <div className="p-grid row m-1">
                        <div className="col-12 ">
                            <label className="font-bold block mb-0">
                                Name<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "title",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                value={consultant.title}
                            />
                            {formValidations &&
                                !formValidations.fields["title"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["title"].errorMsg}
                                    </p>
                                )}
                        </div>
                        <div className="col-12">
                            <label className="font-bold block mb-0">
                                Description<span className="sp-required">*</span>
                            </label>
                            <InputTextarea
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "description",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }}
                                rows={5}
                                value={consultant.description}
                            />
                            {formValidations &&
                                !formValidations.fields["description"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["description"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>
                    <div className="p-grid row m-1">
                        <div className="col-12">
                            <label className="font-bold block mb-0">
                                Resources<span className="sp-required">*</span>
                            </label>
                            <div className="border-1 surface-border pt-2 p-2 pb-2 border-round" >
                                {this.state.consultant &&
                                    this.state.consultant.resources &&
                                    this.state.consultant.resources.length ?
                                    <div  >
                                        {this.state.consultant.resources.map((temp) => {
                                            return (
                                                <div key={temp.id}>
                                                    <AddResourceWidget
                                                        data={temp.data}
                                                        resources={
                                                            this.state.consultant
                                                                .resources
                                                        }
                                                        key={temp.id}
                                                        id={temp.id}
                                                        onAssignClick={this.onAssignClick}
                                                        removeTempSubject={
                                                            this.removeTempSubject
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div> : null}
                                <Button
                                    icon="pi pi-plus-circle"
                                    className="p-button-rounded addmore m-2"
                                    onClick={this.addMore}
                                />
                                {formValidations &&
                                    !formValidations.fields["resources"].isValid && (
                                        <p className="p-error">
                                            {
                                                formValidations.fields["resources"]
                                                    .errorMsg
                                            }
                                        </p>
                                    )}
                            </div>
                        </div>
                    </div>
                    <Toolbar
                        className="sp-toolbar sp-mt20"
                        right={() => {
                            return (<>
                                <Button
                                    label="Cancel"
                                    className="p-button-text hover:bg-primary mr-3"
                                    onClick={() => {
                                        this.props.history.push("/admin/learning-resources");
                                    }}
                                />
                                <Button
                                    label={
                                        this.props?.params?.operation == "edit"
                                            ? "Update"
                                            : "Add"
                                    }
                                    onClick={this.addOrUpdate}
                                    autoFocus
                                    className="p-button-primary "
                                />
                            </>)
                        }}
                    ></Toolbar>
                </div>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

    lazyParams: state?.learningResources?.lazyParams,
    isLoading: state?.learningResources?.isLoading,
    consultantAddedOrUpdated:
        state?.learningResources?.consultantAddedOrUpdated,
});

export default BaseComponent(AddResource, mapStateToProps, {
    addResource,

});
