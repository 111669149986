import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import BaseComponent from "../../common/BaseComponent";
import { getQuestionBank, resetLazyQuestionBank } from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { BreadCrumbHome, logOut } from "../../../Utils";
import { ResponsiveText } from "../../common/ResponsiveText";

class QuestionBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editQuestion: null,
            isShowConfirmationDialog: false,
            isShowApproveDialog: false,
            isRejected: false,
            showTextarea: false,
            rejectionReason: "",
            globalSearch: "",
            selectedId: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            isShowApproveDialog: false,
            showRejectReasonInput: false,
            rejectionReason: "",
            selectedStatus: "",
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Question Bank", url: '/#/admin/question-bank' },
        { label: "Questions", url: '/#/admin/question-bank' },
    ];

    deleteQuestion = (_id) => {
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId || _id;

        const url = `/consultants/questionbank/delete`;
        this.serverlessService
            .post(url, { id: _id }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isShowConfirmationDialog: false,
                            isShowApproveDialog: false,
                        },
                        () => {
                            this.props.getQuestionBank({
                                status: this.props.selectedStatus,
                            });
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                        isShowConfirmationDialog: false,
                        isShowApproveDialog: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                    isShowConfirmationDialog: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getQuestions = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isShowConfirmationDialog: false,
            isLoading: true,
            question: [],
        });

        const url = `/consultants/questionbank?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        question: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.questions?.length) {
            this.props.getQuestionBank({ status: this.props.selectedStatus });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/question-bank");
        }
    }
    onGlobalSearch = (e) => {
        this.props.getQuestionBank({
            globalSearch: e.target.value,
            status: this.props.selectedStatus,
        });
    };

    onPage = (event) => {
        this.props.getQuestionBank({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onSort = (event) => {
        this.props.getQuestionBank({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getQuestionBank({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onHide = () => {
        this.setState({
            isShowConfirmationDialog: false,
            editQuestion: null,
            visible: false,
        });
    };

    onQuestionAddOrUpdate = () => {
        this.setState(
            {
                visible: false,
                editQuestion: null,
            },
            this.props.getQuestions
        );
    };

    actionsBody = (rowData) => {
        return (
            <div >
                <Authorize permId={PERMISSIONS.QUESTION_BANK_EDIT}>
                    <Button
                        onClick={() => {
                            this.props.history.push(
                                `/admin/question-banks/edit/${rowData._id}`
                            );
                        }}
                        tooltip="Edit Question"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-pencil"
                        className="p-button-rounded mr-3" />
                </Authorize>

                <Authorize permId={PERMISSIONS.QUESTION_BANK_DELETE}>
                    <Button
                        onClick={() =>
                            this.showDeleteQuestionDialog(rowData._id)
                        }
                        severity="danger"
                        tooltip="Delete Question"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-trash"
                        className="p-button-rounded mr-3" />
                </Authorize>
                {rowData.hasOwnProperty("isAccept") ? (
                    <>
                        {rowData.isAccept == false && (
                            <Authorize
                                permId={PERMISSIONS.QUESTION_BANK_APPROVE}
                            >
                                <Button
                                    onClick={() =>
                                        this.showApproveDialog(rowData)
                                    }
                                    severity="success"
                                    tooltip="Accept"
                                    tooltipOptions={{
                                        position: 'top'
                                    }}
                                    icon="pi pi-check"
                                    className="p-button-rounded mr-3" />
                            </Authorize>
                        )}

                        {rowData.isAccept == true && (
                            <Authorize
                                permId={PERMISSIONS.QUESTION_BANK_REJECT}
                            >
                                <Button
                                    onClick={() =>
                                        this.showRejectReasonInput(rowData)
                                    }
                                    severity="secondary"
                                    tooltip="Reject"
                                    tooltipOptions={{
                                        position: 'top'
                                    }}
                                    icon="pi pi-times"
                                    className="p-button-rounded mr-3" />
                            </Authorize>
                        )}
                    </>
                ) : (
                    <>
                        <Authorize permId={PERMISSIONS.QUESTION_BANK_APPROVE}>
                            <Button
                                onClick={() =>
                                    this.showApproveDialog(rowData)
                                }
                                severity="success"
                                tooltip="Accept"
                                tooltipOptions={{
                                    position: 'top'
                                }}
                                icon="pi pi-check"
                                className="p-button-rounded mr-3" />
                        </Authorize>

                        <Authorize permId={PERMISSIONS.QUESTION_BANK_REJECT}>
                            <Button
                                onClick={() =>
                                    this.showRejectReasonInput(rowData)
                                }
                                severity="secondary"
                                tooltip="Reject"
                                tooltipOptions={{
                                    position: 'top'
                                }}
                                icon="pi pi-times"
                                className="p-button-rounded mr-3" />
                        </Authorize>
                    </>
                )}
            </div>
        );
    };
    submitRejection = () => {
        if (!this.state.rejectionReason) {
            this.setState({ showRejectionReasonAlert: true });
            return;
        }

        this.setState({
            isLoading: true
        })

        const url = `/consultants/questionbank/update`;
        const requestBody = {
            reason: this.state.rejectionReason,
            _id: this.state.selectedId,
            isAccept: false,
        };

        this.serverlessService
            .put(url, requestBody, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            showRejectReasonInput: false,
                            isLoading: false
                        },
                        () => {
                            this.toast.show({
                                severity: "success",
                                summary: "Question Rejected",
                                detail: "The question has been successfully rejected.",
                                life: 3000,
                            });

                            this.props.getQuestionBank({
                                status: this.props.selectedStatus,
                            });
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    ApproveQuestion = (data) => {
        const url = `/consultants/approvedquestion/add`;
        const requestBody = {
            questionRef: this.state.approvedData._id,
            ...this.state.approvedData,
            isAccept: true,
        };

        this.setState({
            isLoading: true
        });

        this.serverlessService
            .post(url, requestBody, true)
            .then((res) => {
                if (res && res.status) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.getQuestionBank({
                            status: this.props.selectedStatus,
                        });
                    });

                    if (
                        res.message &&
                        res.message.includes("Duplicate _id detected.")
                    ) {
                        this.toast.show({
                            severity: "warn",
                            summary: "Question already accepted",
                            detail: "This question has already been accepted.",
                            life: 3000,
                        });
                    } else {
                        this.toast.show({
                            severity: "success",
                            summary: "Question accepted",
                            detail: "The question has been successfully accepted.",
                            life: 3000,
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    if (
                        res &&
                        res.message &&
                        !res.message.includes("duplicate key error")
                    ) {
                        this.toast.show({
                            severity: "warn",
                            summary: "Question Already accepted",
                            detail: "This question has already been accepted.",
                            life: 3000,
                        });
                    }
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    showDeleteQuestionDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
            rejectionReason: "",
        });
    };

    showApproveDialog = (questionData) => {
        this.setState({
            isShowApproveDialog: true,
            selectedId: questionData._id,
            approvedData: questionData,
            isAccept: questionData.isAccept,
        });
    };

    showRejectReasonInput = (questionData) => {
        this.setState({
            rejectionReason: "",
            showRejectReasonInput: true,
            selectedId: questionData._id,
            approvedData: questionData,
            isAccept: questionData.isAccept,
            showRejectionReasonAlert: false,
        });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3"
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.deleteQuestion}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );
    ApprovalContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.ApproveQuestion}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };
    acceptQuestion = () => {
        this.ApproveQuestion();
        this.setState({ isShowApproveDialog: false });
    };

    renderStatus = (rowData) => {
        return (
            <div className="p-d-flex p-ai-center">
                {rowData.hasOwnProperty("isAccept") ? (
                    rowData.isAccept ? (<Button label="Accepted" severity="success" raised />
                    ) : (<Button label="Rejected" severity="danger" raised />
                    )
                ) : (
                    <Button label="Pending" severity="warning" raised />
                )}
            </div>
        );
    };

    rejectQuestion = () => {
        this.setState({ showTextarea: true, isRejected: true });
    };

    onFilterChange = (e) => {
        this.props.getQuestionBank({ status: e.value });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Questions</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.QUESTION_BANK_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.QUESTION_BANK_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.QUESTION_BANK_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );
        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Approved", value: "approved" },
            { label: "Rejected", value: "rejected" },
            { label: "All", value: "" },
        ];


        return (
            <>
                <div className='p-5'>
                    <BreadCrumb model={this.items} home={BreadCrumbHome} />
                    <Toolbar
                        className="p-0 bg-primary-reverse border-white"
                        right={() => {
                            return (
                                <Authorize
                                    permId={
                                        PERMISSIONS.USER_QUESTION_BANK_FILTER
                                    }
                                >
                                    <Dropdown
                                        className="w-11rem mt-3"
                                        value={
                                            this.props.selectedStatus
                                        }
                                        options={statusOptions}
                                        onChange={this.onFilterChange}
                                        placeholder="Select a status"
                                    />

                                </Authorize>
                            );
                        }}

                    ></Toolbar>
                    <Authorize permId={PERMISSIONS.QUESTION_BANK_LIST}>
                        <div className="mt-3 shadow-3 border-round overflow-hidden">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                header={header}
                                value={this.props.questions}
                                lazy
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                            >
                                <Column
                                    field="question"
                                    header="Question"
                                    body={({ question }) =>
                                        <ResponsiveText text={question} />
                                    }
                                ></Column>
                                <Column
                                    field="answer"
                                    header="Answer"
                                    body={({ answer }) =>
                                        <ResponsiveText text={answer} />
                                    }
                                ></Column>
                                <Column field="createdBy.name" header="Created By"></Column>
                                <Column
                                    field="status"
                                    header="Status"
                                    body={(rowData) =>
                                        this.renderStatus(rowData)
                                    }
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </div>
                    </Authorize>

                </div>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && <LoadingComponent />}

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        className="xl:w-3 md:w-3 lg:w-5 sm:w-9"
                        onHide={this.onCloseConfirmation}
                        draggable={false}
                        footer={this.footerContent}
                    >
                        <p>Deleted Question can't be restore.</p>
                    </Dialog>
                )}

                {this.state.isShowApproveDialog && (
                    <Dialog
                        header="Confirm Acceptance"
                        visible={this.state.isShowApproveDialog}
                        draggable={false}
                        style={{ width: "25vw" }}
                        modal
                        onHide={() =>
                            this.setState({ isShowApproveDialog: false })
                        }
                        footer={
                            <Button
                                label="Accept"
                                className=" p-button-primary "
                                onClick={this.acceptQuestion}
                            />
                        }
                    >
                        <p>Are you sure you want to accept this question?</p>
                    </Dialog>
                )}

                {this.state.showRejectReasonInput && (
                    <Dialog
                        header="Rejection Reason"
                        visible={this.state.showRejectReasonInput}
                        draggable={false}
                        className="xl:w-3 md:w-3 lg:w-5 sm:w-9"
                        // style={{ width: "50vw", height: "15vw" }}
                        modal
                        onHide={() =>
                            this.setState({ showRejectReasonInput: false })
                        }
                        footer={
                            <Button
                                label="Submit"
                                onClick={this.submitRejection}
                                className=" p-button-primary "
                            />
                        }
                    >
                        <div>
                            <p>Provide a reason for rejection</p>
                            <InputTextarea
                                value={this.state.rejectionReason}
                                onChange={(e) => {
                                    if (e.target.value.length > 200) {
                                        this.toast.show({
                                            severity: "warn",
                                            summary: "Length Restriction",
                                            detail: 'More than 200 characters are not allowed.',
                                            life: 3000,
                                        });
                                    } else {
                                        this.setState({
                                            rejectionReason: e.target.value,
                                        })
                                    }
                                }}
                                rows={5}
                            />
                            {this.state.showRejectionReasonAlert && (
                                <div style={{ color: "red" }}>
                                    Rejection reason is required.
                                </div>
                            )}

                        </div>
                    </Dialog>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    questions: state?.questionBanks?.questions,
    totalRecords: state?.questionBanks?.totalRecords,
    lazyParams: state?.questionBanks?.lazyParams,
    isLoading: state?.questionBanks?.isLoading,
    selectedStatus: state?.questionBanks?.selectedStatus,
    consultantAddedOrUpdated: state?.questionBanks?.consultantAddedOrUpdated,
});

export default BaseComponent(QuestionBank, mapStateToProps, {
    getQuestionBank,
    resetLazyQuestionBank,
});
