import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import AddQuestion from "./addQuestion";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import BaseComponent from "../../common/BaseComponent";
import { getFaq, resetLazyFaq } from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { BreadCrumbHome, logOut } from "../../../Utils";
import { ResponsiveText } from "../../common/ResponsiveText";


class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editQuestion: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    items = [{ label: "Admin", url: "/#/admin" }, { label: "FAQ", url: "/#/admin/faq" }];

    deleteQuestion = (_id) => {
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId || _id;

        const url = `/consultants/faq/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isShowConfirmationDialog: false,
                        },
                        () => {
                            this.props.getFaq();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                        isShowConfirmationDialog: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                    isShowConfirmationDialog: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.faqs?.length) {
            this.props.getFaq();
        }
    }
    onGlobalSearch = (e) => {
        this.props.getFaq({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getFaq({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getFaq({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getFaq({ lazyParams: event });
    };

    onHide = () => {
        this.setState({
            isShowConfirmationDialog: false,
            editQuestion: null,
            visible: false,
        });
    };

    actionsBody = (rowData) => {
        return (
            <>
                <Authorize permId={PERMISSIONS.FAQ_EDIT}>
                    <Button
                        onClick={() => {
                            this.props.history.push(
                                `/admin/faq/edit/${rowData._id}`
                            );
                        }}
                        tooltip="Edit Question"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-pencil"
                        className="p-button-rounded mr-3" />
                </Authorize>
                <Authorize permId={PERMISSIONS.FAQ_DELETE}>
                    <Button
                        onClick={() =>
                            this.showDeleteQuestionDialog(rowData._id)
                        }
                        severity="danger"
                        tooltip="Delete Question"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-trash"
                        className="p-button-rounded mr-3" />
                </Authorize>
            </>
        );
    };

    showDeleteQuestionDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    footerContent = () => (
        <>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.deleteQuestion}
                autoFocus
                className=" p-button-primary "
            />
        </>
    );

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };

    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Questions</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize permId={PERMISSIONS.FAQ_SEARCH}>
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize permId={PERMISSIONS.FAQ_SEARCH}>
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={PERMISSIONS.FAQ_DOWNLOAD}
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );

        console.log(this.props, 'faq-state')


        return (
            <div className='p-5'>
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <Authorize permId={PERMISSIONS.FAQ_ADD}>
                    <Button
                        label="New"
                        icon="pi pi-plus"
                        className="mt-3 ml-4"
                        outlined
                        onClick={() =>
                            this.props.history.push(`/admin/faq/add`)
                        }
                    />
                </Authorize>
                <Authorize permId={PERMISSIONS.FAQ_LIST}>
                    <div className="mt-3 shadow-3 border-round overflow-hidden">
                        <DataTable
                            loading={this.state.isLoading || this.props.isLoading}
                            ref={(el) => (this.dt = el)}
                            header={header}
                            value={this.props.faqs}
                            lazy
                            scrollHeight="calc(100vh - 370px)"
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.props.lazyParams.first}
                            last={this.props.totalRecords}
                            rows={this.props.lazyParams.rows}
                            totalRecords={this.props.totalRecords}
                            paginator
                            paginatorLeft
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[
                                5, 10, 25, 50, 100
                            ]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            dataKey="_id"
                        >
                            <Column
                                header="Question"
                                body={({ question }) => {
                                    return <ResponsiveText text={question} />
                                }}
                            ></Column>
                            <Column
                                header="Answer"
                                body={({ answer }) => {
                                    return <ResponsiveText text={answer} />
                                }} ></Column>
                            <Column
                                field="updatedBy.name"
                                header="Updated By"
                            ></Column>
                            <Column
                                header="Actions"
                                body={(rowData) =>
                                    this.actionsBody(rowData)
                                }
                            ></Column>
                        </DataTable>
                    </div>
                </Authorize>

                {this.state.visible && (
                    <AddQuestion
                        onHide={this.onHide}
                        editQuestion={this.state.editQuestion}
                        getQuestions={this.props.getQuestions}
                    ></AddQuestion>
                )}
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        draggable={false}
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        className="xl:w-3 md:w-3 lg:w-5 sm:w-9"
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent}
                    >
                        <p>Deleted Question can't be restore.</p>
                    </Dialog>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    faqs: state?.faq?.faqs,
    totalRecords: state?.faq?.totalRecords,
    lazyParams: state?.faq?.lazyParams,
    isLoading: state?.faq?.isLoading,
});

export default BaseComponent(Faq, mapStateToProps, {
    getFaq,
    resetLazyFaq,
});
