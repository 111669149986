import { Button } from "primereact/button";
import { Chips } from 'primereact/chips';
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { BreadCrumbHome, logOut, trimObj } from "../../../Utils";

import {
    getFormFields,
    isFormValid,

    onTextChange,
} from "../../../Utils/formHelper";
import QuestionFields from "./question.json";
import BaseComponent from "../../common/BaseComponent";
import { addUserQuestionData } from "../../../store/actions";
import Required from "../../common/required";
import { Toolbar } from "primereact/toolbar";
import { BreadCrumb } from "primereact/breadcrumb";


class AddUserQuestion extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            QuestionFields,
            this.props.editQuestion || {}
        );
        this.state = {
            question: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAssign: false,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Question Bank" },
        { label: "User Questions", url: "/#/admin/user-question-bank" },
        { label: `${this.props.params.id ? "Edit" : 'Add'} Question`, url: `/#/admin/user-questionbank/${this.props.params.id ? 'edit/' + this.props.params.id : 'add'}` }
    ];

    addOrEdit = async () => {
        let question = trimObj(this.state.question);

        const formStatus = isFormValid(
            QuestionFields,
            this.formFields.formValidations,
            question
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            await this.props.addUserQuestionData(question)

        }
    };



    footerContent = () => (
        <div>
            <Button
                label={this.props.editQuestion ? "Update" : "Add"}
                onClick={this.addOrEdit}
                autoFocus
                className="p-button-primary "
            />
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onHide}
            />


        </div>
    );


    getUserQuestionById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/questionbank/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            question: res.res.data,
                            isLoading: false,
                        },
                        () => {


                        },

                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });


    }

    componentDidUpdate(prevProps) {
        if (prevProps.consultantAddedOrUpdated != this.props.consultantAddedOrUpdated) {
            this.props.history.push("/admin/user-question-bank");
        }
    }
    componentDidMount() {

        if (this.props?.params?.operation == 'edit') {
            if (this.props?.params?.id) {
                this.getUserQuestionById(this.props?.params.id);
            }

        }
    }

    render() {
        const { question, formValidations } = this.state;

        return (
            <div className="p-5">
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <div className="shadow-3 border-round overflow-hidden p-5 mt-5">
                    <div className="text-900 text-3xl font-medium mb-3 ml-2">{this.props.params.id ? 'Edit' : 'Add'} Question</div>
                    <div className="p-grid row m-1">
                        <div className="col-12">
                            <label className="font-bold block mb-2">Question</label>
                            <InputTextarea
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "question",
                                        this,
                                        QuestionFields,
                                        question,
                                        formValidations,
                                        "question",
                                        "formValidations"
                                    );
                                }}
                                value={question.question}
                            />
                            {formValidations &&
                                !formValidations.fields["question"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["question"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12">
                            <label className="font-bold block mb-2">
                                Answer
                                <Required />
                            </label>
                            <InputTextarea
                                rows={10}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "answer",
                                        this,
                                        QuestionFields,
                                        question,
                                        formValidations,
                                        "question",
                                        "formValidations"
                                    );
                                }}
                                value={this.state.question.answer}
                            />
                            {formValidations &&
                                !formValidations.fields["answer"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["answer"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-12">
                            <label className="font-bold block mb-2">
                                Tags
                            </label>
                            <Chips
                                className="chips-100"
                                value={this.state.question.tags}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "tags",
                                        this,
                                        QuestionFields,
                                        question,
                                        formValidations,
                                        "question",
                                        "formValidations"
                                    );
                                }}

                                separator="," />

                            {formValidations &&
                                !formValidations.fields["tags"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["tags"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                    </div>
                    <Toolbar
                        className="sp-toolbar sp-mt20"
                        right={() => {
                            return (<>
                                <Button
                                    label="Cancel"
                                    className="p-button-text hover:bg-primary mr-3"
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                />
                                <Button
                                    label={
                                        this.props?.params?.operation == "edit"
                                            ? "Update"
                                            : "Add"
                                    }
                                    onClick={this.addOrEdit}
                                    autoFocus
                                    className="p-button-primary "
                                />
                            </>)
                        }}
                    ></Toolbar>




                </div>



                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    userQuestions: state?.userQuestions?.userQuestions,
    totalRecords: state?.userQuestions?.totalRecords,
    lazyParams: state?.userQuestions?.lazyParams,
    isLoading: state?.userQuestions?.isLoading,
    consultantAddedOrUpdated: state?.userQuestions?.consultantAddedOrUpdated
});

export default BaseComponent(AddUserQuestion, mapStateToProps, {
    addUserQuestionData,
});