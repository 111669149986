import React, { Component } from 'react'
import ServerlessService from '../../../Service/ServerlessService';
import { BasicLazyParams } from "../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import LoadingComponent from '../../../LoadingComponent';
import moment from "moment";
import { logOut } from '../../../Utils';

export default class TableHistroy extends Component {
    constructor(props) {
        super(props);
        this.state = {

            tableHistorysData: null,

            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }


    componentDidMount() {

        this.setState({
            rowDataOfTable: this.props.rowDataOfTable
        }, this.showTableHistory);
    }


    UNSAFE_componentWillReceiveProps(nextProps) {

        if (nextProps.rowDataOfTable._id !== this.props.rowDataOfTable._id) {


            this.setState({
                rowDataOfTable: nextProps.rowDataOfTable
            }, this.showTableHistory);

        }
    }



    showTableHistory = () => {

        const { rowDataOfTable } = this.state;
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            rowDataOfTable,
            tableHistorysData: [],
        });
        const url = `/co-space/transactions/byTableName/${rowDataOfTable.name}?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        tableHistorysData: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.showTableHistory);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    page: event.page,
                    rows: event.rows,
                    first: event.first,
                },
            };
        }, this.showTableHistory);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.showTableHistory);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.showTableHistory);
    };


    render() {
        const { tableHistorysData } = this.state;

        return (
            <div>
                <>
                    <span className="text-1000 font-medium text-2xl  mb-3">
                        Table History
                    </span>
                    <br />
                    <div>
                        <DataTable
                            value={tableHistorysData}
                            lazy
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={
                                this.state.totalRecords
                            }
                            paginator
                            scrollHeight="400px"
                            paginatorLeft
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[
                                5, 10, 25, 50, 100, 200, 300,
                                500,
                            ]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            dataKey="_id"
                            selection={
                                this.state.selectedCustomer
                            }
                            globalFilter={
                                this.state.globalFilter
                            }
                            onSelectionChange={(e) =>
                                this.setState({
                                    selectedCustomer: e.value,
                                })
                            }
                            selectionMode="single"
                        >
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {rowData
                                                ?.assignTableDetails
                                                ?.name ||
                                                "--NA--"}
                                        </>
                                    );
                                }}
                                header="Assigned Table"
                            ></Column>
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {rowData
                                                ?.assignCustomerDetails
                                                ?.name ||
                                                "--NA--"}
                                        </>
                                    );
                                }}
                                header="Assigned To"
                            ></Column>
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {rowData?.days ||
                                                "--NA--"}
                                        </>
                                    );
                                }}
                                header="Time Period"
                            ></Column>
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {rowData?.fee ||
                                                "--NA--"}
                                        </>
                                    );
                                }}
                                header="Amount Paid"
                            ></Column>
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {rowData?.paymentMode ||
                                                "--NA--"}
                                        </>
                                    );
                                }}
                                header="PaymentMode"
                            ></Column>
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {rowData?.paymentReferenceNumber ||
                                                "--NA--"}
                                        </>
                                    );
                                }}
                                header="ReferenceNumber"
                            ></Column>
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {rowData?.paymentGateway ||
                                                "--NA--"}
                                        </>
                                    );
                                }}
                                header="Payment Gateway"
                            ></Column>
                            <Column
                                body={(rowData) => {
                                    return (
                                        <>
                                            {moment(
                                                rowData?.createdAt
                                            ).format(
                                                "MM/DD/YYYY"
                                            ) || "--NA--"}
                                        </>
                                    );
                                }}
                                header="Payment Date"
                            ></Column>
                        </DataTable>

                        <Toast
                            ref={(el) => (this.toast = el)}
                            position="bottom-right"
                        />
                        {this.state.isLoading && (
                            <LoadingComponent />
                        )}
                    </div>
                </>

            </div>
        )
    }
}
