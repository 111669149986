import React, { Component, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ServerlessService from "../../../Service/ServerlessService";
import BaseComponent from "../../common/BaseComponent";

import {
    getFormFields,
    isFormValid,
    onDropDownChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import { Tooltip } from "primereact/tooltip";
import PrintComponent from "./printComp";
import moment from "moment";
import { Chip } from "primereact/chip";
import { Toolbar } from "primereact/toolbar";
import { Checkbox } from "primereact/checkbox";
import { Card } from "primereact/card";
import { ALL_POLICY_TYPES } from "../../../Utils/constants";

import './style.scss'
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { fallbackCopyTextToClipboard } from "../../../Utils";
class PolicyHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            policyDetails: null,
            currentMonth: 0,
            filterType: "all",
            selectedCustomer: null,
            isLoading: false,
            monthOptions: [],
        };
        this.serverlessService = new ServerlessService();


        // this.printRef = React.createRef();
    }

    // filterOptions = [
    //     { label: "All Customers", value: "all" },
    //     { label: "Lifted Customers", value: "lifted" },
    //     { label: "Non-Lifted Customers", value: "nonLifted" },
    // ];
    paymentModes = [
        { label: "Cash", value: "CASH" },
        { label: "Online", value: "ONLINE" },
    ];

    paymentTypes = [
        { label: "Google Pay", value: "GPAY" },
        { label: "PhonePe", value: "PHONEPE" },
        { label: "Others", value: "OTHERS" },
    ];
    componentDidMount() {
        if (this.props?.params?.id) {
            this.fetchData(this.state.currentMonth);
        }
    }


    formPaymentObject = (monthHistoryToBePaid) => {
        return monthHistoryToBePaid?.reduce((previousValue, { paid, uuId, paymentGateway, paymentReferenceNumber, paymentMode }) => {
            return {
                ...previousValue,
                ['sh' + uuId]: {
                    paid,
                    paymentGateway,
                    paymentReferenceNumber,
                    paymentMode
                }
            }
        }, {});
    }



    fetchData = (month) => {
        const url =
            month === 0
                ? `/chitfunds/policy/${this.props.params.id}/history`
                : `/chitfunds/policy/${this.props.params.id}/history?monthToGet=${month}`;

        this.setState({
            isLoading: true,
            policyDetails: undefined,
            selectedCustomer: undefined,
            monthOptions: undefined,
            currentMonth: undefined,
            isMonthClosed: undefined,
            monthClosedUpdated: undefined,
            previousMonthClosed: undefined
        });

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res?.status && res?.res?.status) {
                    const policyDetails = res.res.data;
                    const totalMonths =
                        policyDetails.chitsPolicy.policy.totalMonths;
                    const monthOptions = [];
                    for (let i = 1; i <= totalMonths; i++) {
                        let date = moment(policyDetails.chitsPolicy.policy.startDate).add(i - 1, 'months').format('LL');
                        monthOptions.push({ label: `Month ${i} - ${date}`, value: i });
                    }


                    this.setState({
                        policyDetails,
                        selectedCustomer: policyDetails?.payment?.basicDetails.liftMemberUuid,
                        monthOptions,
                        currentMonth: policyDetails?.payment?.basicDetails.month,
                        isMonthClosed: policyDetails?.payment?.basicDetails.isMonthClosed ? true : false,
                        monthClosedUpdated: policyDetails?.payment?.basicDetails.monthClosedUpdated ? true : false,
                        isLoading: false,
                        previousMonthClosed: policyDetails?.payment?.previousMonthClosed,
                        ...this.formPaymentObject(policyDetails?.payment?.monthHistoryToBePaid)
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        isNoSufficientMembers: (res.errMessage || res.res.message).includes('No sufficient members')
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Error",
                        detail: res.errMessage || res.res.message,
                        life: 3000,
                    });

                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
                this.toast.show({
                    severity: "error",
                    summary: "Error",
                    detail: error.message,
                    life: 3000,
                });
            });
    };

    handleMonthChange = (increment) => {
        const newMonth = this.state.currentMonth + increment;
        if (newMonth >= 0) {
            this.setState({ currentMonth: newMonth }, () => {
                this.fetchData(newMonth);
            });
        }
    };

    handleMonthSelect = (e) => {
        const selectedMonth = e.value;
        this.setState({ currentMonth: selectedMonth }, () => {
            this.fetchData(selectedMonth);
        });
    };

    getFilteredData = () => {
        const monthHistoryToBePaid =
            this.state.policyDetails?.payment?.monthHistoryToBePaid || [];
        // switch (this.state.filterType) {
        //     case "lifted":
        //         return monthHistoryToBePaid.filter((item) => item.isLifted);
        //     case "nonLifted":
        //         return monthHistoryToBePaid.filter((item) => !item.isLifted);
        //     default:
        return monthHistoryToBePaid;
        // }
    };


    validateObject = () => {
        let monthHistoryToBePaid = this.state.policyDetails.payment.monthHistoryToBePaid;

        let currentState = this.state;
        let isValid = true;
        let abc = {};

        monthHistoryToBePaid = monthHistoryToBePaid.map((history) => {
            let paymentTracking = currentState['sh' + history.uuId];

            if (paymentTracking.paymentMode == 'ONLINE' && (!paymentTracking.paymentGateway || !paymentTracking.paymentReferenceNumber)) {
                paymentTracking.isError = true;
                isValid = false;
            } else {
                delete paymentTracking.isError;
            }

            abc['sh' + history.uuId] = paymentTracking;

            return {
                ...history,
                ...paymentTracking
            }
        });

        if (
            isValid
        ) {
            return monthHistoryToBePaid;
        } else {
            this.setState({
                ...abc
            });


        }
    }

    handleSubmit = () => {
        const url = "/chitfunds/policy/history/update";

        const monthHistoryToBePaid = this.validateObject();

        if (!monthHistoryToBePaid) {
            return;
        }


        const data = {
            payment: {
                ...this.state.policyDetails.payment,
                monthHistoryToBePaid,
                basicDetails: {
                    ...this.state.policyDetails.payment.basicDetails,
                    liftMemberUuid: this.state.isMonthClosed ? this.state.selectedCustomer : undefined,
                    isMonthClosed: this.state.isMonthClosed,

                },
            },
            chitsPolicy: this.state.policyDetails.chitsPolicy,
        };

        this.setState({ isLoading: true });

        this.serverlessService
            .put(url, data, true)
            .then((res) => {
                if (res?.res?.status) {

                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            policyDetails: {
                                ...prevState.policyDetails,
                                payment: {
                                    ...prevState.policyDetails.payment,
                                    _id: prevState.policyDetails.payment._id || res.res?.data?._id,
                                    monthClosedUpdated: prevState.policyDetails.payment.monthClosedUpdated || res.res?.data?.basicDetails?.monthClosedUpdated,
                                }
                            },
                            monthClosedUpdated: res.res?.data?.basicDetails?.monthClosedUpdated
                        }

                    })

                    this.toast.show({
                        severity: "success",
                        summary: "Updated Successfully",
                    });
                } else {
                    this.setState({ isLoading: false });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage || res.res.message,
                        life: 3000,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ isLoading: false });
                this.toast.show({
                    severity: "error",
                    summary: "Update Failed",
                    detail: error.message,
                    life: 3000,
                });
            });
    };

    statusTemplate = (rowData) => (
        <div
            style={{
                backgroundColor: rowData.isLifted
                    ? "lightgreen"
                    : "transparent",
                padding: "5px",
                borderRadius: "5px",
            }}
        >
            <Tag
                severity={rowData.paid ? "success" : "warning"}
                value={rowData.paid ? "Paid" : "Pending"}
            />
        </div>
    );

    paymentLiftedSwitch = (rowData) => (
        <div
            style={{
                backgroundColor: rowData.paid ? "lightgreen" : "transparent",
                padding: "5px",
                borderRadius: "5px",
            }}
        >
            <InputSwitch
                checked={rowData.paid === true}
                onChange={(e) => this.handlePaymentStatusChange(e, rowData)}
                disabled={true}
            />
        </div>
    );

    paymentLiftedSwitch = (rowData) => (
        <div

        >
            <InputSwitch
                checked={rowData.isLifted === true}
            // onChange={(e) => this.handlePaymentStatusChange(e, rowData)}
            // disabled={true}
            />
        </div>
    );

    handlePaymentStatusChange = (e, rowData) => {
        const updatedData =
            this.state.policyDetails.payment.monthHistoryToBePaid.map(
                (item) => {
                    if (item.uuId === rowData.uuId) {
                        return {
                            ...item,
                            isLifted: e.value,
                        };
                    }
                    return item;
                }
            );

        this.updatePolicyState(updatedData);
    };

    paymentModeTemplate = (rowData) => (
        <Dropdown
            disabled={this.state.isMonthClosed}
            value={this.state['sh' + rowData.uuId].paymentMode}
            options={this.paymentModes}
            onChange={(e) => this.handlePaymentModeChange(e, rowData)}
            placeholder="Select Payment Mode"
        />
    );

    paymentTypeTemplate = (rowData) => (
        <div className="flex flex-column gap-2">
            <Dropdown
                // value={rowData.paymentGateway}
                value={this.state['sh' + rowData.uuId].paymentGateway}
                options={this.paymentTypes}
                onChange={(e) => this.handlePaymentTypeChange(e, rowData)}
                placeholder="Select Payment Type"
                disabled={
                    !this.state['sh' + rowData.uuId].paymentMode || this.state['sh' + rowData.uuId].paymentMode === "CASH" || this.state.isMonthClosed
                }
            />

            {this.state['sh' + rowData.uuId].paymentMode === "ONLINE" && (
                <InputText
                    disabled={this.state.isMonthClosed}
                    // value={rowData.paymentReferenceNumber || ""}
                    value={this.state['sh' + rowData.uuId].paymentReferenceNumber || ""}
                    onChange={(e) =>
                        this.handleReferenceNumberChange(e, rowData)
                    }
                    placeholder={
                        rowData.paymentGateway === "OTHERS"
                            ? "Enter Payment Type"
                            : "Reference Number"
                    }
                />
            )}
            {
                this.state['sh' + rowData.uuId].isError && <p className="p-error">
                    Should not be empty
                </p>
            }
        </div>
    );

    dueDateTemplate = (rowData) => {
        const startDate = new Date(
            this.state.policyDetails.chitsPolicy.policy.startDate
        );
        const dueDate = new Date(startDate);
        dueDate.setMonth(startDate.getMonth() + this.state.currentMonth - 1);

        return (
            <span className={`${new Date() > dueDate ? "text-red-500" : ""}`}>
                {moment(dueDate).format('LL')}
            </span>
        );
    };

    handlePaymentModeChange = (e, rowData) => {
        this.setState((prevState) => ({
            ['sh' + rowData.uuId]: {
                paid: true,
                paymentMode: e.value,
                paymentGateway:
                    e.value === "CASH" ? null : prevState['sh' + rowData.uuId].paymentGateway,
                paymentReferenceNumber:
                    e.value === "CASH"
                        ? null
                        : prevState['sh' + rowData.uuId].paymentReferenceNumber,
            },
        }));
    };

    handlePaymentTypeChange = (e, rowData) => {
        this.setState((prevState) => ({
            ['sh' + rowData.uuId]: {
                ...prevState['sh' + rowData.uuId],
                paymentGateway: e.value,
                isError: !(e.value && prevState['sh' + rowData.uuId].paymentReferenceNumber)
            },
        }));
    };

    handleReferenceNumberChange = (e, rowData) => {
        this.setState((prevState) => ({
            ['sh' + rowData.uuId]: {
                ...prevState['sh' + rowData.uuId],
                paymentReferenceNumber: e.target.value,
                isError: !(e.target.value && prevState['sh' + rowData.uuId].paymentGateway)
            },
        }));

    };

    updatePolicyState = (updatedData) => {
        this.setState((prevState) => ({
            policyDetails: {
                ...prevState.policyDetails,
                payment: {
                    ...prevState.policyDetails.payment,
                    monthHistoryToBePaid: updatedData,
                },
            },
        }));
    };

    handleCustomerSelect = (e) => {
        this.setState({ selectedCustomer: e.value });
    };
    liftedStatusTemplate = (rowData) => {
        return (
            <Tag
                value={rowData.isLifted ? "Lifted" : "Not Lifted"}
                severity={rowData.isLifted ? "success" : "warning"}
            />
        );
    };

    nameTemplate = (rowData) => {

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <span className={`${this.state['sh' + rowData.uuId].paid ? '' : 'text-red-500'}`}>{rowData.name}</span>

            </div>
        );
    };

    getClipboardText = () => {
        const { policyDetails } = this.state;

        const { chitsPolicy, payment } = policyDetails;

        let copyText = `Policy No: *${chitsPolicy.policyNumber}*
        Policy Amount: *${chitsPolicy.policy.totalAmount}*
        Month: *${payment.basicDetails.month} / ${chitsPolicy.policy.totalMonths}*
        Lift Amount : *${payment.basicDetails.liftAmount}*
        Remaining months: *${chitsPolicy.policy.totalMonths - payment.basicDetails.month}*
        `;

        if (chitsPolicy.policyType == 'type1' || chitsPolicy.policyType == 'type4') {
            copyText += `Amount before lift: *${policyDetails.chitsPolicy.policy.monthlyPay}*
            `
            copyText += `Amount after lift: *${policyDetails.chitsPolicy.policy.payAfterLifting}*
            `
        }

        return copyText;

    }
    copyToClipboard = () => {
        try {
            let copyText = this.getClipboardText();
            if (navigator.clipboard) {
                navigator.clipboard.writeText(copyText).then(() => {
                    this.toast.show({
                        severity: "success",
                        summary: "Copied",
                        detail: "Policy lift details are copied.",
                        life: 3000,
                    });
                });
            } else {
                let status = fallbackCopyTextToClipboard(copyText)
                if (status) {

                    this.toast.show({
                        severity: "success",
                        summary: "Copied",
                        detail: "Policy lift details are copied.",
                        life: 3000,
                    });
                }
            }
        } catch (error) {
            //   alert(error.message)
        }
    };

    shareOnWhatsapp = () => {
        let copyText = this.getClipboardText();
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(copyText)}`, '_self')
    };




    render() {
        const { isNoSufficientMembers, policyDetails } = this.state;
        const { chitsPolicy, payment } = policyDetails || {};

        return (
            <div className="p-4">
                <Authorize
                    permId={PERMISSIONS.CHITS_POLICIES_SHOW_POLICY_HISTORY}
                >
                    {this.state.policyDetails ? <div className="surface-card p-4 border-round">
                        <h3 className="mb-4">Policy History</h3>
                        <div className="p-grid row">
                            <div className="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                                <Button
                                    icon="pi pi-arrow-left"
                                    rounded
                                    outlined
                                    aria-label="Filter"
                                    onClick={() => this.handleMonthChange(-1)}
                                    disabled={this.state.currentMonth <= 1}
                                />
                            </div>
                            <div className="col-12 sm:col-8 md:col-8 lg:col-8 xl:col-8">
                                <div className="text-left sm:text-center">
                                    <Dropdown
                                        value={this.state.currentMonth}
                                        options={this.state.monthOptions}
                                        onChange={this.handleMonthSelect}
                                        placeholder="Select Month"
                                    />
                                </div>
                            </div>
                            <div className="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                                <div className="text-left sm:text-right">
                                    <Button
                                        icon="pi pi-arrow-right"
                                        rounded
                                        outlined
                                        aria-label="Filter"
                                        onClick={() => this.handleMonthChange(1)}
                                        disabled={
                                            this.state.currentMonth >=
                                            policyDetails?.chitsPolicy?.policy?.totalMonths
                                        }
                                    />
                                </div>
                            </div>
                        </div>

                        {/* <div className="flex align-items-center gap-3">
                        <Button
                            icon="pi pi-arrow-left"
                            rounded
                            outlined
                            aria-label="Filter"
                            onClick={() => this.handleMonthChange(-1)}
                            disabled={this.state.currentMonth <= 1}
                        />
                        <Dropdown
                            value={this.state.currentMonth}
                            options={this.state.monthOptions}
                            onChange={this.handleMonthSelect}
                            placeholder="Select Month"
                        />
                        <Button
                            icon="pi pi-arrow-right"
                            rounded
                            outlined
                            aria-label="Filter"
                            onClick={() => this.handleMonthChange(1)}
                            disabled={
                                this.state.currentMonth >=
                                policyDetails?.chitsPolicy?.policy?.totalMonths
                            }
                        />
                    </div> */}

                        <div className={`mt-5 p-4 shadow-2 policy-history-cards border-round-xl p-5 mb-5 ${this.state.monthClosedUpdated ? 'bg-green-100' : 'surface-card '}`}>
                            <div className="font-medium text-3xl text-900 mb-3">Policy: No.{chitsPolicy?.policyNumber} ||  {ALL_POLICY_TYPES[chitsPolicy?.policyType]}</div>
                            <div className="flex flex-wrap row-gap-3 align-items-center justify-content-between mb-5">
                                <div className="flex align-items-center">
                                    <div className="">
                                        <div className="text-900 text-4xl font-bold mb-1">Lift <b>{payment?.basicDetails?.liftAmount}</b></div>
                                        <div className="text-600 text-2xl">Month : {payment?.basicDetails?.month}</div>
                                    </div>
                                </div>
                                <Button
                                    type="button"
                                    icon="pi pi-copy"
                                    onClick={this.copyToClipboard}
                                    className="p-button-rounded p-button-text"
                                ></Button>
                                <Button
                                    type="button"
                                    icon="pi pi-share-alt"
                                    onClick={this.shareOnWhatsapp}
                                    className="p-button-rounded p-button-text"
                                ></Button>
                                {/* <Button label="Copy"
                                onClick={this.copyToClipboard} icon="pi pi-copy"
                                className="p-ripple p-button-outlined p-button-plain"></Button> */}
                            </div>
                            <ul className="list-none p-0 m-0">

                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Policy Amount</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{chitsPolicy?.policy?.totalAmount}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Policy Members</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{chitsPolicy?.policy?.totalMembers}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Policy Months</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{chitsPolicy?.policy?.totalMonths}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Policy Start Date</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{moment(chitsPolicy?.policy?.startDate).format('LL')}</div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div className="text-500 w-6 md:w-2 font-medium">Policy End Date</div>
                                    <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{moment(chitsPolicy?.policy?.endDate).format('LL')}</div>
                                </li>
                                {
                                    (chitsPolicy?.policyType == 'type1' || chitsPolicy?.policyType == 'type4') && <>
                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                            <div className="text-500 w-6 md:w-2 font-medium">Amount before Lift</div>
                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{chitsPolicy?.policy?.monthlyPay}</div>
                                        </li>
                                        <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                            <div className="text-500 w-6 md:w-2 font-medium">Amount after Lift</div>
                                            <div className="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{chitsPolicy?.policy?.payAfterLifting}</div>
                                        </li>
                                    </>
                                }
                            </ul>
                        </div>
                        <div className={`policy-history-cards p-4 shadow-2 border-round-xl p-5 mb-5 ${this.state.monthClosedUpdated ? 'bg-green-100' : 'surface-card '}`}>
                            <div className={`mb-3 ${this.state.monthClosedUpdated ? 'bg-green-100' : ''}`}>
                                <h2 className="m-0 font-bold text-900 text-4xl line-height-3">
                                    Policy Lift Details
                                </h2>

                            </div>
                            <div className={`p-grid ${this.state.monthClosedUpdated ? 'bg-green-100' : ''}`}>
                                <div className="col-12 sm:col-12 md:col-6 lg:col-3">
                                    <label
                                        htmlFor="phoneNumber"
                                        className="block text-900 font-medium mt-3"
                                    >
                                        Member Lifting Policy
                                    </label>
                                    <Dropdown
                                        className="w-full md:w-34rem"
                                        value={this.state.selectedCustomer || ''}
                                        options={policyDetails?.payment?.monthHistoryToBePaid.filter(a => !a.isLifted || a.uuId == this.state.selectedCustomer) || []}
                                        optionLabel="name"
                                        optionValue="uuId"
                                        onChange={this.handleCustomerSelect}
                                        disabled={this.state.isMonthClosed}
                                        placeholder="Select Member To lift"
                                    />
                                </div>
                            </div>
                            <div className={`grid  mt-3 ${this.state.monthClosedUpdated ? 'bg-green-100' : ''}`}>
                                <div className="col-12">
                                    <Checkbox
                                        inputId={"3ww3"}
                                        checked={
                                            this.state.isMonthClosed
                                        }
                                        disabled={this.state.monthClosedUpdated}
                                        onChange={(e) => {
                                            if (!this.state.previousMonthClosed) {
                                                this.toast.show({
                                                    severity: "warn",
                                                    summary: "Invalid",
                                                    detail: 'Previous month policy was not closed. Please close previous one close this one.',
                                                    life: 3000,
                                                });
                                            } else if (!this.state.selectedCustomer) {
                                                this.toast.show({
                                                    severity: "warn",
                                                    summary: "Invalid",
                                                    detail: 'Lifted Person was not selected',
                                                    life: 3000,
                                                });
                                            } else if (policyDetails?.payment?.monthHistoryToBePaid.find(({ uuId }) => !this.state['sh' + uuId].paymentMode || (this.state['sh' + uuId].paymentMode == 'ONLINE' &&
                                                (!this.state['sh' + uuId].paymentGateway || !this.state['sh' + uuId].paymentReferenceNumber)
                                            ))) {
                                                this.toast.show({
                                                    severity: "warn",
                                                    summary: "Invalid",
                                                    detail: 'Please update all payments for members',
                                                    life: 3000,
                                                });
                                            } else {
                                                this.setState({
                                                    isMonthClosed: e.target.checked
                                                })
                                            }
                                        }}
                                    ></Checkbox> &nbsp;  Completed this month policy
                                </div>
                            </div>
                            <div className={`grid  mt-3 ${this.state.monthClosedUpdated ? 'bg-green-100' : ''}`}>
                                <div className="col-12">
                                    {
                                        this.state.selectedCustomer && <div className="mt-3">
                                            <PrintComponent policyDetails={policyDetails}
                                                selectedCustomer={this.state.selectedCustomer}
                                            />
                                        </div>

                                    }
                                </div>
                            </div>

                        </div>
                        <div className="surface-card shadow-2 policy-history-cards border-round-xl overflow-hidden">
                            <DataTable
                                tableClassName="policy-history-table"
                                value={policyDetails?.payment?.monthHistoryToBePaid || []}
                                loading={this.state.isLoading}
                            >
                                <Column
                                    header="Name"
                                    field="name"
                                    body={this.nameTemplate}
                                />
                                <Column
                                    header="Money To Be Paid"
                                    field="moneyToBePaid"
                                />
                                <Column
                                    header="Due Date"
                                    body={this.dueDateTemplate}
                                />
                                <Column
                                    header="Lifted Status"
                                    body={this.paymentLiftedSwitch}
                                />
                                <Column
                                    header="Payment Mode"
                                    body={this.paymentModeTemplate}
                                />
                                <Column
                                    header="Payment Details"
                                    body={this.paymentTypeTemplate}
                                />

                            </DataTable>
                        </div>
                        <Toolbar
                            className="sp-toolbar sp-mt20"
                            right={() => {
                                return (<Button
                                    label="Submit Changes"
                                    disabled={this.state.monthClosedUpdated}
                                    onClick={this.handleSubmit}
                                    className="p-button-primary"
                                />
                                );
                            }}
                        ></Toolbar>

                    </div >
                        : <>
                            {isNoSufficientMembers && <Card className="p-3 m-4"><p>
                                No sufficient members to policy. Please add sufficient members to start policy</p>
                            </Card>}
                        </>
                    }
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {
                        (this.state.isLoading || this.props.isLoading) && (
                            <LoadingComponent />
                        )
                    }
                </Authorize>
            </div>
        );
    }
}



export default BaseComponent(PolicyHistory);
