import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { connect } from "react-redux";
import { InputText } from "primereact/inputtext";
import CkEditorComponent from "../../common/ckEditor";
import { ResourceTypes, showPreviewDocMain } from "../../../Utils";
import "./styles.scss";
import FileUploadLambda2 from "../../fileUpload/fileUploadLambda2";
import { CLOUDFRONT_ENDPOINT } from "./../../../Service/config";
import LoadingComponent from "../../../LoadingComponent";

class AddResource extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            file_upload_id: Math.random().toString(36).substring(2, 10),
            fileupload: "Choose File",
            title: (props.data && props.data.title) || "",
            resource: (props.data && props.data.resource) || null,
            isAssigned: props.data ? true : false,
            resourceType: (props.data && props.data.resourceType) || null,

            resourceTypes: [
                { label: "Youtube", value: "youtube" },
                { label: "External Link", value: "externalLink" },
                { label: "Textbox", value: "texbox" },
                { label: "Upload", value: "upload" },
            ],
        };
    }

    componentDidUpdate(prevProps) {
        console.log(this.props.resources);
        if (
            prevProps.resource !== this.props.resource ||
            prevProps.resourceType !== this.props.resourceType ||
            prevProps.title !== this.props.title
        ) {
            this.setState({
                resourceType: this.props.resourceType || "",
                title: this.props.title || "",
                resource: this.props.resource || "",
                isAssigned: !!this.props.resource,
            });
        }
    }

    checkIsAssigned = (subjectId) => {
        let isAssigned = false;
        this.props.resources.forEach((g) => {
            if (g.data && g.data.subjectId == subjectId) {
                isAssigned = true;
            }
        });
        return isAssigned;
    };

    assignCurriculum = () => {
        const { resource, resourceType, title } = this.state;
        console.log(resource);

        if (
            (resource != "" || resource != null) &&
            resourceType != "" &&
            title != ""
        ) {
            if (resource)


                this.setState(
                    {
                        isAssigned: true,
                    },
                    () => {
                        this.props.onAssignClick({
                            id: this.props.id,
                            data: {
                                resource,
                                resourceType,
                                title,
                            },
                            assigned: true,
                        });
                    }
                );
        } else {
            this.setState({
                errorMsg: "Complete all details for resource",
            });

            setTimeout(() => {
                this.setState({
                    errorMsg: "",
                });
            }, 3000);
        }
    };

    onEditClick = () => {
        if (this.props.resources.every((s) => s.assigned === true)) {
            this.setState(
                {
                    isAssigned: false,
                },
                () => {
                    this.props.onAssignClick({
                        id: this.props.id,
                        assigned: false,
                    });
                }
            );
        } else {
            this.toast.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please add or remove which is in edit state.",
                life: 3000,
            });
        }
    };
    _onresourceUploadSuccess = (fileName, data, fileupload) => {
        this.addAttachment("resourceKey", fileName, data, fileupload);
        console.log(fileupload);
        return;
    };
    _onFilsUploadError = (errMsg) => {
        this.toast.show({
            severity: "error",
            summary: "Some error",
            detail: errMsg,
            life: 3000,
        });
    };

    addAttachment = (fieldName, fileName, data, fileupload) => {
        console.log(fileName, data);
        let upload = {
            ...fileupload,
            [fieldName]:
                data.contentStorageKey || data.Key,
        };

        this.setState((prevState) => {
            return {
                ...this.state.resource,
                resource: upload,
            };
        });
    };

    render() {
        const { resourceType, title, resource } = this.state;
        console.log(this.state, 'sssssssswe');

        return (
            <>

                <div className="p-grid row m-0">
                    <div className="p-col-12 p-md-6 p-md-2-5 p-mb-1 m-2 p-col">
                        <label className="font-bold block mb-0">
                            Resource Type<span className="sp-required">*</span>
                        </label>
                        <Dropdown
                            value={this.state.resourceType}
                            disabled={this.state.isAssigned}
                            options={this.state.resourceTypes}
                            onChange={(e) =>
                                this.setState({
                                    resourceType: e.value,
                                })
                            }
                            placeholder="Select Resource Type"
                            className="dropdown-width"
                        />
                    </div>

                    <div className="p-col-12 p-md-6 p-md-2-5 p-mb-1 m-2">
                        <label className="font-bold block mb-0">
                            Resource Title<span className="sp-required">*</span>
                        </label>
                        <InputText
                            placeholder="Resource Title"
                            disabled={this.state.isAssigned}
                            value={this.state.title}
                            onChange={(e) => {
                                this.setState({
                                    title: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="p-col-12 p-md-6 p-md-2-5 p-mb-1 m-2">
                        {(this.state.resourceType ==
                            ResourceTypes.Youtube ||
                            this.state.resourceType ==
                            ResourceTypes.ExternalLink) && (
                                <>
                                    <label className="font-bold block mb-0">
                                        Resource URL<span className="sp-required">*</span>
                                    </label>
                                    <InputText
                                        disabled={this.state.isAssigned}
                                        placeholder="Resource URL"
                                        value={this.state.resource}
                                        onChange={(e) => {
                                            this.setState({
                                                resource: e.target.value,
                                            });
                                        }}
                                    />
                                </>
                            )}
                        {this.state.resourceType ==
                            ResourceTypes.Textbox && (
                                <>
                                    <label className="font-bold block mb-0">
                                        Resource Content<span className="sp-required">*</span>
                                    </label>
                                    <CkEditorComponent
                                        disabled={this.state.isAssigned}
                                        value={this.state.resource || ""}
                                        onChange={(value) => {
                                            this.setState({
                                                resource: value,
                                            });
                                        }}
                                    />
                                </>
                            )}

                        {this.state.resourceType ==
                            ResourceTypes.Upload && (
                                <>

                                    <label className="font-bold block mb-0">
                                        File<span className="sp-required">*</span>
                                    </label>
                                    {
                                        resource?.name ? <span
                                            onClick={async () => {
                                                this.setState({
                                                    isLoading: true
                                                })
                                                await showPreviewDocMain(resource);
                                                this.setState({
                                                    isLoading: false
                                                })
                                            }}
                                            className="p-button p-component p-button-outlined p-button-secondary cursor-pointer"
                                        >
                                            <i className="pi pi-file-plus" /> Preview file
                                        </span> : <FileUploadLambda2
                                            disabled={this.state.isAssigned}
                                            id={"r" + this.state.file_upload_id}
                                            multiple={false}
                                            onProgress={this._onProgress}
                                            onSucess={this._onresourceUploadSuccess}
                                            Reset={this.state.Reset}
                                            maxFileSize={3000000000}
                                            onError={this._onFilsUploadError}
                                            accept={"imagevideo"}
                                            title={"resourceUpload"}
                                            afterFilesUploaded={() => {
                                                this.setState((prevState) => {
                                                    return {
                                                        fileupload: "Reupload File",
                                                    };
                                                });
                                            }}
                                        >
                                            <span
                                                className="p-button p-component p-button-secondary"
                                            >
                                                <i className="pi pi-file-plus" /> Choose File
                                            </span>
                                        </FileUploadLambda2>
                                    }
                                </>
                            )}
                    </div>
                    <div className="p-col-12 p-md-6 p-md-2-5  m-2">
                        <label className="font-bold block mb-0" style={{ visibility: 'hidden' }}>
                            s
                        </label>
                        {this.state.isAssigned ? (
                            <Button
                                label={`Edit`}
                                onClick={this.onEditClick}
                            />
                        ) : (
                            <Button

                                label={`Save`}
                                onClick={this.assignCurriculum}
                            />
                        )}
                        <Button
                            onClick={() => {
                                this.props.removeTempSubject(this.props.id);
                            }}
                            className="ml-2"
                            icon="pi pi-times"
                            rounded
                            text
                            severity="secondary"
                            aria-label="Cancel" />
                    </div>
                </div >
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.state.isLoading && <LoadingComponent />}
                <p className="sp-required m-2">{this.state.errorMsg}</p>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps, {})(withRouter(AddResource));
