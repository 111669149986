import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { BasicLazyParams, CONSULTANT_STATUS } from '../../Utils/constants';
import { CodeGuruReviewer } from 'aws-sdk';
import { logOut, removeNullUndefinedKeys } from '../../Utils';


const intialTableFilter = {
    lazyParams: {
        ...BasicLazyParams,
        sortField: "createdAt",
        sortOrder: 1,
    },
    globalSearch: ''
}




const dispatchRequestDocumentEditorsData = data => ({
    type: ACTIONS.REQUEST_DOCUMENTEDITING,
    data
}

);

const dispatchReceivedDocumentEditorsData = (data) => ({
    type: ACTIONS.RECEIVED_DOCUMENTEDITING,
    data
}
);

const dispatchErrorDocumentEditorsData = err => ({
    type: ACTIONS.ERROR_DOCUMENTEDITING,
    err
});

const dispatchClearDocumentEditorsData = () => ({
    type: ACTIONS.CLEAR_DOCUMENTEDITING
});


export const getDocumentEditors = (data) => (dispatch, getState) => {
    let _data;

    const currentState = getState().documentEditors;
    console.log(currentState, "currentstate")
    if (data?.lazyParams) {
        currentState.lazyParams = {
            ...currentState.lazyParams,
            ...removeNullUndefinedKeys(data.lazyParams)
        }
    }
    if (data?.globalSearch !== undefined) {
        currentState.globalSearch = data.globalSearch;
    }

    const { lazyParams, globalSearch } = currentState;

    dispatch(dispatchRequestDocumentEditorsData({
        ...data,
        selectedStatus: data.status
    }));

    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/document-editor/assigned?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}` +
            `${globalSearch ? `&search=${globalSearch}` : ""}` +
            `${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: { status: data.status }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedDocumentEditorsData({
                ...res.data,
                selectedStatus: data.status

            }));
        } else {
            dispatch(dispatchErrorDocumentEditorsData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorDocumentEditorsData(e));
    });
}

export const resetLazyDocumentEditors = () => (dispatch) => {
    let _data;

    const { lazyParams, globalSearch } = intialTableFilter;
    dispatch(dispatchRequestDocumentEditorsData(intialTableFilter));
    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/consultants/consultant/contentwriter?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        body: { status: CONSULTANT_STATUS.CREATED }
    }).then((res) => {
        _data = res.data
        if (res && res.data) {
            dispatch(dispatchReceivedDocumentEditorsData(res.data));
        } else {
            dispatch(dispatchErrorDocumentEditorsData({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorDocumentEditorsData(e));
    });
}


export const clearConsltants = () => dispatchClearDocumentEditorsData();