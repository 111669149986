import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import Service from "../../../Service";
import {
    getFormFields,
    isFieldValid,
    isFormValid,
    onDropDownChange,
    onNumberChange,
    onTextChange,
} from "../../../Utils/formHelper";
// import { trimObj } from "../../../Utils";
import PolicyFieds from "./policySchema.json";
import ServerlessService from "../../../Service/ServerlessService";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { logOut } from "../../../Utils";
import { calculateType3 } from "../utils";
import BaseComponent from "../../common/BaseComponent";
import LoadingComponent from "../../../LoadingComponent";

class AddPolicyType3 extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            PolicyFieds,
            this.props.editPolicy?.policy || {}
        );
        this.state = {
            policy: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = () => {
        let policy = this.state.policy;
        policy.totalMonths = policy.totalMembers;

        const formStatus = isFormValid(
            PolicyFieds,
            this.formFields.formValidations,
            policy
        );


        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }




        let policyType = this.props?.policyType;
        if (isCompleteFormValid) {

            policy.startDate = new Date(policy.startDate).toISOString()
            policy.endDate = new Date(policy.endDate).toISOString()


            if (this.props.editPolicy._id) {
                this.setState({
                    isLoading: true,
                });

                const url = `/chitfunds/policy/update`;
                this.serverlessService
                    .post(
                        url,
                        {
                            ...this.props.editPolicy,
                            policy,
                            policyType,
                        },
                        true
                    )
                    .then((res) => {
                        if (res && res?.status && res?.res?.status) {
                            this.setState(
                                {
                                    policy: {
                                        policyType: "",
                                        totalAmount: "",
                                        totalMembers: "",
                                        monthlyPay: "",
                                        payAfterLifting: "",
                                        startDate: "",
                                        endDate: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.push('/admin/policy');
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (
                            e?.response?.status > 400 &&
                            e?.response?.status < 500
                        ) {
                            logOut();
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });
                let policy = this.state.policy;

                const url = `/chitfunds/policy/add`;
                //
                this.serverlessService

                    .post(url, { policy, policyType }, true)
                    .then((res) => {
                        if (res && res?.status && res?.res?.status) {
                            this.setState(
                                {
                                    policy: {
                                        policyType: "",
                                        totalAmount: "",
                                        totalMembers: "",
                                        // totalMonths: "",
                                        montlyPay: "",
                                        payAfterLifting: "",
                                        startDate: "",
                                        endDate: "",
                                    },
                                    isLoading: false,
                                },
                                () => {
                                    this.props.history.push('/admin/policy');
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (
                            e?.response?.status > 400 &&
                            e?.response?.status < 500
                        ) {
                            logOut();
                        }
                        console.log(e);
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };


    onCalculate = () => {
        const { totalAmount } = this.state.policy;

        if (
            totalAmount.toString().length &&
            parseInt(totalAmount)

        ) {
            let data = calculateType3(
                parseInt(totalAmount)
            );
            this.setState((prevState) => {
                return {
                    policy: {
                        ...prevState.policy,
                        ...data
                    },
                    onCalculateError: "",
                };
            });
        } else {
            this.setState({
                onCalculateError:
                    "Total amount, total members and total months are required",
            });
        }
    };

    onStartDate = (e) => {
        let policy = {
            ...this.state.policy,
            startDate: e?.value?.toString(),
            endDate: moment(e.value)
                .add(parseInt(this.state.policy.totalMembers), "months")
                .toString(),
        };
        let formValidations = isFieldValid(
            PolicyFieds.find((fm) => fm.FieldName == 'startDate'),
            this.state.formValidations,
            policy
        );
        formValidations = isFieldValid(
            PolicyFieds.find((fm) => fm.FieldName == 'endDate'),
            formValidations,
            policy
        );




        this.setState((prevState) => {
            return {
                policy,
                formValidations
            };
        });
    };

    render() {
        const { policy, formValidations } = this.state;
        const { isReadOnly } = this.props;
        return (
            <>
                <div className="p-grid row p-3">
                    <div className="col-12 sm:col-12 md:col-6 lg:col-4">
                        <label className="font-bold block mb-2">
                            Total Amount
                        </label>
                        <Dropdown
                            disabled={isReadOnly}
                            onChange={(e) => {
                                onNumberChange(
                                    e.value.toString(),
                                    "totalAmount",
                                    this,
                                    PolicyFieds,
                                    policy,
                                    formValidations,
                                    "policy",
                                    "formValidations",
                                    this.onCalculate
                                );
                            }}
                            value={parseInt(policy.totalAmount)}
                            options={[
                                { label: '1 Lack', value: 100000 },
                                { label: '3 Lack', value: 300000 },
                                { label: '5 Lack', value: 500000 },
                            ]}
                            placeholder="Select Month"
                            className="w-full md:w-34rem"
                        />
                        {formValidations &&
                            !formValidations.fields["totalAmount"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["totalAmount"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-12 sm:col-12 md:col-6 lg:col-4">
                        <label className="font-bold block mb-2">
                            Start Date
                        </label>

                        <Calendar
                            disabled={isReadOnly}
                            value={
                                policy.startDate
                                    ? new Date(policy.startDate)
                                    : null
                            }
                            showIcon={true}
                            placeholder="Start Date"
                            id="startDate"
                            className="w-full"
                            // onChange={(e) =>
                            //     onTextChange(
                            //         e?.value?.toString(),
                            //         "startDate",
                            //         this,
                            //         PolicyFieds,
                            //         policy,
                            //         formValidations,
                            //         "policy",
                            //         "formValidations"
                            //     )
                            // }

                            onChange={this.onStartDate}
                        ></Calendar>

                        {formValidations &&
                            !formValidations.fields["startDate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "startDate"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>

                    <div className="col-12 sm:col-12 md:col-6 lg:col-4">
                        <label className="font-bold block mb-2">
                            End Date
                        </label>

                        <Calendar
                            value={
                                policy.endDate
                                    ? new Date(policy.endDate)
                                    : null
                            }
                            showIcon={true}
                            disabled
                            placeholder="End Date"
                            className="w-full"
                            id="endDate"
                            onChange={(e) =>
                                onTextChange(
                                    e?.value?.toString(),
                                    "endDate",
                                    this,
                                    PolicyFieds,
                                    policy,
                                    formValidations,
                                    "policy",
                                    "formValidations"
                                )
                            }
                        ></Calendar>

                        {formValidations &&
                            !formValidations.fields["endDate"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "endDate"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>

                </div>
                {policy?.firstMonthContribution && (
                    <>
                        <div className="p-grid row p-3">
                            <div className="col-12 sm:col-12 md:col-6 lg:col-4">
                                <div className="surface-card shadow-2 p-3 border-round">
                                    <div className="text-500">Total Amount</div>
                                    <div className="text-900 text-xl font-medium">
                                        ₹{policy.totalAmount.toString()}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 sm:col-12 md:col-6 lg:col-4">
                                <div className="surface-card shadow-2 p-3 border-round">
                                    <div className="text-500">
                                        Agent Commission
                                    </div>
                                    <div className="text-900 text-xl font-medium">
                                        {policy.agentCommission}%
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 sm:col-12 md:col-6 lg:col-4">
                                <div className="surface-card shadow-2 p-3 border-round">
                                    <div className="text-500">Members</div>
                                    <div className="text-900 text-xl font-medium">
                                        {policy.totalMembers}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 sm:col-12 md:col-6 lg:col-4">
                                <div className="surface-card shadow-2 p-3 border-round">
                                    <div className="text-500">Duration</div>
                                    <div className="text-900 text-xl font-medium">
                                        {policy.totalMonths} Months
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="surface-card shadow-4 border-round-2xl overflow-hidden m-2">
                            <DataTable
                                value={policy.monthlyBreakdown}
                                scrollable
                                scrollHeight="400px"
                                tableClassName="add-policy-tbl"
                                stripedRows
                            >
                                <Column field="month" header="Month" />
                                <Column
                                    field="contribution"
                                    header="Contribution"
                                    body={(rowData) =>
                                        `₹${rowData.contribution.toString()}`
                                    }
                                />
                                <Column
                                    field="totalCollected"
                                    header="Total Collected"
                                    body={(rowData) =>
                                        `₹${rowData.totalCollected.toString()}`
                                    }
                                />
                                <Column
                                    field="liftAmount"
                                    header="Lift Amount"
                                    body={(rowData) =>
                                        `₹${rowData.liftAmount.toString()}`
                                    }
                                />
                                <Column
                                    field="commission"
                                    header="Commission"
                                    body={(rowData) =>
                                        `₹${rowData.commission.toString()}`
                                    }
                                />
                            </DataTable>
                        </div>
                        <div className="text-right p-4">
                            <Button
                                label="Cancel"
                                className=" p-button-text hover:bg-primary mr-3"
                                onClick={() => {
                                    this.props.history.push('/admin/policy')
                                }}
                            />
                            <Button
                                label={this.props.editPolicy._id ? "Update" : "Add"}
                                onClick={this.addOrEdit}
                                autoFocus
                                className=" p-button-primary "
                            />
                        </div>
                    </>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {this.state.isLoading && <LoadingComponent />}
            </>
        );
    }
}

export default BaseComponent(AddPolicyType3);
