import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import ServerlessService from "../../../../Service/ServerlessService";
import NavFooter from "../../../navFooter";
import {
    getFormFields,
    isFormValid,
    onTextChange,

} from "../../../../Utils/formHelper";
import {
    BasicLazyParams,
    CONSULTANT_STATUS,
    timeZones,
} from "../../../../Utils/constants";
import { v4 as uuidv4 } from "uuid";
import { logOut, trimObj } from "../../../../Utils";
import ScreeningCallFields from "./addScreeningCalls.json";
import BaseComponent from "../../../common/BaseComponent";
import AddQuestionWebgit from './addQuestionWidget';
import { addorUpdateScreeningCalls } from "../../../../store/actions";

class AddScreeningCall extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ScreeningCallFields,
            this.props.editData ||
            {

            }
        );

        this.state = {
            screeningcalls: this.formFields.data,
            consultants: [],
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            consultant: {
                resources: [],
            },

            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }
    getScreeningCallByConsultantId = (id) => {
        const urlEnd = `/consultants/screeningcalls/id/${id}`;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        this.serverlessService
            .get(urlEnd, true)
            .then((res) => {
                if (res && res.status && res.res.status) {

                    if (res.res.data.resources) {
                        this.setState({
                            isLoading: false,
                            screeningcalls: res.res.data,
                            consultant: res.res.data,
                            totalRecords: res.res.totalRecords,
                        });
                    } else {
                        this.setState({
                            isLoading: false,
                            screeningcalls: res.res.data,
                            consultant: {
                                ...this.state.consultant,
                                resources: [],
                            },
                            totalRecords: res.res.totalRecords,
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    getConsultants = () => {
        let { lazyParams, globalSearch } = this.state;

        let urlEnd = "/consultants/consultant/all";

        this.setState({
            isLoading: true,
            consultants: [],
        });

        this.serverlessService
            .get(urlEnd, { status: CONSULTANT_STATUS.CREATED }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    addOrUpdate = () => {
        let consultant = trimObj(this.state.screeningcalls);
        let { resources } = this.state.consultant;

        const formStatus = isFormValid(
            ScreeningCallFields,
            this.formFields.formValidations,
            consultant
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            this.props.addorUpdateScreeningCalls({
                ...this.state.screeningcalls,
                resources: this.state.consultant.resources,
                createdBy: this.state.screeningcalls.consultant,
            });

        }
    };

    addMore = () => {
        if (
            this.state.screeningcalls.questions.every(
                (s) => s.assigned === true
            )
        ) {
            this.setState((prevState) => {
                return {
                    screeningcalls: {
                        ...prevState.screeningcalls,
                        questions: [
                            ...prevState.screeningcalls.questions,
                            { id: uuidv4(), assigned: false },
                        ],
                    },
                };
            });
        } else {
            this.toast.show({
                severity: "warn",
                summary: "Warning",
                detail: "Please save question to add more",
                life: 3000,
            });
        }
    };

    onAssignClick = (curriculum) => {
        // 
        let resources = this.state.screeningcalls.questions.map((item) => {
            if (item.id == curriculum.id) {
                return curriculum;
            } else {
                return item;
            }
        });

        this.setState((prevState) => {
            return {
                screeningcalls: {
                    ...prevState.screeningcalls,
                    questions: resources,
                },
            };
        });
    };

    removeTempSubject = (id) => {
        if (this.state.screeningcalls.questions.length) {
            let questions = this.state.screeningcalls.questions.filter(
                (item) => item.id != id
            );
            this.setState((prevState) => {
                return {
                    screeningcalls: {
                        ...prevState.screeningcalls,
                        questions,
                    },
                };
            });
        }
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className="p-button-text hover:bg-primary"
                onClick={this.props.onHide}
            />
            <Button
                label={this.props.editData ? "Update" : "Add"}
                onClick={this.addOrUpdate}
                autoFocus
                className="p-button-primary "
            />
        </div>
    );

    componentDidMount() {
        // 
        if (this.props.params.id) {

            this.getScreeningCallByConsultantId(this.props.params.id);
            this.setState({
                isEdit: true,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/screeningcalls");
        }
    }
    render() {
        const { screeningcalls, formValidations } = this.state;
        return (
            <NavFooter>
                <div className="h-screen overflow-auto ss-data-table">
                    <div className="font-bold block mb-2">
                        <h1>Screening Calls</h1>
                    </div>

                    <div className="p-grid row pl-6 pr-6">

                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Technology<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "technology",
                                        this,
                                        ScreeningCallFields,
                                        screeningcalls,
                                        formValidations,
                                        "screeningcalls",
                                        "formValidations"
                                    );
                                }}
                                value={screeningcalls.technology}
                            />
                            {formValidations &&
                                !formValidations.fields["technology"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["technology"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Consultant TimeZone
                                <span className="sp-required">*</span>
                            </label>
                            <Dropdown
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "inteviewTimeZone",
                                        this,
                                        ScreeningCallFields,
                                        screeningcalls,
                                        formValidations,
                                        "screeningcalls",
                                        "formValidations"
                                    );
                                }}
                                value={screeningcalls.inteviewTimeZone}
                                options={timeZones}
                                optionValue="value"
                                optionLabel="label"
                                placeholder="Select Time Zone"
                                className="w-full md:w-35rem"
                            />
                            {formValidations &&
                                !formValidations.fields["inteviewTimeZone"]
                                    .isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields[
                                                "inteviewTimeZone"
                                            ].errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Time<span className="sp-required">*</span>
                            </label>
                            <Calendar
                                readOnlyInput={true}
                                value={
                                    screeningcalls.time
                                        ? new Date(screeningcalls.time)
                                        : null
                                }
                                showIcon={true}
                                timeOnly={true}

                                placeholder="time"
                                className="w-full"
                                id="time"
                                hourFormat="12"
                                onChange={(e) =>
                                    onTextChange(
                                        e?.value?.toString(),
                                        "time",
                                        this,
                                        ScreeningCallFields,
                                        screeningcalls,
                                        formValidations,
                                        "screeningcalls",
                                        "formValidations"
                                    )
                                }
                            ></Calendar>
                            {formValidations &&
                                !formValidations.fields["time"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["time"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Vendor<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "vendor",
                                        this,
                                        ScreeningCallFields,
                                        screeningcalls,
                                        formValidations,
                                        "screeningcalls",
                                        "formValidations"
                                    );
                                }}
                                value={screeningcalls.vendor}
                            />
                            {formValidations &&
                                !formValidations.fields["vendor"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["vendor"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>
                        <div className="col-12 md:col-4 lg:col-4">
                            <label className="font-bold block mb-0">
                                Vendor Location<span className="sp-required">*</span>
                            </label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "location",
                                        this,
                                        ScreeningCallFields,
                                        screeningcalls,
                                        formValidations,
                                        "screeningcalls",
                                        "formValidations"
                                    );
                                }}
                                value={screeningcalls.location}
                            />
                            {formValidations &&
                                !formValidations.fields["location"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["location"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>



                        <div className="col-12 md:col-4 lg:col-10">

                            <label className="font-bold block mb-0">
                                Questions asked in screening calls
                                <span className="sp-required">*</span>
                            </label>
                            <div className="m-4">
                                {screeningcalls &&
                                    screeningcalls.questions &&
                                    screeningcalls.questions.length
                                    ? screeningcalls.questions.map((temp) => {
                                        return (
                                            <div key={temp.id}>
                                                <AddQuestionWebgit
                                                    data={temp.data}
                                                    resources={
                                                        screeningcalls.questions
                                                    }

                                                    key={temp.id}
                                                    id={temp.id}
                                                    onAssignClick={
                                                        this.onAssignClick
                                                    }
                                                    removeTempSubject={
                                                        this.removeTempSubject
                                                    }
                                                />
                                            </div>
                                        );
                                    })
                                    : null}
                            </div>
                            <Button
                                icon="pi pi-plus-circle"
                                className="p-button-rounded addmore m-2"
                                onClick={this.addMore}

                            />

                        </div>
                    </div>
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}

                    <div className="mt-3">
                        <Button
                            label="Cancel"
                            className="p-button-text hover:bg-primary mr-3"
                            onClick={() => {
                                this.props.history.goBack();
                            }}
                        />
                        <Button
                            label={this.state.isEdit ? "Update" : "Add"}
                            onClick={this.addOrUpdate}
                            autoFocus
                            className="p-button-primary "
                        />
                    </div>
                </div>
            </NavFooter>
        );
    }
}
const mapStateToProps = (state) => ({
    screeningCalls: state?.screeningCalls?.screeningCalls,
    totalRecords: state?.screeningCalls?.totalRecords,
    lazyParams: state?.screeningCalls?.lazyParams,
    isLoading: state?.screeningCalls?.isLoading,
    consultantAddedOrUpdated: state?.screeningCalls?.consultantAddedOrUpdated,
});

export default BaseComponent(AddScreeningCall, mapStateToProps, {
    addorUpdateScreeningCalls,
});
