import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import { Toast } from "primereact/toast";

import NoteEntryComponent from "../documentPreperator/noteEntryComponent";
import { logOut } from "../../../Utils";

export default class AssignBackToContentPre extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contentWriters: [],

            note: "",
        };
        this.serverlessService = new ServerlessService();
    }

    // getConsultants = () => {
    //     this.setState({
    //         isLoading: true,
    //         contentWriters: [],
    //     });

    //     const url = `/admin/get-privilege-users`;
    //     this.serverlessService
    //         .post(url, { privilegeType: "isDocVerifier" }, true)
    //         .then((res) => {
    //             if (res && res.status && res.res.status) {
    //                 this.setState({
    //                     isLoading: false,
    //                     contentWriters: res.res.data,
    //                 });
    //             } else {
    //                 this.setState({
    //                     isLoading: false,
    //                 });
    //                 this.toast.show({
    //                     severity: "error",
    //                     summary: "Some error occurred",
    //                     detail: res.errMessage,
    //                     life: 3000,
    //                 });
    //             }
    //         })
    //         .catch((e) => {
    //             if (e?.response?.status > 400 && e?.response?.status < 500) {
    //                 logOut()
    //             }
    //             this.setState({
    //                 isLoading: false,
    //             });
    //             this.toast.show({
    //                 severity: "error",
    //                 summary: "Some error occurred",
    //                 detail: e.message,
    //                 life: 3000,
    //             });
    //         });
    // };

    // componentDidMount() {
    //     this.getConsultants();
    // }

    assignToContentWriter = () => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/consultant/${this.props.isAssingToPreperationTeam
            ? "assign-to-tech-prepare"
            : "assign-back-to-preperator"
            }`;
        this.serverlessService
            .post(
                url,
                {
                    _id: this.props.selectedCustomer._id,
                    note: this.state.note,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        this.props.onHide
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3 "
                onClick={this.props.onCancel}
            />
            <Button
                label={"Assign"}
                onClick={this.assignToContentWriter}
                autoFocus
                className="p-button-primary"
            />
        </div>
    );

    render() {
        const { isAssingToPreperationTeam } = this.props;
        return (
            <Dialog
                header={
                    isAssingToPreperationTeam
                        ? "Assign To Tech Preperation Team"
                        : "Assign Back To Document Preperator"
                }
                visible={true}
                style={{ width: "65vw" }}
                draggable={false}
                onHide={this.props.onCancel}
                footer={this.footerContent}
            >
                <div>
                    <div className="p-grid row p-3 pl-6 pr-6">
                        <label className="font-bold block mb-2">Notes</label>
                        <NoteEntryComponent
                            onChange={(e) => {
                                this.setState({
                                    note: e.target.value,
                                });
                            }}
                            value={this.state.note}
                        />
                    </div>
                </div>

                {this.state.isLoading && <LoadingComponent />}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </Dialog>
        );
    }
}
