
// where members === months 
export function calculateType1(totalAmount, months) {
    let raaa = totalAmount; //  100000
    let tm = months;
    let me = months - 1;
    let ra = totalAmount / months;
    let b = (20 * ra) / 100;
    // let commissionPercentage = 5

    let total = 0;

    let m = [];

    let monthlyBreakdown = [];
    let inc = 0;
    let canTake = 0;
    for (let i = 1; i <= tm; i++) {
        total += ra;
        canTake = (ra * me) + inc
        m.push({
            [i]: {
                single: ra,
                totalC: ra * me,
                canTake: canTake,
                till: total
            }
        })

        monthlyBreakdown.push({
            "contribution": ra,
            "totalCollected": canTake, // ra * me,
            "liftAmount": canTake, // - ((commissionPercentage * raaa) / 100),
            "month": i,
            "eachMemberPaidByThisMonth": total,
            // commission: (commissionPercentage * raaa) / 100
        })
        inc += b;
    }



    return {
        monthlyBreakdown,
        firstMonthContribution: ra,
        totalAmount: raaa,
        totalMembers: tm,
        totalMonths: tm,
        monthlyPay: ra.toFixed(0),
        payAfterLifting: (ra + b).toFixed(0)
    };
}



// where members === months 
export function calculateType2(totalAmount, months, agentCommission) {
    const getIncrementForMonthForLack = () => {
        if (months == 30) {
            return 20;
        } else if (months == 25) {
            return 30;
        } else if (months == 20) {
            return 40;
        } else if (months == 15) {
            return 50;
        } else if (months == 10) {
            return 60;
        } else if (months == 5) {
            return 70;
        }
    };

    let decrementEveryMonthForEachPerson = (totalAmount / 100000) * getIncrementForMonthForLack();

    let total = totalAmount;
    let breakdown = [];
    let firstMonthContribution = totalAmount / months;
    let commission = (agentCommission * totalAmount) / 100;

    let monthlyContributions = [];


    for (let i = months; i >= 1; i--) {

        breakdown.unshift({
            [i]: {
                contribution: firstMonthContribution,
                totalCollected: firstMonthContribution * months,
                liftAmount: (firstMonthContribution * months) - commission,
                commission: commission
            }
        });

        monthlyContributions.unshift(firstMonthContribution);

        if (i > 1) {
            firstMonthContribution -= decrementEveryMonthForEachPerson;
        }
    }

    let finalBreakdown = [];
    breakdown = breakdown.map((breakMonthly, cou) => {
        let some = 0;
        for (let index = 0; index <= cou; index++) {
            const element = monthlyContributions[index];
            some += element;
        }
        breakMonthly.collectedTill = some;
        finalBreakdown.push({
            ...breakMonthly[cou + 1],
            month: cou + 1,
            eachMemberPaidByThisMonth: some

        })
        return breakMonthly;
    })

    return {
        monthlyBreakdown: finalBreakdown,
        firstMonthContribution: firstMonthContribution,
        everyMonthAdditionToEachMember: decrementEveryMonthForEachPerson,
        totalAmount: total,
        totalMembers: months,
        totalMonths: months,
        agentCommission,
    };
}


// where members === months 
export function calculateType3(totalAmount) {
    const getMoths = () => {
        switch (totalAmount) {
            case 100000:
                return 20
            case 300000:
                return 25
            case 500000:
                return 25
        }

    }
    const getIncrement = () => {
        switch (totalAmount) {
            case 100000:
                return 70
            case 300000:
                return 150
            case 500000:
                return 220
        }
    }

    const getFirstMonthContribution = () => {
        switch (totalAmount) {
            case 100000:
                return 3670
            case 300000:
                return 8400
            case 500000:
                return 14700
        }
    }

    let raaa = totalAmount;
    let ra = getFirstMonthContribution();
    let incre = getIncrement();
    let tm = getMoths();
    let perce = 3


    let monthlyBreakdown = [];
    let total = 0;
    // let m = [];
    for (let i = 1; i <= tm; i++) {
        total += ra;
        // m.push({
        //     [i]: {
        //         single: ra,
        //         totalC: ra * tm,
        //         canTake: (ra * tm) - ((perce * raaa) / 100),
        //         till: total
        //     }
        // });
        monthlyBreakdown.push({
            "contribution": ra,
            "totalCollected": ra * tm,
            "liftAmount": (ra * tm) - ((perce * raaa) / 100),
            "month": i,
            "eachMemberPaidByThisMonth": total,
            commission: (perce * raaa) / 100
        })
        if (i < tm) {
            ra = ra + incre;
        }
    }

    return {
        monthlyBreakdown,
        firstMonthContribution: ra,
        everyMonthAdditionToEachMember: incre,
        totalAmount: raaa,
        totalMembers: tm,
        totalMonths: tm,
        agentCommission: perce,
    }
}


// export function calculateType4(totalAmount, months, agentCommission) {
//     const getIncrementForMonthForLack = () => {
//         if (months == 30) {
//             return 25;
//         } else if (months == 25) {
//             return 50;
//         } else if (months == 20) {
//             return 75;
//         } else if (months == 15) {
//             return 100;
//         } else if (months == 10) {
//             return 125;
//         } else if (months == 5) {
//             return 150;
//         }
//     };

//     let decrementEveryMonthForEachPerson = (totalAmount / 100000) * getIncrementForMonthForLack();

//     let total = totalAmount;
//     let breakdown = [];
//     let firstMonthContribution = totalAmount / months;
//     let commission = (agentCommission * totalAmount) / 100;

//     let monthlyContributions = [];


//     for (let i = months; i >= 1; i--) {

//         breakdown.unshift({
//             [i]: {
//                 contribution: firstMonthContribution,
//                 totalCollected: firstMonthContribution * months,
//                 liftAmount: (firstMonthContribution * months) - commission,
//                 commission: commission
//             }
//         });

//         monthlyContributions.unshift(firstMonthContribution);

//         if (i > 1) {
//             firstMonthContribution -= decrementEveryMonthForEachPerson;
//         }
//     }

//     let finalBreakdown = [];
//     breakdown = breakdown.map((breakMonthly, cou) => {
//         let some = 0;
//         for (let index = 0; index <= cou; index++) {
//             const element = monthlyContributions[index];
//             some += element;
//         }
//         breakMonthly.collectedTill = some;
//         finalBreakdown.push({
//             ...breakMonthly[cou + 1],
//             month: cou + 1,
//             eachMemberPaidByThisMonth: some

//         })
//         return breakMonthly;
//     })

//     return {
//         monthlyBreakdown: finalBreakdown,
//         firstMonthContribution: firstMonthContribution,
//         everyMonthAdditionToEachMember: decrementEveryMonthForEachPerson,
//         totalAmount: total,
//         totalMembers: months,
//         totalMonths: months,
//         agentCommission,
//     };
// }

export function calculateType4(totalAmount, months, agentCommission) {
    let raaa = totalAmount; //  100000
    let tm = months;
    let me = months
    let ra = (totalAmount / 100000) * 6000
    let b = (20 * ra) / 100;
    let commissionPercentage = agentCommission

    let total = 0;

    let m = [];

    let monthlyBreakdown = [];
    let inc = 0;
    let canTake = 0;
    for (let i = 1; i <= tm; i++) {
        total += ra;
        canTake = (ra * me) + inc
        m.push({
            [i]: {
                single: ra,
                totalC: ra * me,
                canTake: canTake,
                till: total
            }
        })

        monthlyBreakdown.push({
            "contribution": ra,
            "totalCollected": canTake, // ra * me,
            "liftAmount": canTake - ((commissionPercentage * raaa) / 100),
            "month": i,
            "eachMemberPaidByThisMonth": total,
            commission: (commissionPercentage * raaa) / 100
        })
        inc += b;
    }



    return {
        monthlyBreakdown,
        firstMonthContribution: ra,
        totalAmount: raaa,
        totalMembers: tm,
        totalMonths: tm,
        monthlyPay: ra.toFixed(0),
        payAfterLifting: (ra + b).toFixed(0)
    };
}

export const TOTAL_AMOUNT = [
    { label: '1 Lack', value: 100000 },
    { label: '2 Lacks', value: 200000 },
    { label: '3 Lacks', value: 300000 },
    { label: '5 Lacks', value: 500000 },
    { label: '10 Lacks', value: 1000000 },
    { label: '15 Lacks', value: 1500000 },
    { label: '20 Lacks', value: 2000000 },
    { label: '25 Lacks', value: 2500000 },
    { label: '30 Lacks', value: 3000000 },
]

export const TOTAL_MEMBERS = [
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '20', value: 20 },
    { label: '25', value: 25 },
    { label: '30', value: 30 }
]