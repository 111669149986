import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import moment from "moment";

import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import BaseComponent from "../../common/BaseComponent";
import "./styles.scss";
import { BreadCrumb } from "primereact/breadcrumb";
import { getRecruiters, resetLazyRecruiters } from "../../../store/actions";

class Recruiters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editQuestion: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedQuestion: "",

            rolesList: [],
            showDialog: false,
            recruitersList: [],
            consultantList: [],
            Listofassigned: [],
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    componentDidMount() {
        if (!this.props.recruiterList?.length) {
            this.props.getRecruiters({
                privilegeType: "isBenchSales",
            });
        }
    }
    onGlobalSearch = (e) => {
        this.props.getRecruiters({
            globalSearch: e.target.value,
            privilegeType: "isBenchSales",
        });
    };

    onPage = (event) => {
        this.props.getRecruiters({
            lazyParams: event,
            privilegeType: "isBenchSales",
        });
    };

    onSort = (event) => {
        this.props.getRecruiters({
            lazyParams: event,
            privilegeType: "isBenchSales",
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getRecruiters({
            lazyParams: event,
            privilegeType: "isBenchSales",
        });
    };

    onHide = () => {
        this.setState({
            isShowConfirmationDialog: false,
            editQuestion: null,
            visible: false,
        });
    };

    showConsultants = (rowData) => {
        this.setState({
            selectedRecruiter: rowData,
            showDialog: true,
        });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                Constultants Assigned To Recruiters
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={PERMISSIONS.RECRUITER_LIST}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={PERMISSIONS.RECRUITER_SEARCH}
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={PERMISSIONS.RECRUITER_DOWNLOAD}
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const items = [{ label: "Admin", url: "/#/admin" }, { label: "Recruiters" }];

        const home = {
            icon: "pi pi-home",
            url: "/#/admin",
        };
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Authorize permId={PERMISSIONS.RECRUITER_LIST}>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                header={header}
                                value={this.props.recruiterList}
                                lazy
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="recruiterId"
                                selection={this.state.selectedQuestion}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedQuestion: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="recruiterName"
                                    header="Recruiters"
                                ></Column>
                                <Column
                                    field="consultantCount"
                                    header="Consultants"
                                    body={(rowData) => (
                                        <div className="tooltip-container">
                                            <Button
                                                id={`btn-${rowData._id}`}
                                                className="p-button-rounded"
                                                onClick={() =>
                                                    this.showConsultants(
                                                        rowData
                                                    )
                                                }
                                            >
                                                {rowData.consultantCount}
                                            </Button>
                                        </div>
                                    )}
                                />
                            </DataTable>
                        </Authorize>
                    </Card>
                    <Dialog
                        draggable={false}
                        header="List Of Consultants"
                        visible={
                            this.state.showDialog &&
                            this.state.selectedRecruiter?.consultants.length > 0
                        }
                        onHide={() => this.setState({ showDialog: false })}
                        style={{ width: "80%", height: "60vh" }}
                    >
                        <div>
                            {this.state.selectedRecruiter && (
                                <div>
                                    <DataTable
                                        draggable
                                        value={
                                            this.state.selectedRecruiter
                                                .consultants
                                        }
                                        className="p-datatable-sm"
                                        rowHover
                                        paginator
                                        rows={5}
                                    >
                                        <Column
                                            field="name"
                                            header="Consultant Name"
                                            body={(rowData) => (
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    {rowData.name}
                                                </span>
                                            )}
                                        />
                                        <Column
                                            field="assignedAt"
                                            header="Assigned At"
                                            body={(rowData) => (
                                                <span>
                                                    {rowData.assignedAt
                                                        ? moment(
                                                            rowData.assignedAt
                                                        ).format(
                                                            "MMMM Do YYYY, h:mm:ss a"
                                                        )
                                                        : "Not Assigned Yet"}
                                                </span>
                                            )}
                                        />
                                    </DataTable>
                                </div>
                            )}
                        </div>
                    </Dialog>
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    recruiterList: state?.recruiterList?.recruiterList,
    totalRecords: state?.recruiterList?.totalRecords,
    lazyParams: state?.recruiterList?.lazyParams,
    isLoading: state?.recruiterList?.isLoading,
});

export default BaseComponent(Recruiters, mapStateToProps, {
    getRecruiters,
    resetLazyRecruiters,
});
