import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import React, { Component } from "react";
import ServerlessService from "../../../Service/ServerlessService";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import { Column } from "primereact/column";
import { logOut } from "../../../Utils";
import AddMemberToPolicy from "./addMemberToPolicy";
import { Dropdown } from "primereact/dropdown";
import BaseComponent from "../../common/BaseComponent";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { BreadCrumb } from "primereact/breadcrumb";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
const items = [{ label: "Admin", url: "/#/admin" }, { label: "Policy" }, { label: "All Members" }];

const home = {
    icon: "pi pi-home",
    url: "/#/admin",
};

class Members extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: null,
            policyMembers: null,
            newReplaceMemberId: null,
            customers: []
        };

        this.serverlessService = new ServerlessService();
    }


    componentDidMount() {
        if (this.props?.params?.id) {
            this.getPolicyById(this.props?.params.id);
        }
        this.getCustomers();
        this.getMembersByPolicyId();
    }

    getPolicyById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/chitfunds/policy/${id}/details`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            policy: res.res.data,
                            isLoading: false,
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };


    getMembersByPolicyId = () => {
        this.setState({
            isLoading: true,
            policyMembers: [],
        });
        let id = this.props?.params?.id;
        const url = `/chitfunds/policy/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        policyMembers: res?.res?.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowRemoveMemberDialog: false

        })
    };

    onRemoveMember = (_id) => {
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId;

        const url = `/chitfunds/policy/linked-member/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isShowRemoveMemberDialog: false
                        },
                        this.getMembersByPolicyId
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage || res.res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onReplaceMember = (_id) => {
        const { newReplaceMemberId } = this.state;

        if (!newReplaceMemberId) {
            return

        }
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId;

        const url = `/chitfunds/policy/member/${_id}/`;
        this.serverlessService
            .post(url, {
                newMemberId: newReplaceMemberId
            }, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            isLoading: false,
                            newReplaceMemberId: null,
                            isShowReplaceMemberDialog: false
                        },
                        this.getMembersByPolicyId
                    );
                } else {
                    this.setState({
                        isLoading: false,
                        newReplaceMemberId: null,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage || res.res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                    newReplaceMemberId: null,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className=" p-button-text hover:bg-primary "
                    onClick={this.onCloseConfirmation}
                />
                <Button
                    label="Ok"

                    onClick={this.onRemoveMember}
                    autoFocus
                    className=" p-button-primary "
                />
            </div>
        );
    };


    footerContentForReplaceMember = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className=" p-button-text hover:bg-primary "
                    onClick={this.onCloseConfirmation}
                />
                <Button
                    label="Replace"

                    onClick={this.onReplaceMember}
                    autoFocus
                    className="ml-2 p-button-primary "
                />
            </div>
        );
    };

    getCustomers = () => {
        const url = `/chitfunds/members/partial`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        customers: res?.res?.data,
                        //  totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };



    showRemoveMemberDialog = (id) => {
        this.setState({
            isShowRemoveMemberDialog: true,
            selectedId: id,
        });
    };
    showReplaceMemberDialog = (id) => {
        this.setState({
            isShowReplaceMemberDialog: true,
            selectedId: id,
        });
    };


    onCloseConfirmation = () => {
        this.setState({
            isShowRemoveMemberDialog: false,
            isShowReplaceMemberDialog: false,
            newReplaceMemberId: null

        });
    };


    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
            };
        })
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };
    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                {rowData.startedPolicy ? <>
                    <div className="col-2">
                        {/* <Authorize permId={PERMISSIONS.BRANCHES_DELETE}> */}
                        <span
                            onClick={() => this.showReplaceMemberDialog(rowData._id)}
                            data-pr-tooltip={`Replace ${rowData?.member?.name}`}
                            data-pr-position="top"
                            className={`p-text replaceC${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.replaceC${rowData._id}`} />

                        {/* </Authorize> */}
                    </div>
                </> : <div className="col-2">
                    {/* <Authorize permId={PERMISSIONS.BRANCHES_DELETE}> */}
                    <span
                        onClick={() => this.showRemoveMemberDialog(rowData._id)}
                        data-pr-tooltip={`Remove ${rowData?.member?.name}`}
                        data-pr-position="top"
                        className={`p-text removeC${rowData._id}`}
                    >
                        <i className="pi pi-trash" />
                    </span>
                    <Tooltip target={`.removeC${rowData._id}`} />

                    {/* </Authorize> */}
                </div>
                }

            </div>
        );
    };


    render() {
        const { policy, policyMembers } = this.state;

        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                All Members ({policyMembers?.length})
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_MEMBERS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.state.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_MEMBERS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_MEMBERS_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );
        return (
            <div className="p-4">
                <BreadCrumb model={items} home={home} />
                <div >
                    {policy && <>

                        <Toolbar
                            className="sp-toolbar sp-mt20"
                            right={() => {
                                return (<></>

                                );
                            }}
                            left={() => {
                                return (
                                    <Authorize
                                        permId={PERMISSIONS.CHITS_MEMBERS_CREATE}
                                    >
                                        <Button
                                            label="Add Member"
                                            icon="pi pi-plus"
                                            className="p-button-primary m-3"
                                            aria-label="Add Member"
                                            disabled={Number.parseInt(policy.policy.totalMembers) == this.state.policyMembers?.length}
                                            onClick={() => this.setState({ isShowAddMember: true, })}
                                        />
                                    </Authorize>
                                );
                            }}
                        ></Toolbar>
                        <div className="surface-card m-4 shadow-2 border-round overflow-hidden">
                            <DataTable
                                tableClassName="policy-table"
                                ref={(el) => (this.dt = el)}
                                header={header}
                                globalFilter={this.state.globalSearch}
                                value={policyMembers}
                                scrollable

                                rowHover
                                rows={25}
                                totalRecords={policyMembers?.length}
                                paginator
                                scrollHeight="400px"
                                rowsPerPageOptions={[
                                    10, 25, 50, 100, 200, 300, 500,
                                ]}
                            >
                                <Column
                                    body={(rowData, { rowIndex }) =>
                                        <>{rowIndex + 1}</>
                                    }
                                    header="No"
                                ></Column>
                                <Column
                                    field="member.name"
                                    header="Name"
                                ></Column>
                                <Column
                                    field="member.fatherName"
                                    header="Father name"
                                ></Column>
                                <Column
                                    field="member.mobile"
                                    header="Mobile"
                                ></Column>
                                <Column
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                    header="Actions"
                                ></Column>
                            </DataTable>
                        </div>
                    </>}
                </div>
                {
                    this.state.isShowAddMember && <AddMemberToPolicy
                        customers={this.state.customers}
                        policyId={policy._id}
                        policy={policy}
                        policyMembersLength={policyMembers?.length || 0}
                        afterAddCustomerToPolicy={() => {
                            this.setState({
                                isShowAddMember: false
                            }, this.getMembersByPolicyId)
                        }}
                        onHide={() => {
                            this.setState({
                                isShowAddMember: false
                            })
                        }}
                    />
                }

                {this.state.isShowRemoveMemberDialog && (
                    <Dialog
                        className="sm:w-11 md:w-6 w-11 lg:w-4 xl:w-3"
                        header="Remove Member"
                        visible={true}
                        //   style={{ width: "25vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent()}
                        draggable={false}
                    >
                        <p>policy member will be removed from this policy</p>
                    </Dialog>
                )}

                {this.state.isShowReplaceMemberDialog && (
                    <Dialog
                        header="Do you want to replace, are you Sure?"
                        visible={true}
                        className="sm:w-11 md:w-6 w-11 lg:w-4 xl:w-3"
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentForReplaceMember()}
                        draggable={false}
                    >
                        <div className="">
                            <label className="font-bold block mb-2">
                                Select Member
                            </label>
                            <Dropdown
                                onChange={(e) => {
                                    this.setState({
                                        newReplaceMemberId: e.value
                                    })
                                }}
                                value={this.state.newReplaceMemberId}
                                options={this.state.customers || []}
                                optionValue="_id"
                                optionLabel="name"
                                placeholder="Select Member"
                                className="w-full md:w-34rem"
                            />
                        </div>
                    </Dialog>
                )}

                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </div>
        );
    }
}

export default BaseComponent(Members);
