import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { getFormFields, isFormValid, onNumberChange, onTextChange } from './../../../Utils/formHelper';
import { Button } from 'primereact/button';
import { logOut, trimObj } from './../../../Utils';
import Service from './../../../Service';
import { saveUserSession } from './../../../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session/index'
import LoadingComponent from './../../../LoadingComponent';
import ServerlessService from './../../../Service/ServerlessService';


let formFields = [
    {
        "Type": "Number",
        "Label": "OTP",
        "FieldName": "otp",
        "Required": true,
        "MinLength": 6,
        "MaxLength": 6
    },
    {
        "Type": "Password",
        "Label": "Password",
        "FieldName": "password",
        "Required": true,
        "MinLength": 8
    },
    {
        "Type": "Password",
        "Label": "Confirm Password",
        "FieldName": "passwordConfirm",
        "Required": true,
        "MinLength": 8
    },

]




class VerifyOTPChangePassword extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(formFields, {});
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            otpResentCount: 0
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    isAdditionalValid = () => {

        let user = JSON.parse(JSON.stringify(this.state.user));
        let { formValidations } = this.state;
        let isValid = true;
        if (user.password != user.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }


    onVerify = () => {
        let { user } = this.state;

        const formStatus = isFormValid(formFields, this.formFields.formValidations, trimObj(this.state.user));


        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let data = trimObj(this.state.user);
            data._id = this.props.userId;

            this.setState({
                isLoading: true
            });

            const url = `/admin/verify-change`;

            this.serverlessService.post(url, data, true).then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.saveUserSession(res.res);
                        this.props.history.push('/')
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })



        } else {
            this.setState({
                otpErrMsg: 'Invalid OTP.'
            })


        }
    }

    resendOTP = () => {

        if (this.state.otpResentCount < 3) {



            this.setState({
                isLoading: true
            });

            const url = `/admin/resend-otp`;

            this.serverlessService.post(url, { _id: this.props.userId }, true).then((res) => {
                if (res && res.status && res.res.status) {

                    this.setState((prevState) => {
                        return {
                            isLoading: false,
                            otpResentCount: prevState.otpResentCount + 1
                        };
                    });
                    this.toast.show({ severity: 'success', summary: 'Success', detail: 'OTP sent successfully.', life: 3000 });
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Error', detail: res.message, life: 3000 });
                }
            }).catch(e => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })
        } else {
            this.toast.show({ severity: 'error', summary: 'Error', detail: 'OTP resent limit exceeded, please try later.', life: 3000 });
        }
    }


    render() {
        const { formValidations, user } = this.state;
        return (<>
            <label htmlFor="phoneNumber" className="block text-900 font-medium">OTP</label>
            <InputText value={user.otp}
                className='w-full '
                onChange={(e) => { onNumberChange(e.target.value, 'otp', this, formFields, user, formValidations, 'user', 'formValidations') }} />
            {formValidations && !formValidations.fields['otp'].isValid && <p className="p-error">{formValidations.fields['otp'].errorMsg}</p>}
            <label htmlFor="phoneNumber" className="block text-900 font-medium mt-3">Password</label>
            <InputText value={user.password}
                type="password"
                autoComplete="new-password"
                className='w-full '
                onChange={(e) => { onTextChange(e.target.value, 'password', this, formFields, user, formValidations, 'user', 'formValidations') }} />
            {formValidations && !formValidations.fields['password'].isValid && <p className="p-error">{formValidations.fields['password'].errorMsg}</p>}
            <label htmlFor="phoneNumber" className="block text-900 font-medium mt-3">Confirm Password</label>
            <InputText value={user.passwordConfirm}
                type="password"
                autoComplete="new-password"
                className='w-full '
                onChange={(e) => { onTextChange(e.target.value, 'passwordConfirm', this, formFields, user, formValidations, 'user', 'formValidations') }} />
            {formValidations && !formValidations.fields['passwordConfirm'].isValid && <p className="p-error">{formValidations.fields['passwordConfirm'].errorMsg}</p>}
            <div>
                <p className='font-medium  cursor-pointer transition-colors transition-duration-150 my-3'>OTP not received? <span className='text-primary' onClick={this.resendOTP} >Resend</span></p>
            </div>
            <Button label="Change" onClick={this.onVerify} className="w-full py-3 font-medium" />


            <Toast ref={(el) => this.toast = el} position="bottom-right" />
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
        </>)
    }
}


const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(VerifyOTPChangePassword)));
