import React, { Component } from 'react';
import { PolicyTypes } from '../../../Utils/constants';
import { Dropdown } from 'primereact/dropdown';
import AddPolicyType1 from './addPolicyType1';
import AddPolicyType2 from './addPolicyType2';
import LoadingComponent from '../../../LoadingComponent';
import BaseComponent from '../../common/BaseComponent';
import ServerlessService from '../../../Service/ServerlessService';
import AddPolicyType3 from './addPolicyType3';
import AddPolicyType4 from './addPolicyType4';
import { Card } from 'primereact/card';
import { BreadCrumb } from 'primereact/breadcrumb';
import Authorize, { PERMISSIONS } from '../../../session/authorize';

const home = {
    icon: "pi pi-home",
    url: "/#/admin",
};


class AddPolicyTypeBase extends Component {
    constructor(props) {
        super(props);
        this.state = {

            policy: {},
            isLoading: false
        }
        this.serverlessService = new ServerlessService();
        this.items = [{ label: "Admin", url: "/#/admin" }, { label: "Policy" }, { label: `${this.props?.params?.id ? 'Edit' : 'Create '} Policy` }];
    }

    componentDidMount() {
        if (this.props?.params?.id) {
            this.getPolicyById(this.props?.params.id);
        }
    }

    getPolicyById = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/chitfunds/policy/${id}/details`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res.status && res.res.data) {
                    this.setState(
                        {
                            policy: res.res.data,
                            isLoading: false,
                        },
                        () => { }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };



    render() {
        const { policy, isLoading } = this.state;
        console.log(this.props.params.operation, 'operation')
        return (
            <>
                <div className='p-3'>
                    <BreadCrumb model={this.items} home={home} />
                    <Authorize
                        permId={this.props?.params?.id ? (this.props.params.operation == 'view' ? PERMISSIONS.CHITS_POLICIES_VIEW : PERMISSIONS.CHITS_POLICIES_EDIT) : PERMISSIONS.CHITS_POLICIES_CREATE}
                    >
                        {
                            isLoading && <LoadingComponent />
                        }
                        {
                            !isLoading && <>
                                <div className="p-grid row p-3">
                                    <div className="col-12 sm:col-12 md:col-6 lg:col-4 ">
                                        <label className="font-bold block mb-2">
                                            Policy Type
                                        </label>
                                        <Dropdown
                                            onChange={(e) => {
                                                this.setState((prevState) => {
                                                    return {
                                                        policy: {
                                                            ...prevState.policy,
                                                            policyType: e.value
                                                        }

                                                    }
                                                })
                                            }}
                                            disabled={this.props?.params?.id}
                                            value={policy.policyType}
                                            options={PolicyTypes}
                                            optionValue="id"
                                            optionLabel="type"
                                            placeholder="Select Policy Type"
                                            className="w-full md:w-34rem"
                                        />
                                    </div>
                                </div>
                                {policy.policyType == "type1" && (<AddPolicyType1
                                    isReadOnly={this.props.params.operation == 'view'}
                                    onHide={this.props.onHide}
                                    onPolicyAddOrUpdate={this.props.onPolicyAddOrUpdate}
                                    policyType={policy.policyType}
                                    editPolicy={policy}></AddPolicyType1>)}
                                {policy.policyType == "type2" && (<AddPolicyType2
                                    isReadOnly={this.props.params.operation == 'view'}
                                    onHide={this.props.onHide}
                                    onPolicyAddOrUpdate={this.props.onPolicyAddOrUpdate}
                                    policyType={policy.policyType}
                                    editPolicy={policy}></AddPolicyType2>)}
                                {policy.policyType == "type3" && (<AddPolicyType3
                                    isReadOnly={this.props.params.operation == 'view'}
                                    onHide={this.props.onHide}
                                    onPolicyAddOrUpdate={this.props.onPolicyAddOrUpdate}
                                    policyType={policy.policyType}
                                    editPolicy={policy} />)}

                                {policy.policyType == "type4" && (<AddPolicyType4
                                    isReadOnly={this.props.params.operation == 'view'}
                                    onHide={this.props.onHide}
                                    onPolicyAddOrUpdate={this.props.onPolicyAddOrUpdate}
                                    policyType={policy.policyType}
                                    editPolicy={policy} />)}

                            </>
                        }
                    </Authorize>
                </div>

            </>
        );
    }
}

export default BaseComponent(AddPolicyTypeBase);



// editPolicy={this.state.editPolicy}
// onPolicyAddOrUpdate={this.onPolicyAddOrUpdate}