import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";

import { Dialog } from "primereact/dialog";
import {
    DOCUMENT_EDITING,
} from "../../../Utils/constants";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import { Card } from "primereact/card";
import DocumentEditingDialog from "../documentPreperator/documentEditingRequest/documentEditingDialog";
import BaseComponent from "../../common/BaseComponent";
import {
    getDocumentEditors,
    resetLazyDocumentEditors,
} from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";
import { ResponsiveText } from "../../common/ResponsiveText";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
class DocumentEditing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            rowDataOfTable: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };



    deleteTable = () => {
        this.setState({
            isLoading: true,
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        const url = `/consultants/consultant/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.props.getDocumentEditors({ status: this.props.selectedStatus });
                        }
                    );
                    this.toast.show({
                        message: res.message,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };



    componentDidMount() {
        if (!this.props.documentEditingConsultants?.length) {
            this.props.getDocumentEditors({ status: this.props.selectedStatus });
        }
    }

    onGlobalSearch = (e) => {
        this.props.getDocumentEditors({
            globalSearch: e.target.value,
            status: this.props.selectedStatus
        });
    };

    onPage = (event) => {
        this.props.getDocumentEditors({
            lazyParams: event,
            status: this.props.selectedStatus
        });
    };

    onSort = (event) => {
        this.props.getDocumentEditors({
            lazyParams: event,
            status: this.props.selectedStatus
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getDocumentEditors({
            lazyParams: event,
            status: this.props.selectedStatus
        });
    };


    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={this.onHide}
                />
                <Button
                    label="Renewal"
                    autoFocus
                    onClick={this.onRenewalClick}
                />
            </div>
        );
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize
                    permId={PERMISSIONS.DOCUMENT_EDITING_SHOW_CONSULTANT}
                >
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData.consultant}`
                                );
                            }}
                            data-pr-tooltip="Show consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id}`} />
                    </div>
                </Authorize>
                <Authorize
                    permId={PERMISSIONS.DOCUMENT_EDITING_EDIT_DOC_WINDOW}
                >
                    <div className="col-2">
                        <span
                            onClick={() =>
                                this.setState({
                                    isShowDocumentWindow: true,
                                    editData: rowData,
                                })
                            }
                            data-pr-tooltip="Document Editing"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id}`}
                        >
                            <i className="pi pi-server" />
                        </span>
                        <Tooltip target={`.abc${rowData._id}`} />
                    </div>
                </Authorize>

                <Authorize permId={PERMISSIONS.DOCUMENT_EDITING_NOTES}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip target={`.tableHistory${rowData._id}`} />
                    </div>
                </Authorize>
            </div>
        );
    };

    showConsultantNotes = (rowData) => {
        this.setState({
            isShowConsultantNotes: true,
            editData: rowData,
        });
    };

    showNotes = (rowData) => {
        this.setState({
            isShowNotes: true,
            editData: rowData,
        });
    };



    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    previewBody = (rowData) => {
        return (
            <>
                {rowData.documentURL ? (
                    <Authorize permId={PERMISSIONS.DOCUMENT_EDITING_PREVIEW}>
                        <span
                            onClick={() => {
                                const urlToOpen = rowData.documentURL?.path;

                                window.open(urlToOpen, "_blank");
                            }}
                            data-pr-tooltip="Preview Doc"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            Preview <i className="pi pi-file" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                ) : (
                    "NA"
                )}
            </>
        );
    };

    onChangeStatusToInProgress = () => {
        this.setState((prevState) => {
            return {
                consultants: prevState.consultants.map((a) => {
                    if (prevState.editData._id == a._id) {
                        a.status = DOCUMENT_EDITING.INPROGRESS;
                    }
                    return a;
                }),
                editData: {
                    ...prevState.editData,
                    status: DOCUMENT_EDITING.INPROGRESS,
                },
            };
        });
    };
    onDocumentUploaded = () => {
        this.setState(
            {
                isShowConsultant: false,
                editData: null,
                isShowDocumentWindow: false,
            },
            this.props.getDocumentEditors({ status: this.props.selectedStatus })
        );
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };

    onFilterChange = (e) => {
        this.setState({ selectedStatus: e.value });
        this.props.getDocumentEditors({ status: e.value == 'all' ? '' : e.value });
    };


    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Document Requests</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_EDITING_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_EDITING_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.DOCUMENT_EDITING_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );

        const statusOptions = [
            { label: "All", value: "all" },
            { label: "Requested", value: DOCUMENT_EDITING.REQUESTED },
            { label: "Inprogress", value: DOCUMENT_EDITING.INPROGRESS },
            { label: "Submitted", value: DOCUMENT_EDITING.SUBMITTED },
            { label: "Completed", value: DOCUMENT_EDITING.COMPLETED },
            { label: "Blocked", value: DOCUMENT_EDITING.BLOCKED },
            { label: "Pending", value: DOCUMENT_EDITING.PENDING },
            { label: "Re-Requested", value: DOCUMENT_EDITING.REREQUESTED }
        ];

        const items = [{ label: "Admin", url: "/#/admin" }, { label: "Document Editings" }];

        const home = {
            icon: "pi pi-home",
            url: "/#/admin",
        };
        return (
            <>
                <div className='p-5'>

                    <BreadCrumb model={items} home={home} />
                    <Toolbar
                        className="p-0 bg-primary-reverse border-white"
                        right={() => {
                            return (
                                <div className="p-col">
                                    <Dropdown
                                        value={this.props.selectedStatus == '' ? 'all' : this.props.selectedStatus}
                                        options={statusOptions}
                                        className="w-11rem mt-3 ml-4"
                                        onChange={this.onFilterChange}
                                        placeholder="Select a status"
                                    />
                                </div>
                            );
                        }}

                    ></Toolbar>
                    <Authorize permId={PERMISSIONS.DOCUMENT_EDITING_LIST}>
                        <div className="mt-3 shadow-3 border-round overflow-hidden">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.documentEditingConsultants}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams?.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams?.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="documentType"
                                    header="Type Of Document"
                                ></Column>
                                <Column field="notes" header="Notes"
                                    body={({ notes }) => <ResponsiveText text={notes} />}
                                ></Column>
                                <Column field="status" header="Status"></Column>

                                <Column
                                    field="documentURL"
                                    header="Preview Doc"
                                    body={(rowData) =>
                                        this.previewBody(rowData)
                                    }
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </div>
                    </Authorize>
                </div>
                {this.state.isShowNotes && (
                    <Dialog
                        header={"Notes"}
                        draggable={false}
                        visible={true}
                        style={{ width: "49vw" }}
                        onHide={() => {
                            this.setState({
                                isShowNotes: false,
                                editData: null,
                            });
                        }}
                    >
                        <p>{this.state.editData.notesToContentWriter}</p>
                    </Dialog>
                )}

                {this.state.isShowDocumentWindow && (
                    <DocumentEditingDialog
                        documentEditingRequest={this.state.editData}
                        consultantId={this.state?.editData?.consultant}
                        isDocumentEditor={true}
                        onChangeStatusToInProgress={
                            this.onChangeStatusToInProgress
                        }
                        onUploaded={this.onDocumentUploaded}
                        onHide={() => {
                            this.setState({
                                isShowDocumentWindow: false,
                            });
                        }}
                    />
                )}

                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        draggable={false}
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                    >
                        <p>Deleted table can't be restore.</p>
                    </Dialog>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    documentEditingConsultants: state?.documentEditors?.documentEditingConsultants,
    totalRecords: state?.documentEditors?.totalRecords,
    lazyParams: state?.documentEditors?.lazyParams,
    isLoading: state?.documentEditors?.isLoading,
    selectedStatus: state?.documentEditors?.selectedStatus,
});

export default BaseComponent(DocumentEditing, mapStateToProps, {
    getDocumentEditors,
    resetLazyDocumentEditors,
});
