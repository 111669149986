import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import ServerlessService from "../../../../Service/ServerlessService";

import {
    getFormFields,
    isFormValid,
    onTextChange,
    onNumberChange,
    onEmailChange,
    isFieldValid,
    onFileChange,
} from "../../../../Utils/formHelper";
import { fileToS3, showPreviewDoc, trimObj } from "../../../../Utils";
import ConsultantFields from "./documentEditingRequest.json";
import { CLOUDFRONT_ENDPOINT } from "../../../../Service/config";
import BaseComponent from "../../../common/BaseComponent";
import FileUpload from "../../../common/fileUpload";
import { addSubmissions } from "../../../../store/actions";

class EditingRequest extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
        this.fileUploadRef = React.createRef();
    }

    addOrUpdate = async () => {
        let consultant = trimObj(this.state.consultant);
        consultant.submissionProofURL = await fileToS3(
            this.props.orgId,
            consultant.submissionProofURL
        );
        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );

        let isCompleteFormValid = true;

        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        consultant.consultant = this.props?.params?.id;

        if (isCompleteFormValid) {
            this.props.addSubmissions(consultant);
        }
    };

    componentDidMount() { }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push(
                `/admin/submission/${this.props?.params?.id}`
            );
        }
    }

    showPreviewDoc = (file) => {
        if (file.status) {
            const newTab = window.open(file.path, "_blank");
        } else {
            const reader = new FileReader();

            reader.onload = function (event) {
                const data = event.target.result;
                const blob = new Blob([data], { type: file.type });
                const url = URL.createObjectURL(blob);

                const newTab = window.open(url, "_blank");
                newTab.focus();
            };
            reader.readAsArrayBuffer(file);
        }
    };

    render() {
        const { consultant, formValidations } = this.state;
        return (
            <div className="h-screen overflow-auto ss-data-table">
                <div className="font-bold block mb-2">
                    <h1>Submissions</h1>
                </div>

                <div className="p-grid row pl-6 pr-6">
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Client<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "client",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.client}
                        />
                        {formValidations &&
                            !formValidations.fields["client"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["client"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Pay Scale<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "payScale",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.payScale}
                        />
                        {formValidations &&
                            !formValidations.fields["payScale"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["payScale"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Email<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onEmailChange(
                                    e.target.value,
                                    "email",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.email}
                        />
                        {formValidations &&
                            !formValidations.fields["email"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["email"].errorMsg}
                                </p>
                            )}
                    </div>
                    <div className="col-4">
                        <label className="font-bold block mb-2">
                            Phone<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onNumberChange(
                                    e.target.value,
                                    "phone",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.phone}
                        />
                        {formValidations &&
                            !formValidations.fields["phone"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["phone"].errorMsg}
                                </p>
                            )}
                    </div>

                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-2">
                            Submission Screenshot
                            {/* <span className="sp-required">*</span> */}
                        </label>
                        <span
                            onClick={() => {
                                showPreviewDoc(consultant.submissionProofURL);
                            }}
                        >
                            {consultant.submissionProofURL?.name}
                        </span>
                        <FileUpload
                            id="submissionProofURL"
                            multiple={false}
                            accept={"documents"}
                            afterFilesUploaded={(files) => {
                                if (files.length) {
                                    onFileChange(
                                        files[0],
                                        "submissionProofURL",
                                        this,
                                        ConsultantFields,
                                        consultant,
                                        formValidations,
                                        "consultant",
                                        "formValidations"
                                    );
                                }
                            }}
                            inputRef={this.fileUploadRef}
                        />

                        {formValidations &&
                            !formValidations.fields["submissionProofURL"]
                                .isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields[
                                            "submissionProofURL"
                                        ].errorMsg
                                    }
                                </p>
                            )}
                    </div>
                </div>
                <div className="mt-3">
                    <Button
                        label="Cancel"
                        className="p-button-text hover:bg-primary mr-3"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    />
                    <Button
                        label={this.props.editData ? "Update" : "Add"}
                        onClick={this.addOrUpdate}
                        autoFocus
                        className="p-button-primary "
                    />
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    orgId: state?.orgData?.orgId,
    consultants: state?.submissions?.consultants,
    totalRecords: state?.submissions?.totalRecords,
    lazyParams: state?.submissions?.lazyParams,
    isLoading: state?.submissions?.isLoading,
    consultantAddedOrUpdated: state?.submissions?.consultantAddedOrUpdated,
});

export default BaseComponent(EditingRequest, mapStateToProps, {
    addSubmissions,
});
