import { Dialog } from 'primereact/dialog'
import React, { Component } from 'react'
import DocumentsEditingRequest from '.'
import DocumentEditorActions from '../../documentEditing/documentEditorActions'

export default class DocumentEditingDialog extends Component {



    render() {

        return (
            <div>
                <Dialog
                    header="Document Editing"
                    visible={true}
                    className='w-11'
                    style={{ height: '90vh' }}
                    draggable={false}
                    closable={true}
                    onHide={this.props.onHide}
                >
                    {
                        this.props.isDocumentPrepartor && <DocumentsEditingRequest consultant={this.props.consultant}
                            onDocumentAccepted={this.props.onDocumentAccepted}

                        />
                    }
                    {
                        this.props.isDocumentEditor && <DocumentEditorActions
                            onChangeStatusToInProgress={this.props.onChangeStatusToInProgress}
                            onUploaded={this.props.onUploaded}
                            consultant={this.props.consultant}
                            documentEditingRequest={this.props.documentEditingRequest}

                        />
                    }
                </Dialog>
            </div>
        )
    }
}
