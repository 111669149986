import React, { Component } from 'react'
import { Dialog } from 'primereact/dialog';
import Service from './../../../Service';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import resellerFields from './reseller.json';
import LoadingComponent from './../../../LoadingComponent';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, isFieldValid, onTextChange } from './../../../Utils/formHelper';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session/index'
import { bytesToSize, isValidFileSize, logOut, trimObj } from './../../../Utils';
import ServerlessService from './../../../Service/ServerlessService';
import DirectFileUpload from './../../common/directFileUpload';
import { CLOUDFRONT_ENDPOINT } from './../../../Service/config';
import FileUploadLambda2 from "../../fileUpload/fileUploadLambda2";


class CreateReseller extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            resellerFields,
            this.props.reseller
                ? {
                    email: "test@gmail.com",
                    password: "Send@1234",
                    passwordConfirm: "Send@1234",

                    ...this.props.reseller,
                }
                : {}
        );
        this.state = {
            reseller: this.formFields.data,
            formValidations: this.formFields.formValidations,
            uploadedFilesInfo: this.props.reseller ? this.props.reseller.attachments || [] : []
        }
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }


    isAdditionalValid = () => {

        let reseller = JSON.parse(JSON.stringify(this.state.reseller));
        let { formValidations } = this.state;
        let isValid = true;
        if (reseller.password != reseller.passwordConfirm) {
            isValid = false;
            formValidations.fields['passwordConfirm'] = { ...formValidations.fields['passwordConfirm'], isValid: false, errorMsg: `Password and Confirm password is not matched.` }
        }

        this.setState((prevState) => {
            return {
                formValidations
            };
        });

        return isValid

    }


    createReseller = async () => {
        let resellerData = trimObj(this.state.reseller);
        const formStatus = isFormValid(resellerFields, this.formFields.formValidations, resellerData);

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }


        if (!this.isAdditionalValid()) {
            isCompleteFormValid = false;
        }




        if (isCompleteFormValid) {


            if (this.state.uploadedFilesInfo && this.state.uploadedFilesInfo.length) {
                if (this.state.uploadedFilesInfo.some(a => !isValidFileSize(a.size))) {
                    this.toast.show({ severity: 'error', summary: 'Invalid Attachments', detail: 'Attachments can not be more than 20MB. Please remove', life: 3000 });
                    return;
                } else {

                }

            }


            let url = `/organization/save`
            if (this.props.reseller) {
                url = `/organization/update`
            }



            this.setState({
                isLoading: true,
            });


            this.serverlessService.post(url, resellerData, true).then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                    }, () => {


                        this.props.onSave(this.props.reseller ? true : false);
                    })
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.message, life: 3000 });
                }

            }).catch(e => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })

        }

    }

    componentDidMount() {
        if (this.props.isSessionChecked && !this.props.authenticated) {
            this.props.history.push('/login')
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && !this.props.authenticated)) {
            this.props.history.push('/login')
        }

    }

    footer = () => {
        return (
            <div>
                <Button
                    label={`${this.props.reseller ? "Update" : "Save"
                        } Reseller`}
                    className="p-button-primary "
                    onClick={this.createReseller}
                />
            </div>
        );
    };

    _removefile = (fileInf) => {
        let uploadedFiles = [];
        this.state.uploadedFilesInfo.forEach((fileInfo) => {
            if (fileInfo.name !== fileInf.name) {
                uploadedFiles = [...uploadedFiles, fileInfo];
            }
        });

        this.setState({
            uploadedFilesInfo: uploadedFiles
        });
    }

    showAttachment = (attachment) => {
        if (attachment.path) {
            window.open(attachment.path);
        } else {
            let src = URL.createObjectURL(attachment);
            window.open(src);
        }
    }

    _onLogoRectSuccess = (fileName, data) => {
        this.addAttachment('logoRectangle', fileName, data);
        return
    }

    _onLogoSquareSuccess = (fileName, data) => {
        this.addAttachment('logoSquare', fileName, data);
        return;

    }
    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }

    addAttachment = (fieldName, fileName, data) => {
        let reseller = {
            ...this.state.reseller,
            [fieldName]: CLOUDFRONT_ENDPOINT + (data.contentStorageKey || data.Key),
        }
        let formValidations = isFieldValid(resellerFields.find(fm => fm.FieldName == fieldName), this.state.formValidations, reseller);


        this.setState((prevState) => {
            return {
                reseller,
                formValidations
            }
        });
    }

    filesUploadedBindingInfo = (filesInfo = []) => {
        let temp;
        temp = filesInfo.map((fileInfo) => {
            return (
                <div key={fileInfo.name}>
                    <div className="p-grid upload" dir="ltr">
                        <div style={{ width: "100%", overflow: "hidden" }}>
                            <div style={{ width: "30px", float: "right" }}>
                                <span
                                    className="btn-remove-file sp-float-right"
                                    onClick={() => this._removefile(fileInfo)}
                                >
                                    <i className="pi pi-times" />
                                </span>
                            </div>
                            <div style={{ marginRight: "30px" }}>
                                <p
                                    onClick={() => {
                                        this.showAttachment(fileInfo);
                                    }}
                                    className={`filename ${isValidFileSize(fileInfo.size)
                                        ? "valid-file"
                                        : "invalid-file"
                                        }`}
                                >
                                    {fileInfo.name}{" "}
                                    <span className="sp-right">
                                        {" "}
                                        {bytesToSize(fileInfo.size)}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>


                </div>)

        });
        return temp;
    }

    _afterFilesUploaded = (files) => {
        for (let i = 0; i < files.length; i++) {
            this.setState((prevState, prevProps) => ({
                uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
            }));
        }
    }



    render() {
        const { reseller, formValidations } = this.state;
        return (
            <>
                <Dialog
                    header={`${this.props.reseller ? "Edit" : "Create"
                        } Reseller`}
                    blockScroll={true}
                    draggable={false}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    footer={this.footer}
                    closable={true}
                    onHide={this.props.onHide}
                    style={{ width: "65vw" }}

                >
                    <div className="">
                        <div className=" p-5">
                            <div className="grid formgrid p-fluid">
                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        Organization Name
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        className="w-full  "
                                        autoComplete="off"
                                        value={reseller.orgName}
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "orgName",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["orgName"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "orgName"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                <div className="field mb-4 col-4">
                                    <label
                                        htmlFor="bio2"
                                        className="font-medium text-900"
                                    >
                                        Phone (For Login)
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.phoneNumber}
                                        className="w-full   "
                                        onChange={(e) => {
                                            onNumberChange(
                                                e.target.value,
                                                "phoneNumber",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields[
                                            "phoneNumber"
                                        ].isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations
                                                        .fields[
                                                        "phoneNumber"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                {!this.props.reseller && (
                                    <>


                                        <div className="field mb-4 col-4">
                                            <label className="font-medium text-900">
                                                Password
                                                <span className="sp-required">
                                                    *
                                                </span>
                                            </label>
                                            <InputText
                                                value={reseller.password}
                                                type="password"
                                                autoComplete="new-password"
                                                className="w-full   "
                                                onChange={(e) => {
                                                    onTextChange(
                                                        e.target.value,
                                                        "password",
                                                        this,
                                                        resellerFields,
                                                        reseller,
                                                        formValidations,
                                                        "reseller",
                                                        "formValidations"
                                                    );
                                                }}
                                            />
                                            {formValidations &&
                                                !formValidations.fields[
                                                    "password"
                                                ].isValid && (
                                                    <p className="p-error">
                                                        {
                                                            formValidations
                                                                .fields[
                                                                "password"
                                                            ].errorMsg
                                                        }
                                                    </p>
                                                )}
                                        </div>

                                        {" "}



                                        <div className="field mb-4 col-4">
                                            <label className="font-medium text-900">
                                                Confirm Password
                                                <span className="sp-required">
                                                    *
                                                </span>
                                            </label>
                                            <InputText
                                                value={
                                                    reseller.passwordConfirm
                                                }
                                                type="password"
                                                autoComplete="new-password"
                                                className="w-full   "
                                                onChange={(e) => {
                                                    onTextChange(
                                                        e.target.value,
                                                        "passwordConfirm",
                                                        this,
                                                        resellerFields,
                                                        reseller,
                                                        formValidations,
                                                        "reseller",
                                                        "formValidations"
                                                    );
                                                }}
                                            />
                                            {formValidations &&
                                                !formValidations.fields[
                                                    "passwordConfirm"
                                                ].isValid && (
                                                    <p className="p-error">
                                                        {
                                                            formValidations
                                                                .fields[
                                                                "passwordConfirm"
                                                            ].errorMsg
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    </>
                                )}


                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        Sub Domain
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.subDomain}
                                        className="w-full   "
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "subDomain",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["subDomain"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "subDomain"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        Domain
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.domain}
                                        className="w-full   "
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "domain",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["domain"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "domain"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        Contact Number 1
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.contactNumber1}
                                        className="w-full   "
                                        onChange={(e) => {
                                            onNumberChange(
                                                e.target.value,
                                                "contactNumber1",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields[
                                            "contactNumber1"
                                        ].isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "contactNumber1"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        Contact Number 2
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.contactNumber2}
                                        className="w-full   "
                                        onChange={(e) => {
                                            onNumberChange(
                                                e.target.value,
                                                "contactNumber2",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields[
                                            "contactNumber2"
                                        ].isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "contactNumber2"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        Your name
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.name}
                                        autoComplete="new-password"
                                        className="w-full   "
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "name",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["name"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "name"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>


                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        Contact Email
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.contactEmail}
                                        className="w-full   "
                                        onChange={(e) => {
                                            onEmailChange(
                                                e.target.value,
                                                "contactEmail",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields[
                                            "contactEmail"
                                        ].isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "contactEmail"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        City
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.city}
                                        autoComplete="new-password"
                                        className="w-full   "
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "city",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["city"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "city"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                <div className="field mb-4 col-4">
                                    <label className="font-medium text-900">
                                        State
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.state}
                                        autoComplete="new-password"
                                        className="w-full   "
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "state",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["state"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "state"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="field mb-4 col-12">
                                    <label className="font-medium text-900">
                                        Address
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.address}
                                        autoComplete="new-password"
                                        className="w-full   "
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "address",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["address"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "address"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>



                                <div className="field mb-4 col-6">
                                    <label className="font-medium text-900">
                                        Country
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        value={reseller.country}
                                        autoComplete="new-password"
                                        className="w-full   "
                                        onChange={(e) => {
                                            onTextChange(
                                                e.target.value,
                                                "country",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["country"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "country"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                <div className="field mb-4 col-6">
                                    <label className="font-medium text-900">
                                        Pincode
                                        <span className="sp-required">
                                            *
                                        </span>
                                    </label>
                                    <InputText
                                        className=" ma-w100p"
                                        value={reseller.pincode}
                                        onChange={(e) => {
                                            onNumberChange(
                                                e.target.value,
                                                "pincode",
                                                this,
                                                resellerFields,
                                                reseller,
                                                formValidations,
                                                "reseller",
                                                "formValidations"
                                            );
                                        }}
                                    />
                                    {formValidations &&
                                        !formValidations.fields["pincode"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "pincode"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="field mb-4 col-4">
                                    <label
                                        // htmlFor="avatar2"
                                        className="font-medium text-900"
                                    >
                                        Logo
                                    </label>
                                    <div className="flex align-items-center">
                                        <img
                                            src="assets/images/avatar.jpeg"
                                            alt="avatar-f-4"
                                            className="mr-4"
                                            width="50px"
                                            height="50px"
                                        />
                                        {/* <DirectFileUpload id={"logosquare"}
                                            multiple={false}
                                            onProgress={this._onProgress}
                                            onSucess={this._onLogoSquareSuccess}
                                            //  Reset={this.state.Reset}
                                            maxFileSize={307200}
                                            onError={this._onFilsUploadError}
                                            accept={'images'}
                                            title={'Images'}
                                            afterFilesUploaded={this._afterFilesUploaded}
                                        >
                                            <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                        </DirectFileUpload> */}
                                        <FileUploadLambda2 id={"logosquare"}
                                            multiple={false}
                                            onProgress={this._onProgress}
                                            onSucess={this._onLogoSquareSuccess}
                                            Reset={this.state.Reset}
                                            maxFileSize={30000000}
                                            onError={this._onFilsUploadError}
                                            accept={'images'}
                                            title={'logosquare'}
                                            afterFilesUploaded={() => {
                                                this.setState((prevState) => {
                                                    return {
                                                        consultant: {
                                                            ...prevState.consultant,
                                                            passportURL: '',
                                                        },
                                                    };
                                                });

                                            }}
                                        >
                                            <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                        </FileUploadLambda2>

                                        {
                                            reseller.logoSquare ? <img src={reseller.logoSquare}
                                                className="rect-logo" height="200px" width="200px" /> : <img
                                                src="assets/images/avatar.jpeg"
                                                alt="avatar-f-4"
                                                className="mr-4"
                                                width="100px"
                                                height="100px"
                                            />
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </Dialog>
            </>
        );
    }
}






const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    isSessionChecked: state?.user?.isSessionChecked,
});


export default connect(mapStateToProps, {

})(Authentication(withRouter(CreateReseller)));
