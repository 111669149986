import React, { Component } from "react";
import ServerlessService from "../../../../Service/ServerlessService";
import EditingRequest from "./editingRequest";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {
    BasicLazyParams,
    DOCUMENT_EDITING,
} from "../../../../Utils/constants";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Authorize, { PERMISSIONS } from "../../../../session/authorize";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import SubmissionStatus from "./submissionStatus";
import moment from "moment";
import BaseComponent from "../../../common/BaseComponent";
import {
    getSubmissions,
    resetLazySubmissions,
} from "../../../../store/actions";
import { Card } from "primereact/card";
import { logOut, showPreviewDoc } from "../../../../Utils";
import { BreadCrumb } from "primereact/breadcrumb";
class Submissions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowSendRequest: false,
            globalSearch: "",
            editData: null,
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };
        this.serverlessService = new ServerlessService();
    }
    onGlobalSearch = (e) => {
        this.props.getSubmissions({
            globalSearch: e.target.value,
            consultant: this.props.params.id,
        });
    };

    onPage = (event) => {
        this.props.getSubmissions({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    onSort = (event) => {
        this.props.getSubmissions({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getSubmissions({
            lazyParams: event,
            consultant: this.props.params.id,
        });
    };
    componentDidMount() {
        if (!this.props.submmissons?.length) {


            if (this.props?.params?.id) {
                this.props.getSubmissions({ consultant: this.props?.params.id });
            }
        }
    }



    changeStatus = () => {
        this.setState({
            isLoading: true,
        });

        const { editData } = this.state;

        const url = `/consultants/submissions/`;
        this.serverlessService
            .put(
                url,
                {
                    _id: editData._id,
                    status: DOCUMENT_EDITING.COMPLETED,
                },
                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        this.acceptDocumentEdited
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    acceptDocumentEdited = () => {
        this.setState({
            isLoading: true,
            isShowAcceptDialog: false,
        });
        const { editData } = this.state;

        const files = [
            ...(this.props.consultant.files || []),
            {
                name: editData.documentType,
                url: editData.documentURL,
            },
        ];
        const url = `/consultants/consultant`;
        this.serverlessService
            .put(
                url,
                {
                    _id: this.props.consultant._id,
                    files,
                },

                true
            )
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getConsultants();
                        }
                    );
                    this.toast.show({
                        message: res.message,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getConsultants = (consultant) => {
        const _consultant = consultant || this.props.consultant;
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/submissions/${_consultant._id}?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.SUBMISSIONS_TRACKER}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/submissions/tracker`
                                );
                            }}
                            data-pr-tooltip="Submission Tracker"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>

                <Authorize permId={PERMISSIONS.SUBMISSIONS_VIEW}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/submissions/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Submission View"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
            </div>
        );
    };


    showSubmissionStatus = (id) => {
        this.setState({
            isShowSubmissionStatus: true,
            editData: id,
        });
    };



    previewBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.SUBMISSIONS_VIEW}>
                        <span
                            onClick={() => {
                                showPreviewDoc(rowData.submissionProofURL)
                            }}
                            data-pr-tooltip="Preview Doc"
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Submissions</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.SUBMISSIONS_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.SUBMISSIONS_DOWNLOAD
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.SUBMISSIONS_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>

            </div>
        );
        const items = [{ label: "Admin", url: "/#/admin" }, { label: "Marketing" }, { label: "Submissions" }];

        const home = {
            icon: "pi pi-home",
            url: "/#/admin",
        };
        const { id } = this.props.params;
        return (
            <div className="h-screen overflow-auto ss-data-table">

                <Card>
                    <BreadCrumb model={items} home={home} />
                    {" "}
                    <div className="p-grid p-align-center">

                        <Button
                            type="button"
                            label="New Request"
                            icon="pi pi-plus"
                            className="m-3"
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/submissions/add/${id}`
                                );
                            }}
                        />
                    </div>
                    {this.state.isShowSendRequest && (
                        <EditingRequest
                            editData={this.state.editData}
                            consultant={this.props.consultant}
                            onRequestedSuccessfully={() => {
                                this.setState(
                                    {
                                        isShowSendRequest: false,
                                    },
                                    this.getConsultants
                                );
                            }}
                            onCancel={() => {
                                this.setState({
                                    isShowSendRequest: false,
                                });
                            }}
                        />
                    )}
                    <Authorize permId={PERMISSIONS.SUBMISSIONS_LIST}>
                        <DataTable
                            ref={(el) => (this.dt = el)}
                            value={this.props.submmissons}
                            lazy
                            header={header}
                            scrollHeight="400px"
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.props.lazyParams.first}
                            last={this.props.totalRecords}
                            rows={this.props.lazyParams.rows}
                            totalRecords={this.props.totalRecords}
                            paginator
                            paginatorLeft
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 300, 500]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            dataKey="_id"
                            selectionMode="single"
                        >
                            <Column field="client" header="Client"></Column>
                            <Column field="payScale" header="Pay"></Column>
                            <Column field="email" header="Email"></Column>
                            <Column field="phone" header="Phone"></Column>
                            <Column
                                field="createdAt"
                                header="Submitted Date"
                                body={(rowData) =>
                                    moment(rowData.createdAt).format("LLL")
                                }
                            ></Column>
                            <Column
                                field="screenShotURL"
                                header="Screenshot"
                                body={(rowData) => this.previewBody(rowData)}
                            ></Column>

                            <Column
                                header="Actions"
                                body={(rowData) => this.actionsBody(rowData)}
                            ></Column>
                        </DataTable>
                    </Authorize>
                    {this.state.isShowSubmissionStatus && (
                        <SubmissionStatus
                            submission={this.state.editData}
                            onHide={() => {
                                this.setState({
                                    isShowSubmissionStatus: false,
                                    editData: null,
                                });
                            }}
                        />
                    )}

                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {(this.state.isLoading || this.props.isLoading) && (
                        <LoadingComponent />
                    )}
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    submmissons: state?.submissions?.submmissons,
    totalRecords: state?.submissions?.totalRecords,
    lazyParams: state?.submissions?.lazyParams,
    isLoading: state?.submissions?.isLoading,
});
export default BaseComponent(Submissions, mapStateToProps, {
    getSubmissions,
    resetLazySubmissions,
});
