import { Button } from "primereact/button";

import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { BreadCrumbHome, logOut, trimObj } from "../../../Utils";

import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../Utils/formHelper";
import QuestionFields from "./question.json";
import BaseComponent from "../../common/BaseComponent";
import { getFaq } from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";




class AddQuestion extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            QuestionFields,
            this.props.editQuestion || {}
        );
        this.state = {
            question: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isShowAssign: false,
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Question Bank" },
        { label: "FAQ", url: "/#/admin/faq" },
        { label: `${this.props.params.id ? "Edit" : 'Add'} FAQ`, url: `/#/admin/faq/${this.props.params.id ? 'edit/' + this.props.params.id : 'add'}` }
    ];


    addOrEdit = () => {
        let question = trimObj(this.state.question);

        const formStatus = isFormValid(
            QuestionFields,
            this.formFields.formValidations,
            question
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.params.id) {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/faq/update`;
                this.serverlessService
                    .put(url, this.state.question, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    question: {
                                        name: "",
                                        fatherName: "",
                                        permanentAddress: "",
                                        adhar: "",
                                        mobileNumber: "",
                                        emailId: "",
                                        currentAddress: "",
                                        assigned: false,
                                        assignedTable: null,
                                    },

                                    isLoading: false,
                                },
                                () => {
                                    this.props.getFaq()
                                    this.props.history.goBack();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/faq/add`;
                this.serverlessService
                    .post(url, this.state.question, true)
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                () => {
                                    this.props.getFaq()
                                    this.props.history.push('/admin/faq');
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    getFaq = (id) => {
        this.setState({
            isLoading: true,
        });

        const url = `/consultants/faq/id/${id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        question: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    showAssign = () => {
        this.setState({
            isShowAssign: true,
            data: this.state.question,
        });
    };

    componentDidMount() {
        if (this.props?.params?.operation == "edit") {
            if (this.props?.params?.id) {
                this.getFaq(this.props?.params.id);
            }
        }
    }

    render() {
        const { question, formValidations } = this.state;

        return (
            <div className="p-5">
                <BreadCrumb model={this.items} home={BreadCrumbHome} />
                <div className="shadow-3 border-round overflow-hidden p-5 mt-5">
                    <div className="text-900 text-3xl font-medium mb-3 ml-2">{this.props.params.id ? 'Edit' : 'Add'} FAQ</div>
                    <div className="p-grid row m-1">
                        <div className="col-12">
                            <label className="font-bold block mb-2">Question</label>
                            <InputText
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "question",
                                        this,
                                        QuestionFields,
                                        question,
                                        formValidations,
                                        "question",
                                        "formValidations"
                                    );
                                }}
                                value={question.question}
                            />
                            {formValidations &&
                                !formValidations.fields["question"].isValid && (
                                    <p className="p-error">
                                        {
                                            formValidations.fields["question"]
                                                .errorMsg
                                        }
                                    </p>
                                )}
                        </div>

                        <div className="col-12">
                            <label className="font-bold block mb-2">Answer</label>
                            <InputTextarea
                                rows={10}
                                onChange={(e) => {
                                    onTextChange(
                                        e.target.value,
                                        "answer",
                                        this,
                                        QuestionFields,
                                        question,
                                        formValidations,
                                        "question",
                                        "formValidations"
                                    );
                                }}
                                value={question.answer}
                            />
                            {formValidations &&
                                !formValidations.fields["answer"].isValid && (
                                    <p className="p-error">
                                        {formValidations.fields["answer"].errorMsg}
                                    </p>
                                )}
                        </div>
                    </div>

                    <Toolbar
                        className="sp-toolbar sp-mt20"
                        right={() => {
                            return (<>
                                <Button
                                    label="Cancel"
                                    className="p-button-text hover:bg-primary mr-3"
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                />
                                <Button
                                    label={
                                        this.props?.params?.operation == "edit"
                                            ? "Update"
                                            : "Add"
                                    }
                                    onClick={this.addOrEdit}
                                    autoFocus
                                    className="p-button-primary "
                                />
                            </>)
                        }}
                    ></Toolbar>
                </div>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default BaseComponent(AddQuestion, () => { }, {
    getFaq
});
