import React, { Component } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { Divider } from "primereact/divider";
import { Carousel } from "primereact/carousel";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Authentication from "../../../session/index";
import {
    getFormFields,
    isFormValid,
    onEmailChange,
    onTextChange,
} from "../../../Utils/formHelper";
import { trimObj } from "../../../Utils";
import loginFormFields from "./loginForm.json";
import { saveUserSession } from "./../../../store/actions";
import Service from "./../../../Service";
import ServerlessService from "./../../../Service/ServerlessService";
import ResetPassword from "./resetPassword";
import VerifyOTP from "./verifyOTP";
import Register from "./register";
import NavFooter from "../../navFooter";
import { APP_TARGETS } from "../../../Utils/constants";
import LoadingComponent from "../../../LoadingComponent";

const features = [
    {
        title: "Register",
        image: "live-collaboration.svg",
        text: "Login/Register to get into our platform.",
    },
    {
        title: "Data Security",
        image: "security.svg",
        text: "Securely storing your data in cloud.",
    },
];

const consultantFeatures = [
    {
        title: "Register",
        image: "live-collaboration.svg",
        text: "Login to get into our platform.",
    },
    {
        title: "Data Security",
        image: "security.svg",
        text: "Good to know more.",
    },
];

class EmailBasedLogin extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(loginFormFields, {
            email: "",
            password: "",
        });
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isNewUser: false,
            checked4: false,
            isShowVerifyOtp: false,
            // isLoading: true
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    onLogin = () => {
        console.log("loginapi");

        const formStatus = isFormValid(
            loginFormFields,
            this.formFields.formValidations,
            trimObj(this.state.user)
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let data = this.state.user;
            data.orgCode = this.props.orgCode;

            this.setState({
                isLoading: true,
            });
            const url = `/admin/login`;
            console.log(this.props);
            console.log(data);
            this.serverlessService
                .post(url, data, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        if (res.res.statuscode == 2) {
                            this.setState({
                                userId: res.res.user,
                                isLoading: false,
                                isShowVerifyOtp: true,
                            });
                        } else {
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                () => {
                                    this.props.saveUserSession(res.res);
                                    this.props.history.push("/");
                                }
                            );
                        }
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.res.message,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    // if (e?.response?.status > 400 && e?.response?.status < 500) {
                    //     logOut()
                    // }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };

    componentDidMount() {
        if (this.props.isSessionChecked && this.props.authenticated) {
            this.props.history.push("/");
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.isSessionChecked != this.props.isSessionChecked &&
            this.props.isSessionChecked &&
            this.props.authenticated
        ) {
            this.props.history.push("/");
        }
    }

    render() {
        const {
            user,
            checked4,
            formValidations,
            isNewUser,
            isShowVerifyOtp,
            userId,
            isResetPassword,
        } = this.state;
        return (
            <NavFooter>
                <div style={{ minHeight: "calc(100vh - 0px)" }} className="surface-ground px-4 py-8 md:px-6 lg:px-8">
                    <div className="flex flex-wrap shadow-2">
                        <div className="w-full lg:w-6 px-0 py-4 lg:p-7 bg-primary-50">
                            <Carousel
                                value={
                                    process.env.REACT_APP_TARGET !==
                                        APP_TARGETS.CONSULTANT
                                        ? features
                                        : consultantFeatures
                                }
                                itemTemplate={(feature) => (
                                    <div className="text-center mb-8">
                                        <img
                                            src={`assets/images/blocks/illustration/${feature.image}`}
                                            alt="Image"
                                            className="mb-6 w-6"
                                        />
                                        <div className="mx-auto font-medium text-xl mb-4 text-primary-900">
                                            {feature.title}
                                        </div>
                                        <p className="m-0 text-primary-700 line-height-3">
                                            {feature.text}
                                        </p>
                                    </div>
                                )}
                            />
                        </div>
                        <div className="w-full lg:w-6 p-4 lg:p-7 surface-card">
                            {!isNewUser ? (
                                <>
                                    {isResetPassword ? (
                                        <ResetPassword
                                            showLogin={() => {
                                                this.setState({
                                                    isNewUser: false,
                                                    isResetPassword: false,
                                                });
                                            }}
                                        />
                                    ) : (
                                        <>
                                            {isShowVerifyOtp ? (
                                                <>
                                                    <VerifyOTP
                                                        userId={userId}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flex align-items-center justify-content-between mb-7">
                                                        <span className="text-2xl font-medium text-900">
                                                            Login to{" "}
                                                            {this.props.orgName}
                                                        </span>
                                                    </div>

                                                    <label
                                                        htmlFor="phoneNumber"
                                                        className="block text-900 font-medium mt-3"
                                                    >
                                                        Email
                                                    </label>
                                                    <InputText
                                                        value={user.email}
                                                        className="w-full"
                                                        onChange={(e) => {
                                                            onEmailChange(
                                                                e.target.value,
                                                                "email",
                                                                this,
                                                                loginFormFields,
                                                                user,
                                                                formValidations,
                                                                "user",
                                                                "formValidations"
                                                            );
                                                        }}
                                                    />
                                                    {formValidations &&
                                                        !formValidations.fields[
                                                            "email"
                                                        ].isValid && (
                                                            <p className="p-error">
                                                                {
                                                                    formValidations
                                                                        .fields[
                                                                        "email"
                                                                    ].errorMsg
                                                                }
                                                            </p>
                                                        )}

                                                    <label
                                                        htmlFor="password4"
                                                        className="block text-900 font-medium mt-3"
                                                    >
                                                        Password
                                                    </label>
                                                    <InputText
                                                        value={user.password}
                                                        type="password"
                                                        id="password4"
                                                        autoComplete="new-password"
                                                        className="w-full p-3"
                                                        onChange={(e) => {
                                                            onTextChange(
                                                                e.target.value,
                                                                "password",
                                                                this,
                                                                loginFormFields,
                                                                user,
                                                                formValidations,
                                                                "user",
                                                                "formValidations"
                                                            );
                                                        }}
                                                    />
                                                    {formValidations &&
                                                        !formValidations.fields[
                                                            "password"
                                                        ].isValid && (
                                                            <p className="p-error">
                                                                {
                                                                    formValidations
                                                                        .fields[
                                                                        "password"
                                                                    ].errorMsg
                                                                }
                                                            </p>
                                                        )}

                                                    <div className="flex align-items-center justify-content-between mb-6">
                                                        <span
                                                            onClick={() => {
                                                                this.setState({
                                                                    isResetPassword: true,
                                                                });
                                                            }}
                                                            className="font-medium text-primary hover:text-primary cursor-pointer cursor-pointer transition-colors transition-duration-150 mt-3"
                                                        >
                                                            Forgot password?
                                                        </span>
                                                    </div>
                                                    <Button
                                                        label="Sign In"
                                                        onClick={this.onLogin}
                                                        className="w-full py-3 font-medium"
                                                    />
                                                </>
                                            )}
                                        </>
                                    )}

                                    {process.env.REACT_APP_TARGET !==
                                        APP_TARGETS.CONSULTANT && (
                                            <>
                                                <Divider
                                                    align="center"
                                                    className="my-4"
                                                >
                                                    <span className="text-600 font-normal text-sm">
                                                        OR
                                                    </span>
                                                </Divider>
                                                <div className="mt-6 text-center text-600">
                                                    Don't have an account?{" "}
                                                    <span
                                                        onClick={() => {
                                                            this.setState({
                                                                isNewUser: true,
                                                            });
                                                        }}
                                                        tabIndex="0"
                                                        className="text-primary hover:text-primary cursor-pointer font-medium text-blue-500"
                                                    >
                                                        Sign up
                                                    </span>
                                                </div>
                                            </>
                                        )}
                                </>
                            ) : (
                                <>
                                    <Register
                                        onLoginClick={() => {
                                            this.setState({
                                                isNewUser: false,
                                                isResetPassword: false,
                                                isShowVerifyOtp: false,
                                                user: {
                                                    phoneNumber: "",
                                                    password: "",
                                                },
                                            });
                                        }}
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <Toast
                        ref={(el) => (this.toast = el)}
                        position="bottom-right"
                    />
                    {
                        this.state.isLoading && <LoadingComponent />
                    }
                </div>
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgCode: state?.orgData?.orgCode,
    isLoading: state?.orgData?.isLoading,
    orgName: state?.orgData?.orgName,
});

export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(EmailBasedLogin)));
