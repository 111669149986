import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Authentication from './../../session/index'
import './styles.scss'
import EmailBasedLogin from './emailBased/login';
import { APP_TARGETS } from '../../Utils/constants.js';
// import LoginAndRegisterClient from './../../Login';
class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logindata: {

            },
            activeTab: 1,
        }

    }




    render() {
        console.log(this.props, 'ty')
        return (<>
         {/* {process.env.REACT_APP_TARGET == APP_TARGETS.CHITFUNDS ? <>
                <LoginAndRegisterClient />

            </> : */}
            <div className='login-form'>
                {/* {process.env.REACT_APP_TARGET !== APP_TARGETS.CONSULTANT ?
                  <UserLogin /> : <EmailBasedLogin />
              } */}
                <EmailBasedLogin />
            </div>
            {/* } */}

        </>)
    }
}








const mapStateToProps = state => ({

    orgName: state?.orgData?.orgName,
});


export default connect(mapStateToProps, {
})(Authentication(withRouter(Login)));
