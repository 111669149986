import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";
import AddTable from "./addConsultant";
import { Dialog } from "primereact/dialog";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import AssignContentWriter from "./assignContentWriter";
import ConsultantTracker from "../documentPreperator/consultantTracker";
import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getLearningResources,
    resetLazyLearningResources,
} from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { BreadCrumbHome, logOut } from "../../../Utils";
import { ResponsiveText } from "../../common/ResponsiveText";

class LearningResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
        };
        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Materials" },
        { label: "Learning Resources", url: '/#/admin/learning-resources' },
    ];

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };



    deleteTable = () => {
        this.setState({
            isLoading: true,
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        const url = `/consultants/resources/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.props.resetLazyLearningResources();
                        }
                    );
                    this.toast.show({
                        severity: "success",
                        summary: "Deleted",
                        detail: "Consultant record deleted",
                        life: 3000,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.learningResources?.length) {
            this.props.getLearningResources();
        }
    }

    onGlobalSearch = (e) => {
        this.props.getLearningResources({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getLearningResources({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getLearningResources({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getLearningResources({ lazyParams: event });
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={this.onHide}
                />
                <Button
                    label="Renewal"
                    autoFocus
                    onClick={this.onRenewalClick}
                />
            </div>
        );
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.LEARNING_RESOURCES_EDIT}>
                    <Button
                        onClick={() => {
                            this.props.history.push(
                                `/admin/learning-r/edit/${rowData._id}`
                            );
                        }}
                        tooltip="Edit Resource"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-pencil"
                        className="p-button-rounded mr-3" />

                </Authorize>
                <Authorize permId={PERMISSIONS.LEARNING_RESOURCES_DELETE}>
                    <Button
                        onClick={() =>
                            this.showDeleteQuestionDialog(rowData._id)
                        }
                        severity="danger"
                        tooltip="Delete Resource"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-trash"
                        className="p-button-rounded mr-3" />
                </Authorize>
            </div>
        );
    };


    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );
    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                Learning Resources
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.LEARNING_RESOURCES_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.LEARNING_RESOURCES_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.LEARNING_RESOURCES_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );



        return (
            <>
                <div className='p-5'>
                    <BreadCrumb model={this.items} home={BreadCrumbHome} />
                    <Authorize permId={PERMISSIONS.LEARNING_RESOURCES_ADD}>
                        <Button
                            label="New"
                            icon="pi pi-plus"
                            className="ml-4 mt-3"
                            outlined
                            aria-label="Create"
                            onClick={() =>
                                this.props.history.push(
                                    `/admin/learning-r/add`
                                )
                            }
                        />
                    </Authorize>
                    <Authorize permId={PERMISSIONS.LEARNING_RESOURCES_LIST}>
                        <div className="mt-3 shadow-3 border-round overflow-hidden">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.learningResources}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column field="title" header="Title"></Column>
                                <Column
                                    header="Description"
                                    body={({ description }) => {
                                        return <ResponsiveText text={description} />
                                    }}
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </div>
                    </Authorize>
                </div>

                {this.state.isShowAssignToContentWriter && (
                    <AssignContentWriter
                        selectedCustomer={this.state.selectedCustomer}
                        onCancel={() => {
                            this.setState({
                                isShowAssignToContentWriter: false,
                                selectedCustomer: null,
                            });
                        }}
                        onHide={() => {
                            let thisObj = this;

                            this.setState(
                                {
                                    isShowAssignToContentWriter: false,
                                    selectedCustomer: null,
                                },
                                () => {
                                    thisObj.toast.show({
                                        severity: "success",
                                        summary:
                                            "User assigned to content writer.",
                                        detail: "User assigned successfully",
                                        life: 3000,
                                    });
                                    thisObj.getLearningResources();
                                }
                            );
                        }}
                    />
                )}
                {this.state.isShowConsultantTracker && (
                    <ConsultantTracker
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantTracker: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        draggable={false}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                    >
                        <p>Deleted Resource can't be restored.</p>
                    </Dialog>
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    learningResources: state?.learningResources?.data,
    totalRecords: state?.learningResources?.totalRecords,
    lazyParams: state?.learningResources?.lazyParams,
    isLoading: state?.learningResources?.isLoading,
});

export default BaseComponent(LearningResources, mapStateToProps, {
    getLearningResources,
    resetLazyLearningResources,
});
