import { Button } from 'primereact/button';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateOrgFromClient } from './../../../store/actions';
import Authentication from './../../../session';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import DirectFileUpload from './../../common/directFileUpload';
import { CLOUDFRONT_ENDPOINT } from './../../../Service/config';
import { Toast } from 'primereact/toast';

const getThemes = () => {
    if (localStorage.getItem('themes')) {
        return JSON.parse(localStorage.getItem('themes'));
    } else {
        return [
            {
                name: "Default",
                value: 'default',
                isSelected: true
            },
            {
                name: "Yellow",
                value: 'yellow',
                isSelected: false
            },
            {
                name: "Green",
                value: 'green',
                isSelected: false
            },
            {
                name: "Pink",
                value: 'pink',
                isSelected: false
            },
            {
                name: "Light Blue v1",
                value: 'lightblue-v1',
                isSelected: false
            },
            {
                name: "Light Blue v2",
                value: 'lightblue-v2',
                isSelected: false
            },
            {
                name: "Chocolate",
                value: 'chocolate',
                isSelected: false
            },
        ]
    }
}


class PortalManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoVisible: this.props.logoVisible ? this.props.logoVisible : false,
            brandNameVisible: this.props.brandNameVisible ? this.props.brandNameVisible : false,
            theme: this.props.theme ? this.props.theme : '',
            themes: getThemes(),
            logoSquare: this.props.logoSquare ? this.props.logoSquare : null,
            logoSize: this.props.logoSize ? this.props.logoSize : 'small'
        }

    }



    componentDidUpdate(prevProps) {
        if (prevProps.theme != this.props.theme) {
            this.setState({
                logoVisible: this.props.logoVisible,
                brandNameVisible: this.props.brandNameVisible,
                theme: this.props.theme,
                logoSquare: this.props.logoSquare,
                logoSize: this.props.logoSize
            });
        }
    }


    changeTheme = (theme) => {
        this.setState({
            theme
        });
    }


    changeLogoSize = (logoSize) => {
        this.setState({
            logoSize
        });
    }


    onSubmit = () => {

        const { logoVisible, brandNameVisible, theme, logoSquare, logoSize } = this.state;

        this.props.updateOrgFromClient({ logoVisible, brandNameVisible, theme, logoSquare, logoSize })
    }

    _onLogoSquareSuccess = (fileName, data) => {
        this.addAttachment('logoSquare', fileName, data);
        return;

    }

    addAttachment = (fieldName, fileName, data) => {
        this.setState((prevState) => {
            return {
                [fieldName]: CLOUDFRONT_ENDPOINT + data.Key,
            }
        });
    }

    _onFilsUploadError = (errMsg) => {
        this.toast.show({ severity: 'error', summary: 'Some error', detail: errMsg, life: 3000 });
    }


    _afterFilesUploaded = (files) => {

    }



    render() {
        const { logoVisible, brandNameVisible, theme, logoSquare, logoSize } = this.state;
        return (
            < >
                <div className='sp-main'>
                    <div className='sp-card sp-p20'>


                        <div>
                            <h2 class='h2-defult'>Portal Management</h2>
                            <div className='p-grid'>
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Logo Visible</p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <InputSwitch
                                        onChange={(e) => {
                                            this.setState({
                                                logoVisible: e.value
                                            })
                                        }}
                                        checked={logoVisible} />
                                </div>

                            </div>

                            <div className='p-grid'>
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Brand Name Visible</p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <InputSwitch
                                        onChange={(e) => {
                                            this.setState({
                                                brandNameVisible: e.value
                                            })
                                        }}
                                        checked={brandNameVisible} />
                                </div>

                            </div>
                            <div className='p-grid'>
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Theme</p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <Dropdown value={theme}
                                        options={this.state.themes}
                                        optionLabel='name'
                                        optionValue='value'
                                        onChange={(e) => this.changeTheme(e.value)}


                                        placeholder="Select Template" />
                                </div>

                            </div>
                            <div className='p-grid'>
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Logo</p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <div className='custom-input2 file-attach'>
                                        <DirectFileUpload id={"logosquare"}
                                            multiple={false}
                                            onProgress={this._onProgress}
                                            onSucess={this._onLogoSquareSuccess}
                                            maxFileSize={307200}
                                            onError={this._onFilsUploadError}
                                            accept={'images'}
                                            title={'Images'}
                                            afterFilesUploaded={this._afterFilesUploaded}
                                        >
                                            <span className='outlined-s1 ma-right ma-pointer'>Choose file</span>
                                        </DirectFileUpload>
                                        {
                                            logoSquare && <img src={logoSquare}
                                                className="off-logo-preview" />
                                        }
                                    </div>
                                    <p className='field-alert'>(*Only JPEG/PNG formats are allowed with size below 300KB)</p>
                                </div>

                            </div>
                            <div className='p-grid'>
                                <div className="p-col-4 p-md-2 p-lg-2">
                                    <p className='text-label'>Logo Size</p>
                                </div>
                                <div className="p-col-8 p-md-8 p-lg-8 sp-mt2">
                                    <Dropdown value={logoSize}
                                        options={[{ label: 'Small', value: 'small' },
                                        { label: 'Large', value: 'large' }]}

                                        onChange={(e) => this.changeLogoSize(e.value)}


                                        placeholder="Select Logo Size" />
                                </div>

                            </div>

                            <Button label={`Save`} className=''
                                onClick={this.onSubmit}
                            />
                        </div>
                    </div>
                </div>
                <Toast ref={(el) => this.toast = el} position="bottom-right" />
            </>
        )
    }
}




const mapStateToProps = (state) => ({
    isLoading: state?.orgData?.isLoading,
    smsPricings: state?.orgData?.smsPricings,
    emailPricings: state?.orgData?.emailPricings,
    brandNameVisible: state?.orgData?.brandNameVisible,
    logoVisible: state?.orgData?.logoVisible,
    logoSquare: state?.orgData?.logoSquare,
    logoSize: state?.orgData?.logoSize,
    theme: state?.orgData?.theme
});



export default connect(mapStateToProps, {
    updateOrgFromClient
})(Authentication(PortalManage));

