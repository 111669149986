import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ALL_POLICY_TYPES, BasicLazyParams } from "../../../Utils/constants";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../LoadingComponent";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { BreadCrumb } from "primereact/breadcrumb";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Members from "../policyLinkedMembers";
import { InputText } from "primereact/inputtext";
import PolicyType from "./AddPolicyTypeBase";
import { logOut } from "../../../Utils";
import BaseComponent from "../../common/BaseComponent";
import moment from "moment";

const items = [{ label: "Admin", url: "/#/admin" }, { label: "Policies", url: "/#/admin/policy" }];

const home = {
    icon: "pi pi-home",
    url: "/#/admin",
};


class Policy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editPolicy: null,
            policyId: null,
            isShowPolicy: true,
            isShowPolicyHistory: false,
            editData: null,
            isShowConfirmationDialog: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        };

        this.serverlessService = new ServerlessService();
    }

    deletePolicy = (_id) => {
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId;

        const url = `/chitfunds/policy/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isShowConfirmationDialog: false
                        },
                        () => {
                            this.getPolicies();
                        }
                    );
                } else {

                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Error",
                        detail: res.errMessage || res.res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });

                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onPolicyAddOrUpdate = () => {
        this.setState(
            {
                visible: false,
                editData: null,
            }
            , this.getPolicies
        );
    };

    onHide = () => {
        this.setState({
            visible: false,
            editData: null

        });
    };

    getPolicies = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isLoading: true,
            policies: [],
        });

        const url = `/chitfunds/policy?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {

                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        policies: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getPolicies);
    };

    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    page: event.page,
                    rows: event.rows,
                    first: event.first,
                },
            };
        }, this.getPolicies);
    };

    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getPolicies);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getPolicies);
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className=" p-button-text hover:bg-primary "
                    onClick={() => {
                        this.setState({
                            isShowConfirmationDialog: false
                        })
                    }

                    }
                />
                <Button
                    label="Ok"
                    onClick={() => this.deletePolicy()}
                    autoFocus
                    className=" p-button-primary "
                />
            </div>
        );
    };

    showDeletePolicyDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.CHITS_POLICIES_EDIT}>
                    <div className="col-2">

                        <span
                            onClick={() => {
                                if (rowData.membersCount) {
                                    this.toast.show({
                                        severity: "warn",
                                        summary: "Warning",
                                        detail: 'Policy Can not be edited when members added.',
                                        life: 3000,
                                    });
                                } else {

                                    this.props.history.push(
                                        `/admin/policy/edit/${rowData._id}`
                                    );


                                    // this.setState({
                                    //     editPolicy: rowData,
                                    //     visible: true,
                                    //     isEdit: true,
                                    // })
                                }

                            }}
                            data-pr-tooltip="Edit Policy"
                            data-pr-position="top"
                            className={`p-text policyE${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.policyE${rowData._id}`} />

                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CHITS_POLICIES_VIEW}>
                    <div className="col-2">

                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/policy/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="View Policy"
                            data-pr-position="top"
                            className={`p-text policyE${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.policyE${rowData._id}`} />

                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CHITS_POLICIES_DELETE}>
                    <div className="col-2">

                        <span
                            onClick={() => this.showDeletePolicyDialog(rowData._id)}
                            data-pr-tooltip="Delete Policy"
                            data-pr-position="top"
                            className={`p-text policyD${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.policyD${rowData._id}`} />

                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CHITS_POLICIES_SHOW_POLICY_MEMBERS}>
                    <div className="col-2">

                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/policy-members/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show members"
                            data-pr-position="top"
                            className={`p-text policyS${rowData._id}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip target={`.policyS${rowData._id}`} />

                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.CHITS_POLICIES_SHOW_POLICY_HISTORY}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/policy-history/${rowData._id}`
                                );
                            }
                            }
                            data-pr-tooltip="Policy History"
                            data-pr-position="top"
                            className={`p-text policyH${rowData._id}`}
                        >
                            <i className="pi pi-history" />
                        </span>
                        <Tooltip target={`.policyH${rowData._id}`} />

                    </div>
                </Authorize>
            </div >
        );
    };

    showMembers = (editPolicy) => {
        this.setState({
            isShowAddMembers: true,
            isShowPolicy: false,
            editPolicy,
        });
    };

    showPolicyHistory = (editPolicy) => {
        this.setState({
            isShowAddMembers: false,
            isShowPolicy: false,
            editPolicy,
            isShowPolicyHistory: true,
        });
    };

    onAddMembersCancel = () => {
        this.setState({
            isShowAddMembers: false,
            isShowPolicy: true,
            // policy: null
        })
    };

    componentDidMount() {
        this.getPolicies();
    }
    render() {
        const { policies } = this.state;
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                All Policy
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_POLICIES_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_POLICIES_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_POLICIES_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );
        return (
            <div className='p-3'>
                {this.state.isShowPolicy && (
                    <div >
                        <>
                            <BreadCrumb model={items} home={home} />
                            <Toolbar
                                className="sp-toolbar sp-mt20"

                                left={() => {
                                    return (
                                        <Authorize
                                            permId={PERMISSIONS.CHITS_POLICIES_CREATE}
                                        >
                                            <Button
                                                label="Create Policy"
                                                icon="pi pi-plus"
                                                className="p-button-primary m-3"
                                                aria-label="Create Policy"
                                                onClick={() => {
                                                    this.props.history.push(
                                                        `/admin/policy/add`
                                                    );
                                                }}
                                            />
                                        </Authorize>
                                    );
                                }}
                            ></Toolbar>

                            <Authorize permId={PERMISSIONS.CHITS_POLICIES_LIST}>
                                <div className="surface-card m-4 shadow-2 border-round overflow-hidden">
                                    <DataTable
                                        tableClassName="policy-table"
                                        ref={(el) => (this.dt = el)}
                                        header={header}
                                        value={policies}
                                        size={'small'}
                                        lazy
                                        scrollable
                                        rowHover
                                        onSort={this.onSort}
                                        onPage={this.onPage}
                                        onFilter={this.onFilter}
                                        first={this.state.lazyParams.first}
                                        last={this.state.totalRecords}
                                        rows={this.state.lazyParams.rows}
                                        totalRecords={this.state.totalRecords}
                                        paginator
                                        scrollHeight="400px"
                                        paginatorLeft
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                        rowsPerPageOptions={[
                                            5, 10, 25, 50, 100
                                        ]}
                                        dataKey="_id"
                                        selection={this.state.selectedCustomer}
                                        globalFilter={this.state.globalFilter}
                                        onSelectionChange={(e) =>
                                            this.setState({ selectedCustomer: e.value })
                                        }
                                        selectionMode="single"
                                    >
                                        <Column
                                            // className="width-300"

                                            field="policyNumber"
                                            header="Policy Number"
                                        ></Column>

                                        <Column
                                            // className="width-300"
                                            field="policy.totalAmount"
                                            header="Total Amount"
                                        ></Column>
                                        <Column
                                            //  className="width-300"
                                            field="policy.totalMembers"
                                            header="Total Members"
                                        ></Column>
                                        <Column
                                            field="policy.totalMonths"
                                            header="Total Months"
                                        ></Column>
                                        <Column
                                            header="Policy Type"
                                            body={(rowData) => <>
                                                {
                                                    ALL_POLICY_TYPES[rowData.policyType]
                                                }
                                            </>}
                                        ></Column>
                                        <Column
                                            field="policyType"
                                            header="Start Date"
                                            body={(rowData) => <>
                                                {
                                                    moment(rowData?.policy?.startDate).format('LL')
                                                }
                                            </>}
                                        ></Column>
                                        <Column
                                            field="policyType"
                                            header="End Date"
                                            body={(rowData) => <>
                                                {
                                                    moment(rowData?.policy?.endDate).format('LL')
                                                }
                                            </>}
                                        ></Column>

                                        <Column
                                            style={{ minWidth: '160px' }}
                                            body={(rowData) =>
                                                this.actionsBody(rowData)
                                            }
                                            header="Actions"
                                        ></Column>
                                    </DataTable>
                                </div>

                            </Authorize>
                        </>

                    </div>
                )}
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "25vw" }}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent()}
                        draggable={false}
                    >
                        <p>Deleted Policy can't be restore.</p>
                    </Dialog>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </div>
        );
    }
}

export default BaseComponent(Policy);
