import React, { Component } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import LoadingComponent from "../../../../LoadingComponent";
import ServerlessService from "../../../../Service/ServerlessService";

import {
    getFormFields,
    isFormValid,
    onTextChange,
} from "../../../../Utils/formHelper";
import { logOut, trimObj } from "../../../../Utils";
import ConsultantFields from "./documentEditingRequest.json";
import { InputTextarea } from "primereact/inputtextarea";
import { DOCUMENT_EDITING } from "../../../../Utils/constants";
import { getAdminPrivileges } from "../../../../store/actions";
import BaseComponent from "../../../common/BaseComponent";
import { Toolbar } from "primereact/toolbar";


class EditingRequest extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(
            ConsultantFields,
            this.props.editData || {}
        );
        this.state = {
            consultant: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    addOrUpdate = () => {
        let consultant = trimObj(this.state.consultant);

        const formStatus = isFormValid(
            ConsultantFields,
            this.formFields.formValidations,
            consultant
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            if (this.props.editData) {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/document-editor`;

                this.serverlessService
                    .put(
                        url,
                        {
                            ...consultant,
                            status:
                                DOCUMENT_EDITING.SUBMITTED == consultant.status
                                    ? DOCUMENT_EDITING.REREQUESTED
                                    : DOCUMENT_EDITING.SUBMITTED,
                        },
                        true
                    )
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                () => {
                                    this.props.onRequestedSuccessfully();
                                }
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            } else {
                this.setState({
                    isLoading: true,
                });

                const url = `/consultants/document-editor/add`;
                this.serverlessService
                    .post(
                        url,
                        {
                            ...this.state.consultant,
                            consultant: this.props.consultant._id,
                        },
                        true
                    )
                    .then((res) => {
                        if (res && res.status && res.res.status) {
                            this.setState(
                                {
                                    isLoading: false,
                                },
                                this.props.onRequestedSuccessfully
                            );
                        } else {
                            this.setState({
                                isLoading: false,
                            });

                            this.toast.show({
                                severity: "error",
                                summary: "Some error occurred",
                                detail: res.errMessage,
                                life: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        if (e?.response?.status > 400 && e?.response?.status < 500) {
                            logOut()
                        }
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: e.message,
                            life: 3000,
                        });
                    });
            }
        }
    };

    // getAdminPrivileges = () => {
    //     this.setState({
    //         isLoading: true,
    //         documentEditors: [],
    //     });

    //     const url = `/admin/get-privilege-users`;
    //     this.serverlessService
    //         .post(url, { privilegeType: "isDocumentEditor" }, true)
    //         .then((res) => {
    //             if (res && res.status && res.res.status) {
    //                 this.setState({
    //                     isLoading: false,
    //                     documentEditors: res.res.data,
    //                 });
    //             } else {
    //                 this.setState({
    //                     isLoading: false,
    //                 });
    //                 this.toast.show({
    //                     severity: "error",
    //                     summary: "Some error occurred",
    //                     detail: res.errMessage,
    //                     life: 3000,
    //                 });
    //             }
    //         })
    //         .catch((e) => {
    //             if (e?.response?.status > 400 && e?.response?.status < 500) {
    //                 logOut()
    //             }
    //             this.setState({
    //                 isLoading: false,
    //             });
    //             this.toast.show({
    //                 severity: "error",
    //                 summary: "Some error occurred",
    //                 detail: e.message,
    //                 life: 3000,
    //             });
    //         });
    // };

    componentDidUpdate(prevProps) {
        if (prevProps.editData != this.props.editData) {
            this.setState({
                consultant: this.props.editData
            })
        }
    }

    componentDidMount() {
        if (!this.props.adminPrivileges['isDocumentEditor']) {
            this.props.getAdminPrivileges('isDocumentEditor');
        }
    }


    render() {
        const { consultant, formValidations } = this.state;
        console.log(this.props, 'ssssss')
        return (
            <div className="shadow-3 border-round overflow-hidden p-5 mt-5">
                {/* <div className="text-900 text-3xl font-medium mb-3 ml-2">{consultant?._id ? 'Edit' : 'Add'} Request</div> */}
                <div className="p-grid row">
                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-2">
                            Document Type<span className="sp-required">*</span>
                        </label>
                        <InputText
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "documentType",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.documentType}
                        />
                        {formValidations &&
                            !formValidations.fields["documentType"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["documentType"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-2">
                            Assign To<span className="sp-required">*</span>
                        </label>
                        <Dropdown
                            onChange={(e) => {
                                onTextChange(
                                    e.value,
                                    "assignedTo",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.assignedTo}
                            options={this.props.adminPrivileges?.['isDocumentEditor'] || []}
                            optionValue="_id"
                            optionLabel="name"
                            placeholder="Select a user"
                            className="w-full md:w-14rem"
                        />
                        {formValidations &&
                            !formValidations.fields["assignedTo"].isValid && (
                                <p className="p-error">
                                    {
                                        formValidations.fields["assignedTo"]
                                            .errorMsg
                                    }
                                </p>
                            )}
                    </div>
                </div>
                <div className="p-grid row">
                    <div className="col-12 md:col-4 lg:col-4">
                        <label className="font-bold block mb-2">Notes</label>
                        <span className="sp-required">*</span>
                        <InputTextarea
                            rows={3}
                            onChange={(e) => {
                                onTextChange(
                                    e.target.value,
                                    "notes",
                                    this,
                                    ConsultantFields,
                                    consultant,
                                    formValidations,
                                    "consultant",
                                    "formValidations"
                                );
                            }}
                            value={consultant.notes}
                        />
                        {formValidations &&
                            !formValidations.fields["notes"].isValid && (
                                <p className="p-error">
                                    {formValidations.fields["notes"].errorMsg}
                                </p>
                            )}
                    </div>
                </div>
                <Toolbar
                    className="sp-toolbar sp-mt20"
                    right={() => {
                        return (<>
                            <Button
                                label="Cancel"
                                className="p-button-text hover:bg-primary mr-3"
                                onClick={this.props.onCancel}
                            />
                            <Button
                                label={this.props.editData ? "Update" : "Add"}
                                onClick={this.addOrUpdate}
                                autoFocus
                                className="p-button-primary "
                            />
                        </>)
                    }}
                ></Toolbar>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isLoading: state?.adminPrivileges?.isLoading,
    adminPrivileges: state?.adminPrivileges?.privileges,
});

export default BaseComponent(EditingRequest, mapStateToProps, {
    getAdminPrivileges
});
