import React, { Component } from "react";
import { Toast } from "primereact/toast";
import ServerlessService from "../../../Service/ServerlessService";
import { Button } from "primereact/button";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import "./styles.scss";
import {
    getLearningResources,
    resetLazyLearningResources,
} from "../../../store/actions";
import BaseComponent from "../../common/BaseComponent";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { ResponsiveText } from "../../common/ResponsiveText";
class ResourceSelection extends Component {
    constructor(props) {
        super(props);

        this.state = {

            globalSearch: "",
            selectedReason: "",
            resources: {},
            showAddResource: false,
            selectedRows: [],
            updatedResource: {},
        };
        this.serverlessService = new ServerlessService();
    }

    onGlobalSearch = (e) => {
        this.props.getLearningResources({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getLearningResources({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getLearningResources({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getLearningResources({ lazyParams: event });
    };

    componentDidMount() {


        const { resources } = this.props;
        this.setState({ selectedRows: resources });
    }

    toggleAddResource = () => {
        this.setState((prevState) => ({
            showAddResource: !prevState.showAddResource,
        }));
    };

    createRoleDialogFooter = () => {
        return (
            <div className="mt-4">
                <Button
                    label="Cancel"
                    className="p-button-outlined"
                    onClick={this.toggleAddResource}
                />
                <Button
                    label={"Add"}
                    className="ml-2 mr-2"
                    onClick={() => {
                        this.props.onSelectedResourcesChange(
                            this.state.updatedResource
                        );
                        this.toggleAddResource();
                    }}
                />
            </div>
        );
    };

    onSelectionChange = (e) => {
        this.setState({ selectedRows: e.value }, () => {

            const { resources, day } = this.props;

            const currentResources = resources[day] || [];

            const previousResources = Object.values(resources).reduce(
                (prev, curr) => prev.concat(curr),
                []
            );

            const updatedResourcesForDay = [...e.value];

            const updatedResources = {
                [day]: updatedResourcesForDay,
            };

            this.setState({
                updatedResource: updatedResources,
            });

        });
    };

    handleRemoveResource = (resourceToRemove) => {
        const { resources, day, onDeletedResourcesChange } = this.props;
        const currentResources = resources;
        const indexToRemove = currentResources?.findIndex(
            (resource) => resource._id === resourceToRemove._id
        );

        if (indexToRemove !== -1) {
            const updatedResources = [
                ...currentResources?.slice(0, indexToRemove),
                ...currentResources?.slice(indexToRemove + 1),
            ];
            const updatedResourcesWithDay = {
                [day]: updatedResources,
            };

            onDeletedResourcesChange(updatedResourcesWithDay);
            this.setState({ selectedRows: updatedResources }, () => {
            });
        }
    };

    actionsBody = (rowData) => {
        return (
            <Button
                onClick={() =>
                    this.handleRemoveResource(rowData)
                }
                severity="danger"
                tooltip="Remove Resource"
                tooltipOptions={{
                    position: 'top'
                }}
                icon="pi pi-trash"
                className="p-button-rounded mr-3" />
        );
    };

    render() {
        const {
            showAddResource,
            selectedRows,
        } = this.state;
        const { day, resources } = this.props;

        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Resources</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                        {this.state.showSearch && (
                                            <InputText
                                                placeholder="Search"
                                                className=""
                                                value={this.props.globalSearch}
                                                onChange={this.onGlobalSearch}
                                            />
                                        )}
                                    </li>

                                    <Authorize
                                        permId={
                                            PERMISSIONS.CONSULTANTS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        const header2 = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Day {day.toString().substring(1)} Resources ({resources?.length})</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Button
                                        label="Add"
                                        icon="pi pi-plus"
                                        className="right-auto"
                                        onClick={this.toggleAddResource}
                                    />
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );

        return (
            <div>
                <div className="mt-5 shadow-2 border-round-xl overflow-hidden">
                    <DataTable
                        value={resources}
                        header={header2}
                        scrollHeight="400px"
                        rows={5}
                        totalRecords={resources?.length}
                        paginator
                        paginatorLeft
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[
                            5, 10, 25, 50
                        ]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        scrollable
                        responsiveLayout="scroll"
                        rowHover
                        dataKey="_id"
                    >
                        <Column field="title" header="Title" />
                        <Column
                            header="Description"
                            body={({ description }) => {
                                return <ResponsiveText text={description} />
                            }}
                        ></Column>
                        <Column
                            header="Actions"
                            body={(rowData) =>
                                this.actionsBody(rowData)
                            }
                        ></Column>
                    </DataTable>
                </div>
                {showAddResource && (<Dialog
                    className="w-11"
                    header={<>Add day {day.toString().substring(1)} resources</>}
                    modal
                    footer={this.createRoleDialogFooter}
                    draggable={false}
                    closeOnEscape={false}
                    dismissableMask={false}
                    visible={true}
                    closable={true}
                    onHide={this.toggleAddResource}
                >
                    <div className="mt-3 shadow-3 border-round overflow-hidden">
                        <DataTable
                            value={this.props.learningResources}
                            lazy
                            loading={this.props.isResourceLoading}
                            header={header}
                            scrollHeight="400px"
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.props.lazyParams.first}
                            last={this.props.totalRecords}
                            rows={this.props.lazyParams.rows}
                            totalRecords={this.props.totalRecords}
                            paginator
                            paginatorLeft
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            rowsPerPageOptions={[
                                5, 10, 25, 50, 100
                            ]}
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                            scrollable
                            responsiveLayout="scroll"
                            rowHover
                            dataKey="_id"
                            selection={selectedRows}
                            onSelectionChange={this.onSelectionChange}
                        >
                            <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "3em" }}
                            />
                            <Column field="title" header="Title" />
                            <Column
                                field="resource"
                                header="Resource"
                            />
                            <Column
                                field="description"
                                header="Description"
                            />
                        </DataTable>
                    </div>
                </Dialog>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    learningResources: state?.learningResources?.data,
    totalRecords: state?.learningResources?.totalRecords,
    lazyParams: state?.learningResources?.lazyParams,
    isResourceLoading: state?.learningResources?.isLoading,
});

export default BaseComponent(ResourceSelection, mapStateToProps, {
    getLearningResources,
    resetLazyLearningResources,
});