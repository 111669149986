import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import BaseComponent from "../../common/BaseComponent";
import {
    getLearningPlans,
    resetLazyLearningPlans,
} from "../../../store/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { BreadCrumbHome } from "../../../Utils";
class LearningPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Materials" },
        { label: "Learning Plans", url: '#/admin/learning-plans' },
    ];

    componentDidMount() {
        if (!this.props.learningPlans?.length) {
            this.props.getLearningPlans();
        }
    }

    onGlobalSearch = (e) => {
        this.props.getLearningPlans({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getLearningPlans({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getLearningPlans({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getLearningPlans({ lazyParams: event });
    };

    actionsBody = (rowData) => {
        return (
            <div >
                <Authorize permId={PERMISSIONS.LEARNING_PLANS_EDIT}>
                    <Button
                        onClick={() => {
                            this.props.history.push(
                                `/admin/learning-p/edit/${rowData._id}`
                            );
                        }}
                        tooltip="Edit Plan"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-pencil"
                        className="p-button-rounded mr-3" />
                </Authorize>
                <Authorize permId={PERMISSIONS.LEARNING_PLANS_RESOURCES}>
                    <Button
                        onClick={() => {
                            this.props.history.push(
                                `/admin/learning-plan-details/${rowData._id}`
                            );
                        }}
                        tooltip="Plan Details"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        severity="help"
                        icon="pi pi-folder-open"
                        className="p-button-rounded mr-3" />

                </Authorize>
            </div>
        );
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };

    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Learning Plans</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.LEARNING_PLANS_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.LEARNING_PLANS_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.LEARNING_PLANS_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        return (
            <>
                <div className='p-5'>
                    <BreadCrumb model={this.items} home={BreadCrumbHome} />
                    <Authorize permId={PERMISSIONS.LEARNING_PLANS_ADD}>
                        <Button
                            label="New"
                            icon="pi pi-plus"
                            className="ml-4 mt-3"
                            outlined
                            aria-label="Create"
                            onClick={() =>
                                this.props.history.push(
                                    `/admin/learning-p/add`
                                )
                            }
                        />
                    </Authorize>
                    <Authorize permId={PERMISSIONS.LEARNING_PLANS_LIST}>
                        <div className="mt-3 shadow-3 border-round overflow-hidden">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.learningPlans}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="planName"
                                    header="Plan Name"
                                ></Column>
                                <Column
                                    field="days"
                                    header="Plan Days"
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </div>
                    </Authorize>

                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    learningPlans: state?.learningPlans?.data,
    totalRecords: state?.learningPlans?.totalRecords,
    lazyParams: state?.learningPlans?.lazyParams,
    isLoading: state?.learningPlans?.isLoading,
    consultantAddedOrUpdated: state?.learningPlans?.consultantAddedOrUpdated,
});

export default BaseComponent(LearningPlans, mapStateToProps, {
    getLearningPlans,
    resetLazyLearningPlans,
});
