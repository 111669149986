import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import AddQuestion from "./addQuestion";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";

import { BreadCrumb } from "primereact/breadcrumb";

import BaseComponent from "../../common/BaseComponent";

import {
    getUserQuestions,
    resetLazyUserQuestions,
    deleteUserQuestions,
} from "../../../store/actions";
import { BreadCrumbHome, logOut } from "../../../Utils";
import { ResponsiveText } from "../../common/ResponsiveText";

class UserQuestionBank extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            editQuestion: null,
            isShowConfirmationDialog: false,
            isShowApproveDialog: false,
            isRejected: false,
            showTextarea: false,
            rejectionReason: "",
            globalSearch: "",
            selectedId: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "-createdAt",
                sortOrder: 1,
            },
            isShowApproveDialog: false,
            showRejectReasonInput: false,
            rejectionReason: "",
            selectedStatus: "",
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    items = [
        { label: "Admin", url: "/#/admin" },
        { label: "Question Bank", url: '/#/admin/user-question-bank' },
        { label: "User Questions", url: '/#/admin/user-question-bank' }
    ]

    deleteQuestion = (_id) => {
        this.setState({
            isShowConfirmationDialog: false,
        });

        var _id = this.state.selectedId || _id;

        this.props.deleteUserQuestions({ id: _id });
    };

    getUserQuestions = (api) => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isShowConfirmationDialog: false,
            isLoading: true,
            question: [],
        });

        const url = `/consultants/questionbank/${api}/?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.userQuestions?.length) {
            this.props.getUserQuestions({ status: this.props.selectedStatus });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.consultantAddedOrUpdated !=
            this.props.consultantAddedOrUpdated
        ) {
            this.props.history.push("/admin/user-question-bank");
        }
    }
    onGlobalSearch = (e) => {
        this.props.getUserQuestions({
            globalSearch: e.target.value,
            status: this.props.selectedStatus,
        });
    };

    onPage = (event) => {
        this.props.getUserQuestions({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onSort = (event) => {
        this.props.getUserQuestions({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getUserQuestions({
            lazyParams: event,
            status: this.props.selectedStatus,
        });
    };


    onHide = () => {
        this.setState({
            isShowConfirmationDialog: false,
            editQuestion: null,
            visible: false,
        });
    };

    onQuestionAddOrUpdate = () => {
        this.setState(
            {
                visible: false,
                editQuestion: null,
            },
            this.getUserQuestions
        );
    };

    actionsBody = (rowData) => {
        return (
            <div >
                {(!rowData.hasOwnProperty("isAccept") ||
                    rowData.isAccept === false) && (
                        <Authorize permId={PERMISSIONS.USER_QUESTION_BANK_EDIT}>
                            <Button
                                onClick={() => {
                                    this.props.history.push(
                                        `/admin/user-questionbank/edit/${rowData._id}`,
                                        { fromOperation: "edit" }
                                    );
                                }}
                                tooltip="Edit Question"
                                tooltipOptions={{
                                    position: 'top'
                                }}
                                icon="pi pi-pencil"
                                className="p-button-rounded mr-3" />
                        </Authorize>
                    )}
                <Authorize permId={PERMISSIONS.USER_QUESTION_BANK_DELETE}>
                    <Button
                        onClick={() =>
                            this.showDeleteQuestionDialog(rowData._id)
                        }
                        severity="danger"
                        tooltip="Delete Question"
                        tooltipOptions={{
                            position: 'top'
                        }}
                        icon="pi pi-trash"
                        className="p-button-rounded mr-3" />
                </Authorize>
            </div>
        );
    };

    showDeleteQuestionDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3"
                onClick={this.onHide}
            />
            <Button
                label="Ok"
                onClick={this.deleteQuestion}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };
    onFilterChange = (e) => {
        this.props.getUserQuestions({ status: e.value });
    };

    renderStatus = (rowData) => {
        return (
            <div className="p-d-flex p-ai-center">
                {rowData.hasOwnProperty("isAccept") ? (
                    rowData.isAccept ? (<Button label="Accepted" severity="success" raised />
                    ) : (<Button label="Rejected" severity="danger" raised />
                    )
                ) : (
                    <Button label="Pending" severity="warning" raised />
                )}
            </div>
        );
    };
    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                User Questions
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.USER_QUESTION_BANK_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.USER_QUESTION_BANK_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.USER_QUESTION_BANK_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );
        const statusOptions = [
            { label: "Pending", value: "pending" },
            { label: "Approved", value: "approved" },
            { label: "Rejected", value: "rejected" },
            { label: "All", value: "" },
        ];



        return (
            <>
                <div className='p-5'>
                    <BreadCrumb model={this.items} home={BreadCrumbHome} />
                    <Toolbar
                        className="p-0 bg-primary-reverse border-white"
                        right={() => {
                            return (
                                <Authorize
                                    permId={
                                        PERMISSIONS.USER_QUESTION_BANK_FILTER
                                    }
                                >
                                    <Dropdown
                                        className="w-11rem mt-3 ml-4"
                                        value={
                                            this.props.selectedStatus
                                        }
                                        options={statusOptions}
                                        onChange={this.onFilterChange}
                                        placeholder="Select a status"
                                    />

                                </Authorize>
                            );
                        }}
                        left={() => {
                            return (
                                <Authorize
                                    permId={
                                        PERMISSIONS.USER_QUESTION_BANK_ADD
                                    }
                                >
                                    <Button
                                        label="New"
                                        icon="pi pi-plus"
                                        className="mt-3 ml-4"
                                        outlined
                                        onClick={() =>
                                            this.props.history.push(
                                                `/admin/user-questionbank/add`
                                            )
                                        }
                                    />
                                </Authorize>
                            );
                        }}
                    ></Toolbar>

                    <Authorize permId={PERMISSIONS.USER_QUESTION_BANK_LIST}>
                        <div className="mt-3 shadow-3 border-round overflow-hidden">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                header={header}
                                value={this.props.userQuestions}
                                lazy
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                            >
                                <Column
                                    field="question"
                                    header="Question"
                                    body={({ question }) =>
                                        <ResponsiveText text={question} />
                                    }
                                ></Column>
                                <Column
                                    field="answer"
                                    header="Answer"
                                    body={({ answer }) =>
                                        <ResponsiveText text={answer} />
                                    }
                                ></Column>
                                <Column
                                    field="status"
                                    header="Status"
                                    body={(rowData) =>
                                        this.renderStatus(rowData)
                                    }
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </div>
                    </Authorize>
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
                {this.state.visible && (
                    <AddQuestion
                        onHide={this.onHide}
                        editQuestion={this.state.editQuestion}
                        onQuestionAddOrUpdate={this.onQuestionAddOrUpdate}
                        getUserQuestions={this.getUserQuestions}
                    ></AddQuestion>
                )}
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        draggable={false}
                        className="xl:w-3 md:w-3 lg:w-5 sm:w-9"
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContent}
                    >
                        <p>Deleted Question can't be restore.</p>
                    </Dialog>
                )}
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    userQuestions: state?.userQuestions?.userQuestions,
    totalRecords: state?.userQuestions?.totalRecords,
    lazyParams: state?.userQuestions?.lazyParams,
    isLoading: state?.userQuestions?.isLoading,
    selectedStatus: state?.userQuestions?.selectedStatus,
    consultantAddedOrUpdated: state?.userQuestions?.consultantAddedOrUpdated,
});

export default BaseComponent(UserQuestionBank, mapStateToProps, {
    getUserQuestions,
    resetLazyUserQuestions,
    deleteUserQuestions,
});
