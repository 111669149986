import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import LoadingComponent from "../../../../LoadingComponent";

import { BasicLazyParams } from "../../../../Utils/constants";
import ServerlessService from "../../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../../session/authorize";
import { Card } from "primereact/card";
import BaseComponent from "../../../common/BaseComponent";
import { BreadCrumb } from "primereact/breadcrumb";
import { getAllScreeningCalls } from "../../../../store/actions/adminScreeningCallsActions";

class AdminViewCalls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            consultantsArray: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
            consultantId: "",
        };
        this.serverlessService = new ServerlessService();
    }

    componentDidMount() {
        if (!this.props.adminScreeningCalls?.length) {
            this.props.getAllScreeningCalls();
        }
    }
    onGlobalSearch = (e) => {
        this.props.getAllScreeningCalls({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getAllScreeningCalls({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getAllScreeningCalls({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;

        this.props.getAllScreeningCalls({ lazyParams: event });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize permId={PERMISSIONS.SCREENING_CALLS_VIEW}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/screeningcall/admin/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Screening Call"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id}`} />
                    </div>
                </Authorize>
            </div>
        );
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };

    benchSale = (rowData) => {
        if (
            rowData &&
            rowData.consultantId &&
            rowData.consultantId.marketingAssignedTo &&
            rowData.consultantId.marketingAssignedTo.name
        ) {
            return rowData.consultantId.marketingAssignedTo.name;
        } else {
            return "N/A";
        }
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                Screening Calls
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.SCREENING_CALLS_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.SCREENING_CALLS_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.SCREENING_CALLS_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );
        const items = [{ label: "Admin", url: "/#/admin" }, { label: "Screening Calls" }];

        const home = {
            icon: "pi pi-home",
            url: "/#/admin",
        };
        return (
            <>
                <div className="h-screen overflow-auto ss-data-table">
                    <Card>
                        <BreadCrumb model={items} home={home} />
                        <Authorize permId={PERMISSIONS.SCREENING_CALLS_LIST}>
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.adminScreeningCalls}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({ selectedCustomer: e.value })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="createdBy.name"
                                    header="Consultant Name"
                                ></Column>
                                <Column
                                    field="technology"
                                    header="Technology"
                                ></Column>

                                <Column
                                    field="consultantId.marketingAssignedTo.name"
                                    header="BenchSale Recruiter"
                                    body={(rowData) => this.benchSale(rowData)}
                                ></Column>
                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>
                        </Authorize>
                    </Card>
                </div>

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    adminScreeningCalls: state?.adminScreeningCalls?.adminScreeningCalls,
    totalRecords: state?.adminScreeningCalls?.totalRecords,
    lazyParams: state?.adminScreeningCalls?.lazyParams,
    isLoading: state?.adminScreeningCalls?.isLoading,
});
export default BaseComponent(AdminViewCalls, mapStateToProps, {
    getAllScreeningCalls,
});
