import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { getFormFields, isFormValid, onEmailChange, onNumberChange, onTextChange } from './../../../Utils/formHelper';
import { Button } from 'primereact/button';
import { logOut, trimObj } from './../../../Utils';

import Service from './../../../Service';
import { saveUserSession } from './../../../store/actions'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Authentication from './../../../session/index'
import LoadingComponent from './../../../LoadingComponent';
import ServerlessService from './../../../Service/ServerlessService';
import VerifyOTPChangePassword from './verifyOTPAchangeP';



const loginFormFields = [
    {
        "Type": "email",
        "Label": "Email",
        "FieldName": "email",
        "Required": true
    }
];

class ResetPassword extends React.Component {

    constructor(props) {
        super(props);
        this.formFields = getFormFields(loginFormFields, { email: '' });
        this.state = {
            user: this.formFields.data,
            formValidations: this.formFields.formValidations,
            isNewUser: false,
            isShowVerifyOtp: false,
            isLoading: false
        }

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }



    onLogin = () => {
        const formStatus = isFormValid(loginFormFields, this.formFields.formValidations, trimObj(this.state.user));

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            let data = this.state.user;
            data.orgCode = this.props.orgCode;
            this.setState({
                isLoading: true
            });
            const url = `/admin/reset-password`;
            this.serverlessService.post(url, data, true).then((res) => {
                if (res && res.status && res.res.status) {
                    if (res.res.statuscode == 2) {
                        this.setState({
                            userId: res.res.user,
                            isLoading: false,
                            isShowVerifyOtp: true
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        })
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                    this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.res.message, life: 3000 });
                }
            }).catch(e => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
            })
        }
    }

    componentDidMount() {
        if (this.props.isSessionChecked && this.props.authenticated) {
            this.props.history.push('/')
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSessionChecked != this.props.isSessionChecked && (this.props.isSessionChecked && this.props.authenticated)) {
            this.props.history.push('/')
        }
    }

    render() {
        const { user, formValidations, isShowVerifyOtp, userId } = this.state;
        return (<>
            <div className="flex align-items-center justify-content-between mb-7">
                <span className="text-2xl font-medium text-900">Login to {this.props.orgName}</span>
            </div>
            {isShowVerifyOtp ? <VerifyOTPChangePassword userId={userId} email={user.email} /> : <>
                <div>
                    <label htmlFor="email" className="block text-900 font-medium mb-2">Email</label>
                    <InputText className='w-full' autoComplete="off" value={user.email} onChange={(e) => { onEmailChange(e.target.value, 'email', this, loginFormFields, user, formValidations, 'user', 'formValidations') }} />
                    {formValidations && !formValidations.fields['email'].isValid && <p className="p-error">{formValidations.fields['email'].errorMsg}</p>}
                </div>
                <div>
                    <p className='font-medium my-4 cursor-pointer transition-colors transition-duration-150'>Remember Password? <span className='text-primary' onClick={this.props.showLogin} >Login</span></p>
                </div>
                <Button label="Get OTP" onClick={this.onLogin} className="w-full py-3 font-medium" />
            </>}
            {
                this.state.isLoading && <>
                    <LoadingComponent />
                </>
            }
            <Toast ref={(el) => this.toast = el} position="bottom-right" />
        </>)
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    userType: state?.user?.userType,
    authenticated: state?.user?.authenticated,
    orgCode: state?.orgData?.orgCode,
    isLoading: state?.orgData?.isLoading,
    orgName: state?.orgData?.orgName,
});

export default connect(mapStateToProps, {
    saveUserSession,
})(Authentication(withRouter(ResetPassword)));
