import moment from "moment";
import { Dialog } from "primereact/dialog";
import React, { Component } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FeeDays, PaymentModes } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import Authorize, { PERMISSIONS } from "../../../session/authorize";
import {
    getFormFields,
    isFormValid,
    onTextChange,
    onDropDownChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";
import _TableFields from "../tables/renewalTable.json";
import _AssignTableFields from "../tables/assignTable.json";

let AssignTableFields = _.cloneDeep(_AssignTableFields);
let TableFields = _.cloneDeep(_TableFields);

class TableDetails extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(TableFields, this.props.editData || {});
        this.reAssignFormFields = getFormFields(
            AssignTableFields,
            this.props.editData || {}
        );
        this.state = {
            assignData: {},
            isShowRenewel: false,
            renewalData: null,
            rowDataOfTable: null,
            isVisible: true,
            tableData: this.formFields.data,
            reAssignData: this.reAssignFormFields.data,
            formValidations: this.formFields.formValidations,
            reAssignFormValidations: this.reAssignFormFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }

    unAssignTable = () => {
        this.setState({
            isLoading: true,
            isVisible: false,
        });

        let _id = this.props?.table?._id;
        const url = `/co-space/tables/unassign-user`;
        this.serverlessService
            .put(url, { _id }, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.props.onSuccess();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });

                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    showAssign = () => {
        this.setState({
            table: this.props?.table,
            isShowAssign: true,
            isShowRenewel: false,
            table: this.props?.table,
        });
    };

    onPaymentUpdate = () => {
        const { tableData } = this.state;

        TableFields = TableFields.map((field) => {
            if (
                field.FieldName == "paymentReferenceNumber" ||
                field.FieldName == "paymentGateway"
            ) {
                field.Required = tableData.paymentMode == "online";
            }
            return field;
        });
    };

    reAssignOnPaymentUpdate = () => {
        const { reAssignData } = this.state;
        AssignTableFields = AssignTableFields.map((field) => {
            if (
                field.FieldName == "paymentReferenceNumber" ||
                field.FieldName == "paymentGateway"
            ) {
                field.Required = reAssignData.paymentMode == "online";
            }
            return field;
        });
    };

    onDaysRSelect = (e) => {
        let fee = this.state.rowDataOfTable?.feeType?.fees?.find(
            (a) => a.id === e.value
        )?.fee;

        this.setState((prevState) => {
            return {
                tableData: {
                    ...prevState.tableData,
                    days: e.value,
                    fee: fee,
                },
            };
        });
    };

    onRenewalClick = () => {
        let tableData = trimObj(this.state.tableData);
        const formStatus = isFormValid(
            TableFields,
            this.formFields.formValidations,
            tableData
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            this.setState({
                isLoading: true,
                isVisible: false,
            });

            let payload = {
                ...this.state.tableData,
                assignTable: this.state.rowDataOfTable?._id,
            };

            const url = `/co-space/tables/renewal-table`;
            this.serverlessService
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                                isShowRenewal: false,
                                rowDataOfTable: null,
                                renewalData: null,
                            },
                            () => {
                                this.props.onSuccess();
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };

    showRenewel = () => {
        this.setState({
            rowDataOfTable: this.props?.table,
            isShowRenewel: true,
            isShowAssign: false,
        });
    };

    getCustomers = () => {
        this.setState({
            isLoading: true,
            customers: [],
        });

        const url = `/co-space/customers/unassigned`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        customers: res.res.data,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    componentDidMount() {
        this.getCustomers();
    }

    onDaysSelect = (e) => {
        let fee = this.state.table?.feeType?.fees?.find(
            (a) => a.id === e.value
        )?.fee;

        this.setState((prevState) => {
            return {
                reAssignData: {
                    ...prevState.reAssignData,
                    days: e.value,
                    fee: fee,
                },
            };
        });
    };

    onAssignClick = () => {
        this.setState({
            isLoading: true,
        });

        let payload = {
            ...this.state.assignData,
            assignTable: this.state.table._id,
        };

        const url = `/co-space/tables/assign-user`;
        this.serverlessService
            .post(url, payload, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            table: null,
                            isShowAssign: false,
                        },
                        () => {
                            this.props.onSuccess();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onReAssignClick = () => {
        let reAssignData = trimObj(this.state.reAssignData);
        const formStatus = isFormValid(
            AssignTableFields,
            this.reAssignFormFields.formValidations,
            reAssignData
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                reAssignFormValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            this.setState({
                isLoading: true,
                isVisible: false,
            });

            let payload = {
                ...this.state.reAssignData,
                assignTable: this.props.table._id,
            };

            const url = `/co-space/tables/assign-user`;
            this.serverlessService
                .post(url, payload, true)
                .then((res) => {
                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                            },
                            () => {
                                this.props.onSuccess();
                            }
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });
                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };

    footerContent = () => {
        return (
            <div>
                <Authorize permId={PERMISSIONS.DASHBOARD_RENEWAL}>
                    <Button
                        label="Renewel"
                        onClick={() => this.showRenewel()}
                        className=" p-button-text hover:bg-primary"
                    />
                </Authorize>

                <Authorize permId={PERMISSIONS.DASHBOARD_REASSIGN}>
                    <Button
                        label=" Re-assign"
                        onClick={() => this.showAssign()}
                        className=" p-button-text hover:bg-primary"
                    />
                </Authorize>

                <Authorize permId={PERMISSIONS.DASHBOARD_UNASSIGN}>
                    <Button
                        label="Unassign"
                        onClick={() => this.unAssignTable()}
                        className=" p-button-primary "
                    />
                </Authorize>
            </div>
        );
    };

    render() {
        const { table } = this.props;
        const {
            tableData,
            formValidations,
            reAssignData,
            reAssignFormValidations,
        } = this.state;

        return (
            <>
                <Dialog
                    header="Table Details"
                    visible={this.state.isVisible}
                    style={{ width: "65vw" }}
                    onHide={this.props.onHide}
                    footer={this.footerContent(table)}
                    draggable={false}
                >
                    <div className="p-grid row pl-6 pr-6">
                        <p className="col-4">
                            <b>Table : </b> {table?.name}
                        </p>
                        <p className="col-4">
                            <b>Customer : </b> {table?.assignedTo?.name}
                        </p>
                        <p className="col-4">
                            <b>Adhar : </b> {table?.assignedTo?.adhar}
                        </p>
                        <p className="col-4">
                            <b>Current Address : </b>{" "}
                            {table?.assignedTo?.currentAddress}
                        </p>
                        <p className="col-4">
                            <b>Email : </b> {table?.assignedTo?.email}
                        </p>
                        <p className="col-4">
                            <b>Father Name : </b>{" "}
                            {table?.assignedTo?.fatherName}
                        </p>
                        <p className="col-4">
                            <b>Mobile : </b> {table?.assignedTo?.mobile}
                        </p>
                        <p className="col-4">
                            <b>Permanent Address : </b>{" "}
                            {table?.assignedTo?.permanentAddress}
                        </p>
                        <p className="col-4">
                            <b>Days : </b> {table?.transaction?.days}
                        </p>
                        <p className="col-4">
                            <b>Fee : </b> {table?.transaction?.fee}
                        </p>
                        <p className="col-4">
                            <b>Payment Mode : </b>{" "}
                            {table?.transaction?.paymentMode}
                        </p>
                        <p className="col-4">
                            <b>Valid Till : </b>
                            {moment(table?.transaction?.validTill).format(
                                "MM/DD/YYYY"
                            )}
                        </p>
                    </div>

                    {this.state.isShowAssign && (
                        <>
                            <div className="flex w-full relative align-items-center justify-content-start my-3 px-4">
                                <div className="border-top-1 border-300 top-50 left-0 absolute w-full"></div>
                                <div className="px-2 z-1 surface-0 flex align-items-center"></div>
                            </div>
                            <span className="text-1000 font-medium text-2xl mt-6 pl-5">
                                Re-Assign
                            </span>
                            <div className="col-12 p-grid row pl-6 pr-6">
                                <div className="col-4">
                                    <label className="font-bold block">
                                        User
                                    </label>
                                    <Dropdown
                                        onChange={(e) => {
                                            onDropDownChange(
                                                e.value,
                                                "assignedTo",
                                                this,
                                                AssignTableFields,
                                                reAssignData,
                                                reAssignFormValidations,
                                                "reAssignData",
                                                "reAssignFormValidations"
                                            );
                                        }}
                                        value={
                                            this.state.reAssignData.assignedTo
                                        }
                                        options={this.state.customers}
                                        optionValue="_id"
                                        optionLabel="name"
                                        placeholder="Select a user"
                                        className="w-full md:w-14rem"
                                    />
                                    {reAssignFormValidations &&
                                        !reAssignFormValidations.fields[
                                            "assignedTo"
                                        ].isValid && (
                                            <p className="p-error">
                                                {
                                                    reAssignFormValidations
                                                        .fields["assignedTo"]
                                                        .errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="col-4">
                                    <label className="font-bold block mb-2">
                                        Days
                                    </label>
                                    <Dropdown
                                        onChange={(e) => {
                                            onDropDownChange(
                                                e.value,
                                                "days",
                                                this,
                                                AssignTableFields,
                                                reAssignData,
                                                reAssignFormValidations,
                                                "reAssignData",
                                                "reAssignFormValidations",
                                                () => {
                                                    this.setState(
                                                        (prevState) => {
                                                            return {
                                                                assignData: {
                                                                    ...prevState.assignData,
                                                                    fee: this.onDaysSelect(
                                                                        e
                                                                    ),
                                                                },
                                                            };
                                                        }
                                                    );
                                                }
                                            );
                                        }}
                                        value={this.state.reAssignData.days}
                                        options={FeeDays}
                                        optionValue="id"
                                        optionLabel="days"
                                        placeholder="Select a day"
                                        className="w-full md:w-14rem"
                                    />
                                    {reAssignFormValidations &&
                                        !reAssignFormValidations.fields["days"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    reAssignFormValidations
                                                        .fields["days"].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="col-4">
                                    <label className="font-bold block mb-2">
                                        Fee
                                    </label>

                                    <InputNumber
                                        value={this.state.reAssignData.fee}
                                        readOnly
                                    />
                                </div>

                                <div className="col-4">
                                    <label className="font-bold block mb-2">
                                        Payment Mode
                                    </label>
                                    <Dropdown
                                        onChange={(e) => {
                                            onDropDownChange(
                                                e.value,
                                                "paymentMode",
                                                this,
                                                AssignTableFields,
                                                reAssignData,
                                                reAssignFormValidations,
                                                "reAssignData",
                                                "reAssignFormValidations",
                                                this.reAssignOnPaymentUpdate
                                            );
                                        }}
                                        value={
                                            this.state.reAssignData.paymentMode
                                        }
                                        options={PaymentModes}
                                        optionValue="id"
                                        optionLabel="type"
                                        placeholder="Select a payment type"
                                        className="w-full md:w-14rem"
                                    />
                                    {reAssignFormValidations &&
                                        !reAssignFormValidations.fields[
                                            "paymentMode"
                                        ].isValid && (
                                            <p className="p-error">
                                                {
                                                    reAssignFormValidations
                                                        .fields["paymentMode"]
                                                        .errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                {this.state.reAssignData.paymentMode ==
                                    "online" && (
                                        <div className="col-4">
                                            <label className="font-bold block mb-2">
                                                Payment reference Number
                                            </label>
                                            <InputText
                                                onChange={(e) => {
                                                    onTextChange(
                                                        e.target.value,
                                                        "paymentReferenceNumber",
                                                        this,
                                                        AssignTableFields,
                                                        reAssignData,
                                                        reAssignFormValidations,
                                                        "reAssignData",
                                                        "reAssignFormValidations"
                                                    );
                                                }}
                                            ></InputText>
                                            {reAssignFormValidations &&
                                                !reAssignFormValidations.fields[
                                                    "paymentReferenceNumber"
                                                ].isValid && (
                                                    <p className="p-error">
                                                        {
                                                            reAssignFormValidations
                                                                .fields[
                                                                "paymentReferenceNumber"
                                                            ].errorMsg
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    )}
                                {this.state.reAssignData.paymentMode ==
                                    "online" && (
                                        <div className="col-4">
                                            <label className="font-bold block mb-2">
                                                Payment Gateway
                                            </label>
                                            <InputText
                                                onChange={(e) => {
                                                    onTextChange(
                                                        e.target.value,
                                                        "paymentGateway",
                                                        this,
                                                        AssignTableFields,
                                                        reAssignData,
                                                        reAssignFormValidations,
                                                        "reAssignData",
                                                        "reAssignFormValidations"
                                                    );
                                                }}
                                            ></InputText>
                                            {reAssignFormValidations &&
                                                !reAssignFormValidations.fields[
                                                    "paymentGateway"
                                                ].isValid && (
                                                    <p className="p-error">
                                                        {
                                                            reAssignFormValidations
                                                                .fields[
                                                                "paymentGateway"
                                                            ].errorMsg
                                                        }
                                                    </p>
                                                )}
                                        </div>
                                    )}
                                <div className="col-4 m-5">
                                    <Button
                                        label="Re-Assign"
                                        onClick={() => this.onReAssignClick()}
                                        className="p-button-primary "
                                    ></Button>
                                </div>
                            </div>
                        </>
                    )}
                    {this.state.isShowRenewel && (
                        <>
                            <div className="flex w-full relative align-items-center justify-content-start my-3 px-4">
                                <div className="border-top-1 border-300 top-50 left-0 absolute w-full"></div>
                                <div className="px-2 z-1 surface-0 flex align-items-center"></div>
                            </div>
                            <span className="text-1000 font-medium text-2xl mt-6  pl-5">
                                Renewal
                            </span>
                            <div className="p-grid row pl-6 pr-6">
                                <div className="col-4">
                                    <label className="font-bold block mb-2">
                                        Days
                                    </label>
                                    <Dropdown
                                        onChange={(e) => {
                                            onDropDownChange(
                                                e.value,
                                                "days",
                                                this,
                                                TableFields,
                                                tableData,
                                                formValidations,
                                                "tableData",
                                                "formValidations"
                                            );
                                            this.setState((prevState) => {
                                                return {
                                                    renewalData: {
                                                        ...prevState.renewalData,
                                                        days: e.value,
                                                        fee: this.onDaysRSelect(
                                                            e
                                                        ),
                                                    },
                                                };
                                            });
                                        }}
                                        value={tableData.days}
                                        options={FeeDays}
                                        optionValue="id"
                                        optionLabel="days"
                                        placeholder="Select a day"
                                        className="w-full md:w-14rem"
                                    />
                                    {formValidations &&
                                        !formValidations.fields["days"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "days"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>

                                <div className="col-4">
                                    <label className="font-bold block mb-2">
                                        Fee
                                    </label>
                                    <InputNumber
                                        value={tableData.fee}
                                        readOnly
                                    />
                                </div>

                                <div className="col-4">
                                    <label className="font-bold block mb-2">
                                        Payment Mode
                                    </label>
                                    <Dropdown
                                        onChange={(e) => {
                                            onDropDownChange(
                                                e.value,
                                                "paymentMode",
                                                this,
                                                TableFields,
                                                tableData,
                                                formValidations,
                                                "tableData",
                                                "formValidations",
                                                this.onPaymentUpdate
                                            );
                                            this.setState((prevState) => {
                                                return {
                                                    renewalData: {
                                                        ...prevState.renewalData,
                                                        paymentMode: e.value,
                                                    },
                                                };
                                            });
                                        }}
                                        value={tableData.paymentMode}
                                        options={PaymentModes}
                                        optionValue="id"
                                        optionLabel="type"
                                        placeholder="Select a payment type"
                                        className="w-full md:w-14rem"
                                    />
                                    {formValidations &&
                                        !formValidations.fields["paymentMode"]
                                            .isValid && (
                                            <p className="p-error">
                                                {
                                                    formValidations.fields[
                                                        "paymentMode"
                                                    ].errorMsg
                                                }
                                            </p>
                                        )}
                                </div>
                                {this.state.tableData.paymentMode ==
                                    "online" && (
                                        <>
                                            <div className="col-4">
                                                <label className="font-bold block mb-2">
                                                    Payment Reference Number
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "paymentReferenceNumber",
                                                            this,
                                                            TableFields,
                                                            tableData,
                                                            formValidations,
                                                            "tableData",
                                                            "formValidations"
                                                        );
                                                        this.setState(
                                                            (prevState) => {
                                                                return {
                                                                    renewalData: {
                                                                        ...prevState.renewalData,
                                                                        paymentReferenceNumber:
                                                                            e.value,
                                                                    },
                                                                };
                                                            }
                                                        );
                                                    }}
                                                ></InputText>
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "paymentReferenceNumber"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "paymentReferenceNumber"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                            <div className="col-4">
                                                <label className="font-bold block mb-2">
                                                    Payment Gateway
                                                </label>
                                                <InputText
                                                    onChange={(e) => {
                                                        onTextChange(
                                                            e.target.value,
                                                            "paymentGateway",
                                                            this,
                                                            TableFields,
                                                            tableData,
                                                            formValidations,
                                                            "tableData",
                                                            "formValidations"
                                                        );

                                                        this.setState(
                                                            (prevState) => {
                                                                return {
                                                                    renewalData: {
                                                                        ...prevState.renewalData,
                                                                        paymentGateway:
                                                                            e.target
                                                                                .value,
                                                                    },
                                                                };
                                                            }
                                                        );
                                                    }}
                                                ></InputText>
                                                {formValidations &&
                                                    !formValidations.fields[
                                                        "paymentGateway"
                                                    ].isValid && (
                                                        <p className="p-error">
                                                            {
                                                                formValidations
                                                                    .fields[
                                                                    "paymentGateway"
                                                                ].errorMsg
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </>
                                    )}
                                <div className="col-4 m-5">
                                    <Button
                                        label="Renewal"
                                        onClick={() => this.onRenewalClick()}
                                        className="p-button-primary "
                                    ></Button>
                                </div>
                            </div>
                        </>
                    )}
                </Dialog>
            </>
        );
    }
}

export default TableDetails;
