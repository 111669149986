import React, { Component } from 'react'
import ServerlessService from '../../../../Service/ServerlessService';
import EditingRequest from './editingRequest';
import { Button } from 'primereact/button';
import { BasicLazyParams, DOCUMENT_EDITING } from "../../../../Utils/constants";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Authorize, { PERMISSIONS } from '../../../../session/authorize';
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import LoadingComponent from '../../../../LoadingComponent';
import { logOut } from '../../../../Utils';

export default class DocumentsEditingRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowSendRequest: false,
            globalSearch: "",
            editData: null,
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
        }
        this.serverlessService = new ServerlessService();

    }
    componentDidMount() {
        this.getRequests();
    }



    deleteTable = () => {
        this.setState({
            isLoading: true,
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        const url = `/consultants/document-editor/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getRequests();
                        }
                    );
                    this.toast.show({
                        message: res.message,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    changeStatus = () => {
        this.setState({
            isLoading: true
        });

        const { editData } = this.state;

        const url = `/consultants/document-editor/`;
        this.serverlessService.put(url, {
            _id: editData._id,
            status: DOCUMENT_EDITING.COMPLETED
        }, true).then((res) => {
            if (res && res.status && res.res.status) {
                this.setState({
                    isLoading: false
                }, this.acceptDocumentEdited)

            } else {

                this.setState({
                    isLoading: false
                });
                this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: res.res.message, life: 3000 });
            }

        }).catch(e => {
            if (e?.response?.status > 400 && e?.response?.status < 500) {
                logOut()
            }
            this.setState({
                isLoading: false
            });
            this.toast.show({ severity: 'error', summary: 'Some error occurred', detail: e.message, life: 3000 });
        })

    }

    acceptDocumentEdited = () => {
        this.setState({
            isLoading: true,
            isShowAcceptDialog: false,
        });
        const { editData } = this.state;

        const files = [...this.props.consultant.files || [], {
            name: editData.documentType,
            url: editData.documentURL,
        }]
        const url = `/consultants/consultant`;
        this.serverlessService
            .put(url, {
                _id: this.props.consultant._id,
                files
            },

                true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            //  this.props.onDocumentAccepted(files);
                            this.getRequests()
                        }
                    );
                    this.toast.show({
                        message: res.message,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getRequests = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/document-editor/${this.props.consultant._id}?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };


    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                        <span
                            onClick={() => {
                                const _rowData = { ...rowData };
                                _rowData.assignedTo = _rowData.assignedTo._id;
                                this.setState({
                                    editData: _rowData,
                                    isShowSendRequest: true,
                                    isEdit: true,
                                });
                            }}
                            data-pr-tooltip={rowData.status == DOCUMENT_EDITING.SUBMITTED ? 'Request More Changes' : "Edit Request"}
                            data-pr-position="top"
                            className={`p-text tableEdit${rowData._id}`}
                        >
                            <i className="pi pi-pencil" />
                        </span>
                        <Tooltip target={`.tableEdit${rowData._id}`} />
                    </Authorize>
                </div>
                {
                    rowData.status == DOCUMENT_EDITING.SUBMITTED && <div className="col-2">
                        <Authorize permId={PERMISSIONS.CONSULTANTS_DELETE}>
                            <span
                                onClick={() => {
                                    if (this.state.isEdit) {
                                        this.toast.show({
                                            severity: "warn",
                                            summary: "Cancel/update the record before doing this action.",
                                            life: 3000,
                                        });
                                    } else {
                                        this.showAcceptDocumentDialog(rowData)
                                    }
                                }
                                }
                                data-pr-tooltip="Accept Document"
                                data-pr-position="top"
                                className={`p-text accept${rowData._id}`}
                            >
                                <i className="pi pi-check-circle" />
                            </span>
                            <Tooltip target={`.accept${rowData._id}`} />
                        </Authorize>
                    </div>
                }
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.CONSULTANTS_DELETE}>
                        <span
                            onClick={() => {

                                if (this.state.isEdit) {
                                    this.toast.show({
                                        severity: "warn",
                                        summary: "Cancel/update the record before doing this action.",
                                        life: 3000,
                                    });
                                } else {
                                    this.showDeleteTableDialog(rowData._id)
                                }
                            }
                            }
                            data-pr-tooltip="Delete Request"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id}`}
                        >
                            <i className="pi pi-trash" />
                        </span>
                        <Tooltip target={`.tableDelete${rowData._id}`} />
                    </Authorize>
                </div>

            </div>
        );
    };

    showAcceptDocumentDialog = (id) => {
        this.setState({
            isShowAcceptDialog: true,
            editData: id,
        });
    };

    onCloseAcceptDocumentConfirmation = () => {
        this.setState({
            isShowAcceptDialog: false,
        });
    };


    footerAcceptDocumentAcceptDialog = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3 "
                onClick={() => {
                    this.setState({
                        isShowAcceptDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.changeStatus}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };


    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary mr-3 "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );


    previewBody = (rowData) => {
        return (<>
            {
                rowData.documentURL ? <Authorize permId={PERMISSIONS.CONSULTANTS_EDIT}>
                    <span
                        onClick={() => {
                            const urlToOpen = rowData.documentURL;

                            window.open(urlToOpen, '_blank');
                        }}
                        data-pr-tooltip="Preview Doc"
                        data-pr-position="top"
                        className={`p-text tableEdit${rowData._id}`}
                    >
                        Preview <i className="pi pi-file" />
                    </span>
                    <Tooltip target={`.tableEdit${rowData._id}`} />
                </Authorize> :
                    'NA'
            }



        </>);
    };



    render() {
        return (
            <div className=''>
                {
                    !this.state.isShowSendRequest && <Button
                        type="button"
                        label="New"
                        icon="pi pi-plus"
                        outlined
                        className="p-button-primary mt-3 mb-4"
                        onClick={() => {
                            this.setState({
                                isShowSendRequest: true
                            });
                        }} />
                }

                {
                    this.state.isShowSendRequest && <EditingRequest
                        editData={this.state.editData}
                        consultant={this.props.consultant}
                        onRequestedSuccessfully={() => {
                            this.setState({
                                isShowSendRequest: false,

                            }, this.getRequests)
                        }}
                        onCancel={() => {
                            this.setState({
                                isShowSendRequest: false,
                                editData: '',
                                isEdit: false
                            })
                        }}
                    />
                }

                <div className="mt-4 shadow-3 border-round overflow-hidden">
                    <DataTable

                        value={this.state.consultants}
                        lazy
                        scrollHeight="400px"

                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[
                            5, 10, 25, 50, 100
                        ]}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        scrollable
                        responsiveLayout="scroll"
                        rowHover
                        dataKey="_id"

                        selectionMode="single"
                    >
                        <Column
                            field="documentType"
                            header="Type Of Document"
                        ></Column>
                        <Column
                            field="notes"
                            header="Notes"
                        ></Column>
                        <Column
                            field="status"
                            header="Status"
                        ></Column>
                        <Column
                            field="assignedTo.name"
                            header="Assigned"
                        ></Column>
                        <Column
                            field="documentURL"
                            header="Preview Doc"
                            body={(rowData) =>
                                this.previewBody(rowData)
                            }
                        ></Column>

                        <Column
                            header="Actions"
                            body={(rowData) =>
                                this.actionsBody(rowData)
                            }
                        ></Column>





                    </DataTable>
                </div>
                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        draggable={false}
                        onHide={this.onCloseConfirmation}
                        footer={this.footerContentforDelete}
                    >
                        <p>Deleted Request Can't be Restored.</p>
                    </Dialog>
                )}


                {this.state.isShowAcceptDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={true}
                        draggable={false}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseAcceptDocumentConfirmation}
                        footer={this.footerAcceptDocumentAcceptDialog}
                    >
                        <p>Would you like to change document status is completed. and the document will be saved to consultant database.</p>
                    </Dialog>
                )}


                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div >

        )
    }
}
