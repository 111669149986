import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import RegistrationFeeFields from "./registration.json";
import ServerlessService from "../../../Service/ServerlessService";

import {
    getFormFields,
    isFormValid,
    onNumberChange,
} from "../../../Utils/formHelper";
import { logOut, trimObj } from "../../../Utils";

class PortalManageNew extends Component {
    constructor(props) {
        super(props);
        this.formFields = getFormFields(RegistrationFeeFields, {});
        this.state = {
            registrationFee: this.formFields.data,
            formValidations: this.formFields.formValidations,
        };
        this.serverlessService = new ServerlessService();
    }


    addOrEdit = () => {
        let registrationFee = trimObj(this.state.registrationFee);

        const formStatus = isFormValid(
            RegistrationFeeFields,
            this.state.formValidations,
            registrationFee
        );

        let isCompleteFormValid = true;
        if (!formStatus.formValidations.isFormValid) {
            this.setState({
                formValidations: formStatus.formValidations,
            });
            isCompleteFormValid = false;
        }

        if (isCompleteFormValid) {
            var url = `/registration-fee/save`;

            if (this.state.registrationFee._id) {
                url = `/registration-fee/update`;
            }

            this.serverlessService
                .post(url, this.state.registrationFee, true)
                .then((res) => {

                    if (res && res.status && res.res.status) {
                        this.setState(
                            {
                                isLoading: false,
                            },
                            this.getRegistrationFee
                        );
                    } else {
                        this.setState({
                            isLoading: false,
                        });

                        this.toast.show({
                            severity: "error",
                            summary: "Some error occurred",
                            detail: res.errMessage,
                            life: 3000,
                        });
                    }
                })
                .catch((e) => {
                    if (e?.response?.status > 400 && e?.response?.status < 500) {
                        logOut()
                    }
                    console.log(e);
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: e.message,
                        life: 3000,
                    });
                });
        }
    };

    getRegistrationFee = () => {
        this.setState({
            isLoading: true,
        });

        const url = `/registration-fee`;

        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        registrationFee: res.res.data[0],
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        this.getRegistrationFee();
    }

    render() {
        const { registrationFee, formValidations } = this.state;

        return (
            <>
                <div className="surface-ground">
                    <div className="px-6 py-5">
                        <div className="surface-card p-4 shadow-2 border-round">
                            <div className="font-medium text-3xl text-900 mb-3">
                                Registration Fee
                            </div>
                            <ul className="list-none p-0 m-0 border-top-1 border-300">
                                <li className="flex align-items-center py-3 px-2 flex-wrap">
                                    <div className="text-500 w-full md:w-2 font-medium">
                                        Basic Fee
                                    </div>
                                    <div className="text-900 w-full md:w-10 line-height-3">
                                        Rs. <InputText
                                            onChange={(e) => {
                                                onNumberChange(
                                                    e.target.value,
                                                    "basicFee",
                                                    this,
                                                    RegistrationFeeFields,
                                                    registrationFee,
                                                    formValidations,
                                                    "registrationFee",
                                                    "formValidations"
                                                );
                                            }}
                                            value={registrationFee?.basicFee}
                                        />

                                        {formValidations &&
                                            !formValidations.fields["basicFee"]
                                                .isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "basicFee"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </li>
                                <li className="flex align-items-center py-3 px-2 flex-wrap">
                                    <div className="text-500 w-full md:w-2 font-medium">
                                        Premium Fee
                                    </div>
                                    <div className="text-900 w-full md:w-10 line-height-3">
                                        Rs.<InputText
                                            onChange={(e) => {
                                                onNumberChange(
                                                    e.target.value,
                                                    "premiumFee",
                                                    this,
                                                    RegistrationFeeFields,
                                                    registrationFee,
                                                    formValidations,
                                                    "registrationFee",
                                                    "formValidations"
                                                );
                                            }}
                                            value={registrationFee?.premiumFee}
                                        />

                                        {formValidations &&
                                            !formValidations.fields[
                                                "premiumFee"
                                            ].isValid && (
                                                <p className="p-error">
                                                    {
                                                        formValidations.fields[
                                                            "premiumFee"
                                                        ].errorMsg
                                                    }
                                                </p>
                                            )}
                                    </div>
                                </li>
                                <Button
                                    label={
                                        this.state.registrationFee?._id
                                            ? "Update"
                                            : "Save"
                                    }
                                    onClick={() => this.addOrEdit()}
                                ></Button>
                            </ul>
                            <br />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PortalManageNew;
