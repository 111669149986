import { ACTIONS } from '../actionTypes';
import axios from 'axios';
import { SERVERLESS_URL } from '../../Service/config';
import { logOut, removeNullUndefinedKeys } from '../../Utils';

const dispatchRequestFaq = data => ({
    type: ACTIONS.REQUEST_ADMIN_PRIVILEGES,
    data
});

const dispatchReceivedFaq = (data) => ({
    type: ACTIONS.RECEIVED_ADMIN_PRIVILEGES,
    data
});

const dispatchErrorFaq = err => ({
    type: ACTIONS.ERROR_ADMIN_PRIVILEGES,
    err
});

const dispatchClearFaq = () => ({
    type: ACTIONS.CLEAR_ADMIN_PRIVILEGES
});


export const getAdminPrivileges = (privilegeType) => (dispatch, getState) => {

    // const currentState = getState().consultantFAQs;
    // if (data?.lazyParams) {
    //     currentState.lazyParams = {
    //         ...currentState.lazyParams,
    //         ...removeNullUndefinedKeys(data.lazyParams)
    //     }
    // }

    dispatch(dispatchRequestFaq());

    axios({
        method: 'post',
        url: `${SERVERLESS_URL}/admin/get-privilege-users`,
        headers: {
            'x-api-key': '9dPQkCfrgSaDEIUQYIO3e37pRt1E9zS9aTBIAHkg',
            'Authorization': `Bearer ${localStorage.getItem('Bearer') ? localStorage.getItem('Bearer') : ''}`
        },
        data: { privilegeType: "isDocumentEditor" }
    }).then((res) => {
        debugger
        if (res && res.data) {
            dispatch(dispatchReceivedFaq(
                {
                    privilegeType,
                    data: res?.data?.data
                }));
        } else {
            dispatch(dispatchErrorFaq({
                message: 'Some error occurred'
            }));
        }
    }).catch(e => {
        if (e?.response?.status > 400 && e?.response?.status < 500) {
            logOut()
        }
        dispatch(dispatchErrorFaq(e));
    });
}

export const clearAdminPrivileges = () => dispatchClearFaq();