import React, { Component } from "react";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";
import { BreadCrumb } from "primereact/breadcrumb";
import { InputText } from 'primereact/inputtext';
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import LoadingComponent from "../../../LoadingComponent";
import AddMember from "./addMember";
import { BasicLazyParams } from "../../../Utils/constants";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

import { logOut } from "../../../Utils";
import BaseComponent from "../../common/BaseComponent";
const items = [{ label: "Admin", url: '/#/admin' }, { label: "Members", url: '/#/admin/chits-members' }];

const home = {
    icon: "pi pi-home",
    url: "/#/admin",
};

class MembersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAddOrEdit: false,
            editMember: null,
            isShowConfirmationDialog: false,
            showSearch: true,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams
            },
        };

        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    deleteMember = (_id) => {
        this.setState({
            isLoading: true,
        });
        var _id = this.state.selectedId

        const url = `/chitfunds/members/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {

                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                            isShowConfirmationDialog: false
                        },
                        () => {
                            this.getMembers();
                        }
                    );
                } else {
                    this.setState({
                        isLoading: false,
                        //  isShowConfirmationDialog: false
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage || res.res.message,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                    isShowConfirmationDialog: false
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };
    getMembers = () => {
        let { lazyParams, globalSearch } = this.state;
        this.setState({
            isShowConfirmationDialog: false,
            isLoading: true,
            member: [],
        });

        const url = `/chitfunds/members?limit=${lazyParams.rows}&page=${lazyParams.page + 1
            }&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                // 
                if (res && res?.status && res?.res?.status) {
                    this.setState({
                        isLoading: false,
                        member: res?.res?.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onGlobalSearch = (e) => {
        this.setState((prevState) => {
            return {
                globalSearch: e.target.value,
                lazyParams: BasicLazyParams,
            };
        }, this.getMembers);
    };



    onPage = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    page: event.page,
                    rows: event.rows,
                    first: event.first,
                },
            };
        }, this.getMembers);
    };


    onSort = (event) => {
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    ...event,
                },
            };
        }, this.getMembers);
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.setState((prevState) => {
            return {
                lazyParams: {
                    ...prevState.lazyParams,
                    filters: event.filters,
                },
            };
        }, this.getMembers);
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };

    showTransactionsHandler = (_id) => {
        this.setState({
            isLoading: true,
            transactionsData: [],
        });

        const url = `/chitfunds/transactions/${_id}`;
        this.serverlessService
            .get(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        transactionsData: res.res.data,
                        transactionsTable: true,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    onHide = () => {
        this.setState({
            isShowConfirmationDialog: false,
            editMember: null,
            showAddOrEdit: false
        });
    };

    componentDidMount() {
        this.getMembers();
    }

    onMemberAddOrUpdate = () => {
        this.setState(
            {
                showAddOrEdit: false,
                editMember: null,
            },
            this.getMembers
        );
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.CHITS_MEMBERS_EDIT}>
                        <span onClick={() =>
                            this.setState({
                                editMember: rowData,
                                showAddOrEdit: true,
                                isEdit: true,
                            })
                        }
                            data-pr-tooltip="Edit Member"
                            data-pr-position="top"
                            className={`p-text editC${rowData._id}`}
                        >
                            <i className='pi pi-pencil' />
                        </span>
                        <Tooltip target={`.editC${rowData._id}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.CHITS_MEMBERS_DELETE}>
                        <span onClick={() => this.showDeleteMemberDialog(rowData._id)}
                            data-pr-tooltip="Delete Member"
                            data-pr-position="top"
                            className={`p-text deleteC${rowData._id}`}
                        >
                            <i className='pi pi-trash' />
                        </span>
                        <Tooltip target={`.deleteC${rowData._id}`} />
                    </Authorize>
                </div>
                <div className="col-2">
                    <Authorize permId={PERMISSIONS.CHITS_MEMBERS_SHOW_POLICIES}>
                        <span onClick={() => {
                            this.props.history.push(`policy/${rowData._id}/pending`);
                        }}
                            data-pr-tooltip="Show Member Policies"
                            data-pr-position="top"
                            className={`p-text deleteC${rowData._id}`}
                        >
                            <i className='pi pi-history' />
                        </span>
                        <Tooltip target={`.deleteC${rowData._id}`} />
                    </Authorize>
                </div>
            </div>
        );
    };

    showDeleteMemberDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id
        })
    }

    footerContent = () => (<div>
        <Button
            label="Cancel"
            className=" p-button-text hover:bg-primary "
            onClick={this.onHide}
        />
        <Button
            label="Ok"
            onClick={this.deleteMember}
            autoFocus
            className=" p-button-primary "
        />
    </div>)


    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false
        })
    }

    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">
                                All Members
                            </p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_MEMBERS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_MEMBERS_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.CHITS_MEMBERS_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex font-bold border-round w-4"></div>
                    <div class="flex absolute right-0 align-items-right  font-bold border-round w-8 "></div>
                </div>
            </div>
        );

        return (
            <div className="p-3">
                <BreadCrumb model={items} home={home} />
                <Toolbar
                    className="sp-toolbar sp-mt20"

                    left={() => {
                        return (
                            <Authorize
                                permId={PERMISSIONS.CHITS_MEMBERS_CREATE}
                            >
                                <Button
                                    label="New"
                                    icon="pi pi-plus"
                                    className="p-button-primary m-3"
                                    aria-label="Create"
                                    onClick={() => this.setState({ showAddOrEdit: true })}
                                />
                            </Authorize>
                        );
                    }}
                ></Toolbar>
                <Authorize permId={PERMISSIONS.CHITS_MEMBERS_LIST}>
                    <div className="surface-card shadow-2 border-round overflow-hidden">
                        <DataTable
                            tableClassName="policy-table"
                            ref={(el) => (this.dt = el)}
                            header={header}
                            value={this.state.member}
                            lazy
                            scrollHeight="400px"
                            onSort={this.onSort}
                            onPage={this.onPage}
                            onFilter={this.onFilter}
                            first={this.state.lazyParams.first}
                            last={this.state.totalRecords}
                            rows={this.state.lazyParams.rows}
                            totalRecords={this.state.totalRecords}
                            paginator
                            paginatorLeft
                            rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 500]}
                            scrollable
                            sortField='createdAt'
                            rowHover
                            dataKey="_id"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        >
                            <Column field="name" header=" Name"></Column>
                            <Column field="fatherName" header="Father Name"></Column>
                            <Column field="mobile" header="Mobile "></Column>
                            <Column
                                header="Actions"
                                body={(rowData) => this.actionsBody(rowData)}
                            ></Column>
                        </DataTable>


                    </div>

                </Authorize>

                {this.state.showAddOrEdit && (
                    <AddMember
                        onHide={this.onHide}
                        editMember={this.state.editMember}
                        onMemberAddOrUpdate={this.onMemberAddOrUpdate}
                        getMembers={this.getMembers}
                    ></AddMember>
                )}
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
                <Dialog
                    visible={this.state.isShowConfirmationDialog}
                    header="Are you Sure ?"
                    className="sm:w-11 md:w-6 w-11 lg:w-4 xl:w-3"
                    onHide={this.onCloseConfirmation}
                    footer={this.footerContent}
                    draggable={false}
                >
                    <p>Deleted Member can't be restore.</p>
                </Dialog>
            </div>
        );
    }
}

export default BaseComponent(MembersList);
