import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import LoadingComponent from "../../../LoadingComponent";

import { Dialog } from "primereact/dialog";
import { BasicLazyParams } from "../../../Utils/constants";
import ServerlessService from "../../../Service/ServerlessService";
import { Tooltip } from "primereact/tooltip";
import Authorize, { PERMISSIONS } from "../../../session/authorize";

import AssignBackToContentPre from "./assignContentWriter";

import ConsultantNotes from "../documentPreperator/consultantNotes";
import ConsultantMonitor from "../documentPreperator/consultantMonitor";
import { Card } from "primereact/card";
import BaseComponent from "../../common/BaseComponent";
import {
    getTechnicalPreperator,
    resetLazyTechnicalPreperator,
} from "../../../store/actions/technicalPreperatorActions";
import { BreadCrumb } from "primereact/breadcrumb";
import { logOut } from "../../../Utils";

class TechnicalPreperator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowView: false,
            viewTable: [],
            visible: false,
            isShowConfirmationDialog: false,
            consultants: [],
            customers: [],
            isShowAssignTable: false,
            isShowUnAssignTable: false,
            editData: null,
            id: null,
            renewalData: null,
            tableHistorysData: null,
            rowDataOfTable: null,
            isShowRenewal: false,
            globalSearch: "",
            lazyParams: {
                ...BasicLazyParams,
                sortField: "createdAt",
                sortOrder: 1,
            },
            selectedReason: "",
        };
        this.serverlessService = new ServerlessService();
    }

    onHide = () => {
        this.setState({
            visible: false,
            isShowRenewal: false,
            editData: null,
        });
    };

    deleteTable = () => {
        this.setState({
            isLoading: true,
            isShowConfirmationDialog: false,
        });
        var _id = this.state.selectedId;

        const url = `/consultants/consultant/${_id}`;
        this.serverlessService
            .delete(url, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState(
                        {
                            isLoading: false,
                        },
                        () => {
                            this.getTechnicalPreperator();
                        }
                    );
                    this.toast.show({
                        message: res.message,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    getTechnicalPreperator = () => {
        let { lazyParams, globalSearch } = this.state;

        this.setState({
            isLoading: true,
            consultants: [],
        });

        const url = `/consultants/consultant/get-to-preperation?limit=${lazyParams.rows
            }&page=${lazyParams.page + 1}&search=${globalSearch}${lazyParams.sortField
                ? `&sort=${lazyParams.sortOrder === 1 ? "" : "-"}${lazyParams.sortField
                }`
                : ""
            }`;
        this.serverlessService
            .post(url, {}, true)
            .then((res) => {
                if (res && res.status && res.res.status) {
                    this.setState({
                        isLoading: false,
                        consultants: res.res.data,
                        totalRecords: res.res.totalRecords,
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    componentDidMount() {
        if (!this.props.technicalPreperatorConsultants?.length) {
            this.props.getTechnicalPreperator();
        }
    }

    onGlobalSearch = (e) => {
        this.props.getTechnicalPreperator({ globalSearch: e.target.value });
    };

    onPage = (event) => {
        this.props.getTechnicalPreperator({ lazyParams: event });
    };

    onSort = (event) => {
        this.props.getTechnicalPreperator({ lazyParams: event });
    };

    onFilter = (event) => {
        event["first"] = 0;
        this.props.getTechnicalPreperator({ lazyParams: event });
    };

    footerContent = () => {
        return (
            <div>
                <Button
                    label="Cancel"
                    className="p-button-text hover:bg-primary"
                    onClick={this.onHide}
                />
                <Button
                    label="Renewal"
                    autoFocus
                    onClick={this.onRenewalClick}
                />
            </div>
        );
    };

    showConsultantNotes = (rowData) => {
        this.setState({
            isShowConsultantNotes: true,
            editData: rowData,
        });
    };

    showConsultantMonitor = (rowData) => {
        this.setState({
            isShowConsultantMonitor: true,
            editData: rowData,
        });
    };

    actionsBody = (rowData) => {
        return (
            <div className="p-grid row">
                <Authorize
                    permId={
                        PERMISSIONS.TECH_PREPERATION_ASSIGN_TO_MARKET_REVIEW_TEAM
                    }
                >
                    <div className="col-2">
                        <span
                            onClick={() =>
                                this.setState({
                                    isShowAssignToContentWriter: true,
                                    selectedCustomer: rowData,
                                })
                            }
                            data-pr-tooltip="Assign To Market Review Team"
                            data-pr-position="top"
                            className={`p-text tableDelete${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-directions" />
                        </span>
                        <Tooltip
                            target={`.tableDelete${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>

                <Authorize
                    permId={PERMISSIONS.TECH_PREPERATION_SHOW_CONSULTANT}
                >
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/view/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Show Consultant"
                            data-pr-position="top"
                            className={`p-text abc${rowData._id.substr(4)}`}
                        >
                            <i className="pi pi-eye" />
                        </span>
                        <Tooltip target={`.abc${rowData._id.substr(4)}`} />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.TECH_PREPERATION_MONITOR}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantMonitor: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Consultant Monitor"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-comments" />
                        </span>
                        <Tooltip
                            target={`.tableHistory${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.TECH_PREPERATION_TRACKER}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.props.history.push(
                                    `/admin/cosultant/tracker/${rowData._id}`
                                );
                            }}
                            data-pr-tooltip="Consultant Tracker"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-building" />
                        </span>
                        <Tooltip
                            target={`.tableHistory${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
                <Authorize permId={PERMISSIONS.TECH_PREPERATION_NOTES}>
                    <div className="col-2">
                        <span
                            onClick={() => {
                                this.setState({
                                    isShowConsultantNotes: true,
                                    editData: rowData,
                                });
                            }}
                            data-pr-tooltip="Notes (Only visible to you.)"
                            data-pr-position="top"
                            className={`p-text tableHistory${rowData._id.substr(
                                4
                            )}`}
                        >
                            <i className="pi pi-book" />
                        </span>
                        <Tooltip
                            target={`.tableHistory${rowData._id.substr(4)}`}
                        />
                    </div>
                </Authorize>
            </div>
        );
    };

    showNotes = (rowData) => {
        this.setState({
            isShowNotes: true,
            editData: rowData,
        });
    };

    footerContentfotDelete = () => {
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.onCloseConfirmation}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable()}
                autoFocus
                className=" p-button-primary "
            />
        </div>;
    };

    showDeleteTableDialog = (id) => {
        this.setState({
            isShowConfirmationDialog: true,
            selectedId: id,
        });
    };

    onCloseConfirmation = () => {
        this.setState({
            isShowConfirmationDialog: false,
        });
    };

    footerContentforDelete = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={() => {
                    this.setState({
                        isShowConfirmationDialog: false,
                    });
                }}
            />
            <Button
                label="Ok"
                onClick={this.deleteTable}
                autoFocus
                className=" p-button-primary "
            />
        </div>
    );

    onDocumentAccepted = (files) => {
        this.setState((prevState) => {
            return {
                consultants: prevState.consultants.map((a) => {
                    if (prevState.editData._id == a._id) {
                        a.files = files;
                    }
                    return a;
                }),
                editData: {
                    ...prevState.editData,
                    files: files,
                },
            };
        });
    };

    exportCSV = () => {
        this.dt.exportCSV();
    };
    render() {
        const header = (
            <div className="">
                <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none border-1 lg:border-none border-gray-800">
                    <ul class="list-none flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li>
                            <p className="mx-0 my-1 ss-header">Consultants</p>
                        </li>
                    </ul>
                    <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                        <li class="flex-order-2 lg:flex-order-0">
                            <ul className="right-0">
                                <ul className="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                                    <Authorize
                                        permId={
                                            PERMISSIONS.TECH_PREPERATION_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 ">
                                            {this.state.showSearch && (
                                                <InputText
                                                    placeholder="Search"
                                                    className=""
                                                    value={
                                                        this.props.globalSearch
                                                    }
                                                    onChange={
                                                        this.onGlobalSearch
                                                    }
                                                />
                                            )}
                                        </li>
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.TECH_PREPERATION_GENERAL_SEARCH
                                        }
                                    >
                                        <li className="flex-order-2 lg:flex-order-0 ml-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Search"
                                                data-pr-position="bottom"
                                                className="pi pi-search text-base lg:text-2xl mr-2 ss-tbl-srch"
                                                onClick={(e) => {
                                                    this.setState({
                                                        showSearch:
                                                            !this.state
                                                                .showSearch,
                                                    });
                                                }}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            autoHide={false}
                                            target=".ss-tbl-srch"
                                        />
                                    </Authorize>
                                    <Authorize
                                        permId={
                                            PERMISSIONS.TECH_PREPERATION_GENERAL_DOWNLOAD
                                        }
                                    >
                                        <li className="border-top-1 border-gray-800 lg:border-top-none ml-4 mr-4 cursor-pointer">
                                            <i
                                                data-pr-tooltip="Download"
                                                data-pr-position="bottom"
                                                className="pi pi-download  text-base lg:text-2xl mr-2 ss-tbl-dwnld"
                                                onClick={this.exportCSV}
                                            ></i>
                                        </li>
                                        <Tooltip
                                            className="table-li-tooltip"
                                            target=".ss-tbl-dwnld"
                                        />
                                    </Authorize>
                                </ul>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        );
        const items = [{ label: "Admin", url: "/#/admin" }, { label: "Tech Preperations" }];

        const home = {
            icon: "pi pi-home",
            url: "/#/admin",
        };
        return (
            <>
                <div className='p-5'>
                    <BreadCrumb model={items} home={home} />
                    <Authorize permId={PERMISSIONS.TECH_PREPERATION_LIST}>
                        <div className="mt-3 shadow-3 border-round overflow-hidden">
                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.props.technicalPreperatorConsultants}
                                lazy
                                header={header}
                                scrollHeight="400px"
                                onSort={this.onSort}
                                onPage={this.onPage}
                                onFilter={this.onFilter}
                                first={this.props.lazyParams.first}
                                last={this.props.totalRecords}
                                rows={this.props.lazyParams.rows}
                                totalRecords={this.props.totalRecords}
                                paginator
                                paginatorLeft
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                rowsPerPageOptions={[
                                    5, 10, 25, 50, 100
                                ]}
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                                scrollable
                                responsiveLayout="scroll"
                                rowHover
                                dataKey="_id"
                                selection={this.state.selectedCustomer}
                                onSelectionChange={(e) =>
                                    this.setState({
                                        selectedCustomer: e.value,
                                    })
                                }
                                selectionMode="single"
                            >
                                <Column
                                    field="name"
                                    header="Full Name"
                                ></Column>
                                <Column
                                    field="mobile"
                                    header="Phone"
                                ></Column>
                                <Column
                                    field="email"
                                    header="Email"
                                ></Column>
                                <Column
                                    field="rolePrefered"
                                    header="Role"
                                ></Column>

                                <Column
                                    header="Actions"
                                    body={(rowData) =>
                                        this.actionsBody(rowData)
                                    }
                                ></Column>
                            </DataTable>

                            {this.state.isShowAssignToContentWriter && (
                                <AssignBackToContentPre
                                    isAssingToPreperationTeam={
                                        this.state.isAssingToPreperationTeam
                                    }
                                    selectedCustomer={
                                        this.state.selectedCustomer
                                    }
                                    onCancel={() => {
                                        this.setState({
                                            isShowAssignToContentWriter: false,
                                            selectedCustomer: null,
                                        });
                                    }}
                                    onHide={() => {
                                        let thisObj = this;

                                        this.setState(
                                            {
                                                isShowAssignToContentWriter: false,
                                                selectedCustomer: null,
                                            },
                                            () => {
                                                thisObj.toast.show({
                                                    severity: "success",
                                                    summary:
                                                        "User Assign to Pre-Market Reviewer.",
                                                    detail: "User assigned successfully",
                                                    life: 3000,
                                                });
                                                thisObj.props.getTechnicalPreperator();
                                            }
                                        );
                                    }}
                                />
                            )}
                        </div>
                    </Authorize>
                </div>
                {this.state.isShowNotes && (
                    <Dialog
                        header={"Notes"}
                        visible={true}
                        style={{ width: "49vw" }}
                        onHide={() => {
                            this.setState({
                                isShowNotes: false,
                                editData: null,
                            });
                        }}
                    >
                        <p>{this.state.editData.notesToDocumentPreparator}</p>
                    </Dialog>
                )}

                {this.state.isShowConfirmationDialog && (
                    <Dialog
                        header="Are you Sure ?"
                        visible={this.state.isShowConfirmationDialog}
                        style={{ width: "15vw" }}
                        onHide={this.onCloseConfirmation}
                        draggable={false}
                        footer={this.footerContentforDelete}
                    >
                        <p>Deleted table can't be restore.</p>
                    </Dialog>
                )}

                {this.state.isShowConsultantMonitor && (
                    <ConsultantMonitor
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantMonitor: false,
                                editData: null,
                            });
                        }}
                    />
                )}
                {this.state.isShowConsultantNotes && (
                    <ConsultantNotes
                        consultant={this.state.editData}
                        onHide={() => {
                            this.setState({
                                isShowConsultantNotes: false,
                                editData: null,
                            });
                        }}
                    />
                )}

                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    technicalPreperatorConsultants: state?.technicalPreperators?.technicalPreperatorConsultants,
    totalRecords: state?.technicalPreperators?.totalRecords,
    lazyParams: state?.technicalPreperators?.lazyParams,
    isLoading: state?.technicalPreperators?.isLoading,
});

export default BaseComponent(TechnicalPreperator, mapStateToProps, {
    getTechnicalPreperator,
    resetLazyTechnicalPreperator,
});
