import React, { Component } from "react";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { ScrollTop } from "primereact/scrolltop";
import { Accordion, AccordionTab } from "primereact/accordion";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import NavFooter from "../navFooter";

import "./styles.scss"; // Import your custom CSS for styling

class Chitfunds extends Component {
    constructor() {
        super();
        this.state = {
            features: [
                {
                    title: "Member Management",
                    description:
                        "Effortlessly manage member details, including personal information, verification status, and contribution history. Ensure all member profiles are secure and verified for authenticity. Gain insights into member participation and streamline communication for better collaboration.",
                    image: "member-management-image.jpg",
                },
                {
                    title: "Chit Group Management",
                    description:
                        "Easily create, customize, and manage multiple chit fund groups with unique settings for contribution amounts, auction schedules, and member allocations. Track group performance and ensure smooth fund distribution among members.",
                    image: "chit-group-management-image.jpg",
                },
                {
                    title: "Payment Tracking",
                    description:
                        "Simplify financial tracking by maintaining a transparent record of all contributions, payouts, and dues. Monitor member-wise payment history, automate reminders for pending payments, and ensure accurate bookkeeping.",
                    image: "payment-tracking-image.jpg",
                },
                {
                    title: "Auction Management",
                    description:
                        "Streamline auction processes by organizing and tracking bids, calculating winners, and distributing funds efficiently. Enable real-time auction updates and notifications for participating members.",
                    image: "auction-management-image.jpg",
                },
                {
                    title: "Reports and Analytics",
                    description:
                        "Generate comprehensive reports on member participation, payment statuses, group performance, and auction outcomes. Leverage detailed analytics to make informed decisions and improve operational efficiency.",
                    image: "reports-analytics-image.jpg",
                },
                {
                    title: "Dashboard",
                    description:
                        "Manage all chit fund operations from a centralized, user-friendly dashboard. Access member information, monitor payments, schedule auctions, and view reports seamlessly, ensuring complete control over your chit fund activities.",
                    image: "dashboard-image.jpg",
                },
                {
                    title: "Notifications and Alerts",
                    description:
                        "Keep members informed with automated reminders and alerts for payments, auction schedules, and other important updates. Improve member engagement and ensure timely actions with real-time notifications.",
                    image: "notifications-alerts-image.jpg",
                },
                {
                    title: "Branch Management",
                    description:
                        "Oversee multiple branches with ease. Manage operations, track payments, and monitor performance across different locations from a single platform, ensuring consistency and efficiency.",
                    image: "branch-management-image.jpg",
                },
            ]

        };
    }

    featureTemplate(feature, layout) {
        return (
            <div
                className={`p-col-12 p-md-4 ${layout}-box`}
                key={feature.title}
            >
                <Card title={feature.title}>
                    <img
                        src={`images/${feature.image}`}
                        alt={feature.title}
                        className="feature-image"
                    />
                    <p>{feature.description}</p>
                </Card>
            </div>
        );
    }

    render() {
        const pricingPlans = [
            {
                title: "Basic Plan",
                description: "Ideal for getting started",
                price: "$49/month",
                days: "30 days",
            },
            {
                title: "Standard Plan",
                description: "Our most popular plan",
                price: "$99/month",
                days: "60 days",
            },
            {
                title: "Premium Plan",
                description: "For advanced users",
                price: "$149/month",
                days: "90 days",
            },
            {
                title: "Enterprise Plan",
                description: "Custom solutions for businesses",
                price: "Contact us",
                days: "Custom",
            },
            {
                title: "Free Plan",
                description: "Try it for free",
                price: "Free",
                days: "7 days",
            },
        ];
        return (
            <NavFooter>
                <div>
                    {/* ... Your navigation and footer components ... */}
                    {/* <div
                        className="bg-white px-4 sm:px-12 md:px-6 lg:px-8 intro-card"
                        style={{
                            display: "flex",
                            backgroundColor: "#FF5733",
                            animation: "slide-in 1s both",
                        }}
                    > */}
                    <div className="grid">
                        <div className="col-12 md:col-6 p-4">
                            <h1>Welcome to Chitfunds</h1>
                        </div>
                        <div className="col-12 md:col-6">
                            <img
                                src="https://images.unsplash.com/photo-1515965885361-f1e0095517ea?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NjR8fG9mZmljZSUyMHNwYWNlcyUyMGhkJTIwcGhvdG9zfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60"
                                alt="co-space Image"
                            />
                        </div>
                    </div>
                    {/* </div> */}
                    {/* <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
                        <div className="mb-3 font-bold text-3xl">
                            <span
                                className="text-900"
                                style={{ fontSize: "inherit" }}
                            >
                                One Product,{" "}
                            </span>
                            <span
                                className="text-blue-600"
                                style={{ fontSize: "inherit" }}
                            >
                                Our Features
                            </span>
                        </div>
                        <div className="text-700 mb-6">
                            We designed our platform considering below points.
                        </div>
                        <div className="p-grid">
                            {this.state.features.map((feature) => (
                                <div
                                    className="surface-card p-4 shadow-2 border-round"
                                    style={{ margin: "20px" }}
                                >
                                    <div className="text-3xl font-medium text-900 mb-3">
                                        {feature.title}
                                    </div>
                                    <div
                                        style={{ minHeight: "150px" }}
                                        className="surface-border"
                                    >
                                        {feature.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{ backgroundColor: "#F8F8FF", padding: "20px" }}
                    >
                        <h3>Why Choose Us?</h3>
                        <ul
                            style={{
                                padding: "30px",
                                margin: "10px",
                            }}
                        >
                            <li>
                                <strong>Member Management:</strong> Secure,
                                verified members for efficient collaboration.
                            </li>
                            <li>
                                <strong>Table Management:</strong> Customize and
                                organize your workspace layout.
                            </li>
                            <li>
                                <strong>Branches:</strong> Manage multiple
                                locations with ease.
                            </li>
                            <li>
                                <strong>Transactions:</strong> Monitor and track
                                financial activities transparently.
                            </li>
                        </ul>
                    </div> */}
                    <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
                        <div className="mb-3 font-bold text-3xl">
                            <span className="text-900" style={{ fontSize: "inherit" }}>
                                Chit Funds Simplified,{" "}
                            </span>
                            <span className="text-blue-600" style={{ fontSize: "inherit" }}>
                                Comprehensive Features
                            </span>
                        </div>
                        <div className="text-700 mb-6">
                            We designed our chit fund management platform with the following capabilities to streamline operations.
                        </div>
                        <div className="p-grid">
                            {this.state.features.map((feature) => (
                                <div
                                    className="surface-card p-4 shadow-2 border-round"
                                    style={{ margin: "20px" }}
                                >
                                    <div className="text-3xl font-medium text-900 mb-3">
                                        {feature.title}
                                    </div>
                                    <div style={{ minHeight: "150px" }} className="surface-border">
                                        {feature.description}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div style={{ backgroundColor: "#F8F8FF", padding: "20px" }}>
                        <h3>Why Choose Us?</h3>
                        <ul style={{ padding: "30px", margin: "10px" }}>
                            <li>
                                <strong>Member Management:</strong> Manage member details, including
                                personal information, verification status, and contribution history.
                            </li>
                            <li>
                                <strong>Chit Groups:</strong> Create, manage, and monitor multiple
                                chit fund groups with unique settings for contribution and auction
                                frequency.
                            </li>
                            <li>
                                <strong>Payment Tracking:</strong> Keep a transparent record of all
                                payments, including member-wise contributions, payouts, and dues.
                            </li>
                            <li>
                                <strong>Auction Management:</strong> Organize and track auctions,
                                calculate winning bids, and distribute funds seamlessly.
                            </li>
                            <li>
                                <strong>Automated Notifications:</strong> Send timely reminders for
                                payments, auctions, and other important updates via SMS or email.
                            </li>
                            <li>
                                <strong>Branches:</strong> Manage and oversee multiple branches with
                                consolidated reporting for all locations.
                            </li>
                            <li>
                                <strong>Reports and Analytics:</strong> Generate detailed reports on
                                financial activities, group performance, and member participation.
                            </li>
                            <li>
                                <strong>Secure Transactions:</strong> Ensure end-to-end security for
                                all financial transactions and data storage.
                            </li>
                            <li>
                                <strong>Customizable Settings:</strong> Tailor chit group policies,
                                payment schedules, and other parameters to meet your needs.
                            </li>
                        </ul>
                    </div>

                    <div
                        className="p-grid"
                        style={{ height: "fit-content", padding: "20px" }}
                    >

                    </div>


                </div>
            </NavFooter>
        );
    }
}

const mapStateToProps = (state) => ({
    orgName: state?.orgData?.orgName,
});

export default connect(mapStateToProps, {})(withRouter(Chitfunds));
