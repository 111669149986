import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from 'primereact/multiselect';

import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { Component } from "react";
import LoadingComponent from "../../../LoadingComponent";
import Service from "../../../Service";
import ServerlessService from "../../../Service/ServerlessService";
import { logOut } from "../../../Utils";

class AddMemberToPolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: []
        };
        this.service = new Service();
        this.serverlessService = new ServerlessService();
    }

    addOrEdit = () => {
        let policy = this.props?.policyId;
        let { members } = this.state;

        if (!this.state.members?.length) {
            this.setState({
                isShowError: true
            });
            return
        }

        this.setState({
            isShowError: false,
            isLoading: true
        });

        const url = `/chitfunds/policy/members`;
        this.serverlessService
            .post(url, { members, policy }, true)
            .then((res) => {
                if (res && res?.status && res?.res?.status) {
                    this.setState(
                        {
                            members: [],
                            isLoading: false,
                        },
                        this.props.afterAddCustomerToPolicy
                    );
                } else {
                    this.setState({
                        isLoading: false,
                    });
                    this.toast.show({
                        severity: "error",
                        summary: "Some error occurred",
                        detail: res.errMessage,
                        life: 3000,
                    });
                }
            })
            .catch((e) => {
                if (e?.response?.status > 400 && e?.response?.status < 500) {
                    logOut()
                }
                console.log(e);
                this.setState({
                    isLoading: false,
                });
                this.toast.show({
                    severity: "error",
                    summary: "Some error occurred",
                    detail: e.message,
                    life: 3000,
                });
            });
    };

    footerContent = () => (
        <div>
            <Button
                label="Cancel"
                className=" p-button-text hover:bg-primary "
                onClick={this.props.onHide}
            />
            <Button
                label={"Add"}

                onClick={this.addOrEdit}
                autoFocus
                className="ml-2 p-button-primary "
            />
        </div>
    );

    render() {
        const { members, isShowError } = this.state;
        const { policyMembersLength, policy } = this.props;
        return (
            <div>
                <Dialog
                    header={'Add Members'}
                    draggable={false}
                    visible={true}
                    className="sm:w-11 md:w-6 w-11 lg:w-4 xl:w-3"
                    onHide={this.props.onHide}
                    footer={this.footerContent}
                >
                    <div className="p-grid row pl-6 pr-6">
                        <div className="col-12">
                            <label className="font-bold block mb-2">Member Name <span className="sp-required">* </span> </label>
                            <MultiSelect
                                display="chip"
                                onChange={(e) => {
                                    if (policyMembersLength + e.value?.length <= policy.policy.totalMembers) {
                                        this.setState({
                                            members: e.value
                                        })
                                    } else {
                                        this.toast.show({
                                            severity: "error",
                                            summary: "Error",
                                            detail: 'No more than total members are allowed.',
                                            life: 3000,
                                        });
                                    }
                                }}
                                value={members}
                                options={this.props.customers}
                                optionValue="_id"
                                optionLabel="name"
                                placeholder="Select members"
                                className="w-full"
                            />
                            {isShowError && !members?.length && <p className="p-error">Please select minimum one member</p>}
                        </div>
                    </div>
                </Dialog>
                <Toast
                    ref={(el) => (this.toast = el)}
                    position="bottom-right"
                />
                {(this.state.isLoading || this.props.isLoading) && (
                    <LoadingComponent />
                )}
            </div>
        );
    }
}

export default AddMemberToPolicy;
