import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { Component } from 'react';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import { ClassicEditor } from '@ckeditor/ckeditor5-build-classic';

class CkEditorComponent extends Component {
    render() {
        return (<>
            <InputTextarea
                rows={10}
                value={this.props.value}
                onChange={(e) => {
                    this.props.onChange(e.target.value);
                }}
            />
        </>

            // <CKEditor
            //     editor={ClassicEditor}
            //     data={this.props.value}
            //     onChange={(event, editor) => {
            //         const data = editor.getData();
            //         this.props.onChange(data);
            //     }}
            //     disabled={this.props.disabled}
            // />
            // <CKEditor
            //     editor={ClassicEditor}
            //     config={{
            //         licenseKey: '<YOUR_LICENSE_KEY>', // Or 'GPL'.
            //         plugins: [Essentials, Paragraph, Bold, Italic, FormatPainter],
            //         toolbar: ['undo', 'redo', '|', 'bold', 'italic', '|', 'formatPainter'],
            //         initialData: '<p>Hello from CKEditor 5 in React!</p>',
            //     }}
            //     data={this.props.value}
            //     onChange={(event, editor) => {
            //         const data = editor.getData();
            //         this.props.onChange(data);
            //     }}
            //     disabled={this.props.disabled}
            // />

        );
    }
}

export default CkEditorComponent;